import { List } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { List as AntdList, Button, Card, Col, Divider, Row, } from "antd";
import { useState } from "react";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const [selectSV, setSV] = useState<any>();
    const [selectLC, setLC] = useState<any>();


    const mockDT: any = [
        {
            id: 1,
            name: "WIN-SUPREME-PRO",
            price: 11,
            service: [
                {
                    id: 1,
                    name: "2 Core CPU"
                },
                {
                    id: 2,
                    name: "4 GB RAM"
                },
                {
                    id: 3,
                    name: "100 GB SSD"
                }
            ]
        },
        {
            id: 2,
            name: "WIN-SUPREME-BUSINESS",
            price: 28,
            service: [
                {
                    id: 1,
                    name: "3 Core CPU"
                },
                {
                    id: 2,
                    name: "8 GB RAM"
                },
                {
                    id: 3,
                    name: "200 GB SSD"
                }
            ]
        },
        {
            id: 3,
            name: "WIN-SUPREME-ENTERPRISE",
            price: 50,
            service: [
                {
                    id: 1,
                    name: "4 Core CPU"
                },
                {
                    id: 2,
                    name: "16 GB RAM"
                },
                {
                    id: 3,
                    name: "300 GB SSD"
                }
            ]
        },
        {
            id: 4,
            name: "LUX-SUPREME-PRO",
            price: 10,
            service: [
                {
                    id: 1,
                    name: "2 Core CPU"
                },
                {
                    id: 2,
                    name: "4 GB RAM"
                },
                {
                    id: 3,
                    name: "100 GB SSD"
                }
            ]
        },
        {
            id: 5,
            name: "LUX-SUPREME-BUSINESS",
            price: 18,
            service: [
                {
                    id: 1,
                    name: "3 Core CPU"
                },
                {
                    id: 2,
                    name: "8 GB RAM"
                },
                {
                    id: 3,
                    name: "200 GB SSD"
                }
            ]
        },
        {
            id: 6,
            name: "LUX-SUPREME-ENTER",
            price: 28,
            service: [
                {
                    id: 1,
                    name: "4 Core CPU"
                },
                {
                    id: 2,
                    name: "16 GB RAM"
                },
                {
                    id: 3,
                    name: "300 GB SSD"
                }
            ]
        },
    ];

    const mockLOC: any = [
        {
            id: 1,
            name: "London",
            currency: "GB",
            flagURL: "https://mt4.cloud/_images/flags/flag_gb.png"
        },
        {
            id: 2,
            name: "New Jersey",
            currency: "US",
            flagURL: "https://mt4.cloud/_images/flags/flag_us.png"
        },
        {
            id: 3,
            name: "Tokyo",
            currency: "JP",
            flagURL: "https://mt4.cloud/_images/flags/flag_jp.png"
        },
        {
            id: 4,
            name: "Singapore",
            currency: "SG",
            flagURL: "https://mt4.cloud/_images/flags/flag_sg.png"
        },
        {
            id: 5,
            name: "Falkenstein",
            currency: "DE",
            flagURL: "https://mt4.cloud/_images/flags/flag_de.png"
        },
        {
            id: 6,
            name: "Helsinki",
            currency: "FI",
            flagURL: "https://mt4.cloud/_images/flags/flag_fi.png"
        },
        {
            id: 7,
            name: "Virginia",
            currency: "US",
            flagURL: "https://mt4.cloud/_images/flags/flag_us.png"
        }
    ]

    const renderItem = (item: any) => {
        const {
            id,
            name,
            price,
            service,
        }: any = item;

        return (
            <AntdList.Item 
                key={id} 
                onClick={() => setSV({
                    id: id,
                    item: item
                })}
            >
                <AntdList.Item>
                    <Card
                        bordered={true}
                        style={{cursor: "pointer"}}
                        // className={`card-dp ` + selectSV == id ? "card-dp-selected": ""}
                        className={`card-dp ${selectSV?.id == id ? "card-dp-selected": ""}`}
                        // card-dp-selected
                        // onClick={() => setSV(id)}
                    >
                        <div>
                            <div style={{fontSize: "1rem", fontWeight: 600, textAlign: "center"}}>{name}</div>
                            <div style={{fontSize: "3rem", color: "#FF9823", textAlign: "center"}}>{"฿" + price + " /วัน"}</div>

                            <Divider />

                            <div style={{fontSize: "1rem"}}>
                                <div style={{textAlign: "center"}}>{service[0]?.name}</div>
                                <div style={{textAlign: "center"}}>{service[1]?.name}</div>
                                <div style={{textAlign: "center"}}>{service[2]?.name}</div>
                            </div>
                        </div>
                    </Card>
                </AntdList.Item>
            </AntdList.Item>
        )
    }

    const renderLoc = (item: any) => {
        const {
            id,
            name,
            currency,
            flagURL,
        }: any = item;

        return (
            <AntdList.Item 
                key={id} 
                onClick={() => setLC({
                    id: id,
                    item: item
                })}
            >
                <AntdList.Item>
                    <Card
                        bordered={true}
                        style={{cursor: "pointer"}}
                        className={`card-lc ${selectLC?.id == id ? "card-lc-selected": ""}`}
                    >
                        <div>
                            <Row gutter={6}>
                                <Col className="gutter-row" span={6}>
                                    <img src={flagURL} style={{width: "80%"}}/>
                                </Col>
                                <Col className="gutter-row" span={18}>
                                    <div>
                                        <div style={{fontWeight: 600, fontSize: "1rem"}}>{name}</div>
                                        <div style={{color: "#b3b3b3"}}>{currency}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </AntdList.Item>
            </AntdList.Item>
        )
    }

    return (
        <List
            title={t("Deploy New Instance")}
            breadcrumb={false}
            headerButtons={false}
        >
            <div style={{fontSize: "1.8rem", margin: "1rem 0", paddingLeft: "5px"}}>Choose Server</div>

            <div>
                <AntdList
                    rowKey={"id"}
                    grid={{ gutter: 12, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }}
                    dataSource={mockDT}
                    renderItem={renderItem}
                    style={{ position: "relative" }}
                    pagination={false}
                />
            </div>

            <div style={{fontSize: "1.8rem", margin: "1rem 0", paddingLeft: "5px"}}>Server Location</div>

            <div>
                <AntdList
                    rowKey={"id"}
                    grid={{ gutter: 12, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }}
                    dataSource={mockLOC}
                    renderItem={renderLoc}
                    style={{ position: "relative" }}
                    pagination={false}
                />
            </div>

            <div 
                className="footer" 
                style={{
                    height: "120px",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    position: "sticky",
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    // boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
                }}
            >
                <div style={{padding: "10px", width: "95%"}}>
                    <Row gutter={6}>
                        <Col className="gutter-row" span={6}>
                            <div style={{fontSize: "1.5rem"}}>How many servers?</div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={{fontSize: "1.5rem"}}>Price</div>
                            <div style={{fontSize: "1.5rem", color: "#FF9823"}}>{(selectSV ? selectSV?.item?.price : 0) + ".00฿"}</div>
                        </Col>
                        <Col className="gutter-row" span={8} />
                        <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Button 
                                size="large" 
                                type="primary" 
                                style={{
                                    width: "100%",
                                    fontSize: "1.5rem",
                                    height: "50px"
                                }}
                            >
                                Deploy
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </List>
    )
}