import { axiosInstance } from "@refinedev/strapi-v4";
import { API_URL, TOKEN_KEY, GOOGLEID } from "../../constants";
import GoogleLogin from 'react-google-login';
import { useEffect } from "react";
import { gapi } from 'gapi-script';
import axios from "axios";

interface Iform {
    aftersocialLGN: (data: any) => void
}

export const GoogleBTN: React.FC<Iform> = ({aftersocialLGN}) => {
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId : GOOGLEID,
                scope : 'profile'
            })

            gapi.load('client:auth2',start);
        };
    });

    const responseGoogle = (response: any) => {
        let checked = response?.googleId
        if(checked){
            let respons: any = {
                googleID: response?.googleId,
                profileObj: response?.profileObj
            }

            let body: any = {
                googleId: respons?.googleID,
                email: respons?.profileObj?.email,
                name: respons?.profileObj?.name,
                first_name: respons?.profileObj?.givenName,
                last_name: respons?.profileObj?.familyName
            }

            axiosInstance.post(`${API_URL}/api/user-profiles/CheckSocialLogin?type=1&id=${respons?.googleID}`, body,{ headers: { Authorization: `Bearer ${TOKEN_KEY}` }})
            .then((response) => {
                let res = response?.data;
                if(res?.code == "200" || res?.code == "201"){
                    localStorage.setItem(TOKEN_KEY, res?.jwt);
                    aftersocialLGN(res);
                }
            })
            .catch(error => {
                console.error('error ',error);
            });
        }
    }

    return (
        <div>
            <GoogleLogin
                clientId={GOOGLEID}
                buttonText="Sign in With Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                scope="profile"
                style={{width: "100%"}}
                className="btn_lgn_google"
            />
        </div>
    )
}