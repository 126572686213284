// export const API_URL  = "https://ming-dev.techscape.app"; //STRAPI
// export const API_URL  = "http://192.168.100.178:1337"; //STRAPI
// export const API_URL  = "https://lsc-hom-api.techscape.app"; //STRAPI TEST URL
export const API_URL  = "https://api-v1.nueamek.cloud"; //STRAPI SIT/UAT
// export const SOURCE_URL  = "http://192.168.100.178:10061"; //NEST LOCAL
export const SOURCE_URL  = "https://trans.nueamek.cloud"; //NEST SIT/UAT
// export const DOMAIN_URL  = "http://192.168.100.178:4000"; //URL
// export const DOMAIN_URL  = "https://rockket.techscape.app"; //DOMAIN TEST URL
// export const DOMAIN_URL  = "https://app-uat.nueamek.cloud"; //URL
// export const DOMAIN_URL  = "https://go.nueamek.cloud"; //URL
export const DOMAIN_URL  = "https://nueamek.cloud"; //URL
export const LIFFID  = "2002166412-8BAp6wjm"; //LINE ID
export const TOKEN_KEY = "056b62897a5f28a765b2784da9c64d98d67663da0e95bac290b52d5cda56e5e11328ed45957bed17996ca18733bdccf8aa3c56dbe8edc2ebba101065f8edab3ee0db8607e5accfcb94f4b8ce29e6f6785e6e0593fce97ef662cd2425916c08daec8689ede0d170c36d6d36100b5f0885a68024176a013e759150bb8ac1c39d72";
export const VERSION = "DEV.XXXXX";
// export const GGID = process.env.GOOGLEID;
export const GOOGLEID = "25981592103-1r1dh0ft70k600o2b2sab23a98gpcg8s.apps.googleusercontent.com";