import { useCustomMutation, useLogin, useNavigation, useTranslate } from "@refinedev/core";
import { Avatar, Button, Card, Checkbox, Form, Input, Layout, Segmented, Spin, Tag, notification } from "antd";
import { useNavigate } from "react-router";
// import { API_URL, TOKEN_KEY } from "../../constants";
import "./styles.css";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { API_URL, DOMAIN_URL, TOKEN_KEY } from "../../constants";
import { GoogleBTN } from "./button_gg";
import { HeadernoAuth } from "components/unAuth/header";
import axios from "axios";
import { useState } from "react";

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const LoginPage: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login } = useLogin<ILoginForm>();
    const { mutate: clogin } = useCustomMutation<any>();
    const { push } = useNavigation();

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[3].split("?");
    let pageTOKEN: any = pageSPLIT[1];

    const [isLoading, setLoading] = useState<boolean>(false);

    const faildNoti = () => {
        notification['error']({
          message: t("Login Faild"),
          description: t("E-mail or Password is incorrect"),
        });
    };

    const notVerify = () => {
        notification['error']({
          message: t("Login Faild"),
          description: t("This e-mail is not verify"),
        });
    };

    function aftersocialLGN(data: any){
        if(data?.code == "200" || data?.code == "201"){
            localStorage.setItem(TOKEN_KEY, data?.jwt);
            localStorage.setItem("USER", data?.user?.id);
            localStorage.setItem("USERID", data?.user_profile?.id);
            localStorage.setItem("VERIFY", data?.user_profile?.is_verify);
            localStorage.setItem("SECRET_KEY", data?.user_profile?.secret_key);
            localStorage.setItem("SOCIAL", "1");
            setLoading(true);

            setTimeout(() => {
                let checked = localStorage?.getItem(TOKEN_KEY);
                if(checked){
                    if(pageTOKEN == undefined){
                        window.location.href = `${DOMAIN_URL}/products`;
                    }else{
                        window.location.href = `${DOMAIN_URL}/products/product-config/${pageTOKEN}`;
                    }

                    setLoading(false);
                }
            }, 500);
        }
    }

    function goPage(path: any,mode: any){
        if(mode == "un-token"){
            push(`/${path}`);
        }else if(mode == "token"){
            push(`/${path}?${pageTOKEN}`);
        }
    }

    return (
        <Layout className="layout-reg">
            <HeadernoAuth sticky/>
            <div id="container-page-bg" style={{display: "flex", alignItems: "center", height: "92.4vh", justifyContent: "center"}}>
                <Spin size="large" spinning={isLoading}>
                    <div className="layout_lgn">
                        <Form<ILoginForm>
                            layout="vertical"
                            form={form}
                            onFinish={async (values: any) => {
                                setLoading(true);
                                clogin({
                                    url:  API_URL + `/api/auth/local`,
                                    method: "post",
                                    values: {
                                        identifier: values.email,
                                        password: values.password,
                                    },
                                    errorNotification : false
                                    },
                                    {
                                    onError: (error, variables, context) => {
                                        faildNoti();
                                        setLoading(false);
                                    },
                                    onSuccess: async (data:any, variables, context) => {
                                        let dataLoad: any = data?.data;
                                        let dataUser: any = undefined;
                                        
                                        const getProfile = await axios.get(API_URL + `/api/user-profiles/UserPermission?id=${dataLoad?.user?.id}`, {headers: { Authorization: `Bearer ${dataLoad?.jwt}` }}).then((res) => {
                                            dataUser = res?.data[0]?.user_detail;
                                        });

                                        if(dataUser?.is_verify == false){
                                            notVerify();
                                            setLoading(false);
                                        }else if(dataUser?.is_verify == true){
                                            login(values);
                                        }            
                                    },
                                })
                            }}
                            requiredMark={false}
                            initialValues={{
                                remember: false,
                            }}
                        >
                            <div style={{marginBottom: "1rem", marginTop: "2rem"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <img className="logoLogin" src={`${DOMAIN_URL}/images/svg/logo_new_dark.svg`} alt="NUEMEK LOGO" style={{width: "250px"}}/>
                                </div>
                            </div>

                            <Form.Item
                                name="email"
                                label=" "
                                rules={[{ required: true, message: "Please Enter Email" }]}
                                style={{ marginBottom: "0px" }}
                            >
                                <Input size="large" placeholder="Email"/>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label=" "
                                // className="cus_input_pwd"
                                rules={[{ required: true, message: "Please Enter Password" }]}
                                style={{ marginBottom: "12px" }}
                            >
                                {/* <Input.Password
                                    id="input_logi_pwd"
                                    type="password"
                                    placeholder="Password"
                                    size="large"
                                /> */}
                                <Input.Password
                                    // id="input_logi_pwd"
                                    type="password"
                                    placeholder="Password"
                                    size="large"
                                />
                            </Form.Item>

                            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox style={{fontSize: "0.9rem", color: "#838383"}} className="checkbox_custom txt_onres">
                                        Remember me
                                    </Checkbox>
                                </Form.Item> */}
                                <div 
                                    style={{textDecoration: "underline", cursor: "pointer", color: "#838383"}}
                                    onClick={() => {
                                        pageTOKEN == undefined ? 
                                            goPage("forgot-pwd", "un-token")
                                        : pageTOKEN !== undefined &&
                                            goPage("forgot-pwd", "token")
                                        }
                                    }
                                >
                                    Forgot password?
                                </div>
                            </div>

                            <Button
                                className="btn_sign_in"
                                type="primary"
                                size="large"
                                htmlType="submit"
                                block
                                style={{boxShadow: "unset"}}
                            >
                                {t("Sign in")}
                            </Button>

                            <div style={{textAlign: "center", margin: "10px 0", color: "#838383"}}>Or</div>

                            <GoogleBTN 
                                aftersocialLGN={aftersocialLGN}
                            />

                            <div style={{color: "#838383", marginTop: "2rem", textAlign: "center", marginBottom: "1.5rem"}}>
                                <span>Don't have an account? </span>
                                <span 
                                    style={{
                                        color: "#FF9823",
                                        cursor: "pointer"
                                    }} 
                                    onClick={() => {
                                        pageTOKEN == undefined ? 
                                            goPage("register", "un-token")
                                        : pageTOKEN !== undefined &&
                                            goPage("register", "token")
                                        }
                                    }
                                >
                                    Signup
                                </span>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </div>
        </Layout>
    )
}