import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Spin, Upload, Image, notification, UploadProps, message, InputNumber } from "antd";
import { API_URL, DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { runes } from 'runes2';
import { axiosInstance } from "@refinedev/strapi-v4";
import { ExclamationCircleOutlined, CheckCircleFilled, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs from "dayjs";
import { getValueProps } from "@pankod/refine-strapi-v4";
import { ModalConfirm } from "components/confirm_md";

let dateNow: any = new Date().toISOString();
let nf = new Intl.NumberFormat('en-US');
export const PayPage: React.FC<IResourceComponentsProps> = () => {
    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[5];

    const [isLoading, setLoading] = useState<boolean>(true);

    const t = useTranslate();
    // const [formBF] = Form.useForm<any>();
    // const [formAT] = Form.useForm<any>();
    const [form] = Form.useForm<any>();
    const { data: user } = useGetIdentity<any>();
    const { replace, push} = useNavigation();
    const format = 'YYYY-MM-DD HH:mm';
    const USERID: any = localStorage.getItem("USERID");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const [modal, contextHolder] = Modal.useModal();

    const [isConfirm, setConfirm] = useState<boolean>(false);
    const [productVal, setproductVal] = useState<any>();
    const [productVat, setproductVat] = useState<any>();
    const [topupVal, settopupVal] = useState<any>();
    const [isAlert, setAlert] = useState<any>("#000000");
    const [isAlertBANK, setAlertBANK] = useState<any>("none");
    const [afterConfirm, setafterConfirm] = useState<boolean>(false);

    const [DTBANK, setDTBANK] = useState<any>([]);
    const [slBANK, setBANK] = useState<any>();
    const [SLP, setSLP] = useState<any>();
    const [dateSLP, setdateSLP] = useState<any>();
    const [dataPD, setdataPD] = useState<any>();

    const [mdSubmit, setmdSubmit] = useState(false);
    const [mdCancel, setmdCancel] = useState(false);

    const { data: dataBank } = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/bankacc/findAll`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                let newData: any = [];
                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        id: dataLoad[index]?._id,
                        account_name: dataLoad[index]?.account_name,
                        account_no: Number(dataLoad[index]?.account_no),
                        bankOBJ: 
                        {
                            id: dataLoad[index]?.bank_master_obj[0]?._id,
                            name: dataLoad[index]?.bank_master_obj[0]?.name_th,
                            name_en: dataLoad[index]?.bank_master_obj[0]?.name_en,
                            logo: dataLoad[index]?.bank_master_obj[0]?.logo_url
                        }
                        ,
                    })
                }

                setDTBANK((pre: any) => newData);
                setBANK({
                    id: newData[0]?.id,
                    item: {
                        account_name: newData[0]?.account_name,
                        account_no: newData[0]?.account_no,
                        bank_name: newData[0]?.bankOBJ?.name,
                        logo: newData[0]?.bankOBJ?.logo
                    }
                });

                setLoading(false);
            },
        },
    });

    const { data: dataTarn, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionDetail`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: pageTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataPD((pre: any) => dataLoad);
                setproductVal(dataLoad?.expense);
                setproductVat(dataLoad?.vat);
                settopupVal(dataLoad?.total_price.replace(".00",""));

                form?.setFieldsValue({
                    // total_amount: dataLoad?.total_price.replace(".00",""),
                    total_amount: nfformat(dataLoad?.total_price),
                    topup_timestamp: new Date().toISOString()
                })
            },
        },
    });

    const faildNoti = () => {
        notification['error']({
          message: "Faild",
          description: t("บันทึกข้อมูลล้มเหลว กรุณาลองใหม่อีกครั้ง"),
        });
    };

    const props: UploadProps = {
        name: 'files',
        accept: ".png,.jpeg,.jpg",
        action: `${API_URL}/api/upload`,
        headers: { Authorization: `Bearer ${TOKEN}`},
        maxCount: 1,
        listType: "picture",
        multiple: false,
        onChange(e: any) {
            if(e?.file?.status === "removed") {
                let res = e?.file?.response[0];
                setSLP(undefined);
            }else if(e?.file?.status === "done"){
                let res = e?.file?.response[0];
                setSLP({
                    id: res?.id,
                    url: res?.url,
                    name: res?.name
                });
            }
        },
        beforeUpload: (file) => {
            const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
            if (!validateSize) {
              message.error(`file size is over 5mb.`);
            }
            return validateSize || Upload.LIST_IGNORE;
        },
    };

    const onCheckConfirm = (e: CheckboxChangeEvent) => { 
        setConfirm(e.target.checked);

        if(e.target.checked == true){
            setAlert("#000000");
        }
    };

    const changeslip_Date:any = (dateString: string) => {
        if(dateString !== ""){
            setdateSLP(dayjs(dateString).toISOString());
        }else if(dateString == ""){
            setdateSLP(undefined);
        }
    }

    function cancelODR(){
        setafterConfirm(false);
        setBANK(undefined);
        setSLP(undefined);
        setdateSLP(undefined);
        form?.resetFields();
        form?.resetFields();
    }

    function copytoClipBoard(text: any){
        navigator.clipboard.writeText(text);
        message.success(`Copy text to Clipboard`);
    }

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    function clickSubmit(){
        if(isConfirm == true){
            form?.submit()
        }else{
            setAlert("#ff4d4f")
        }
    }
    
    return (
        <Spin spinning={isLoading}>
            <div style={{display: "flex", justifyContent: "start"}}>
                <div className="layout_panel" style={{width: "1280px"}}>
                    {contextHolder}

                    <div style={{marginBottom: "1rem"}}>
                        <div style={{fontSize: "26px", fontWeight: 500, color: "#EE642A"}}>{"Payment #" + dataPD?.invoice_no}</div>
                        <div style={{cursor: "pointer", color: "#3EA5F1"}} onClick={() => push(`/cart/cart-detail/${pageTOKEN}`)}>{"Back to invoices"}</div>
                    </div>

                    <Row gutter={[56,24]}>
                        <Col className="gutter-row" span={7}>
                            {DTBANK?.length > 0 && DTBANK?.map((item: any, key: any) => {
                                return (
                                    <Card 
                                        key={key} 
                                        className={`card_custom_body_billing ${slBANK?.id == item?.id ? "card-billing-selected": ""}`}
                                        onClick={() => {
                                            if(afterConfirm == false){
                                                setBANK({
                                                    id: item?.id,
                                                    item: {
                                                        account_name: item?.account_name,
                                                        account_no: item?.account_no,
                                                        bank_name: item?.bankOBJ?.name,
                                                        logo: item?.bankOBJ?.logo
                                                    }
                                                });
        
                                                if(isAlertBANK == "block"){
                                                    setAlertBANK("none");
                                                }
                                            }else{

                                            }
                                        }}
                                        style={{
                                            marginBottom: DTBANK?.length -1 == key ? "0px" : "1rem",
                                            cursor: afterConfirm == false ? "pointer" : "not-allowed"
                                        }}
                                    >
                                        <Row gutter={[12,24]}>
                                            <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <div style={{position: "absolute", width: "60%"}}>
                                                    <Image src={item?.bankOBJ?.logo} preview={false}/>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={18}>
                                                <div>
                                                    <div>{item?.bankOBJ?.name}</div>
                                                    <div>{item?.account_no} <span style={{marginLeft: "0.5rem", cursor: "copy"}}><CopyOutlined onClick={() => copytoClipBoard(String(item?.account_no))}/></span></div>
                                                    <div style={{color: "#A3A3A3"}}>{item?.account_name}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            })}
                            
                            <div style={{color: "#ff4d4f", display: isAlertBANK, marginTop: "1rem"}}>กรุณาเลือกธนาคารที่ต้องการจ่ายเงิน</div>
                        </Col>
                        <Col className="gutter-row" span={13}>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={async (values: any) => {
                                    let amount: any = values?.total_amount?.replace("THB","")?.replace(",", "")?.replace(" ", "")
                                    axiosInstance.post(`${SOURCE_URL}/api/v1/topup/create`,
                                        {
                                            transaction_id: pageTOKEN,
                                            amount: String(amount),
                                            topup_timestamp: dateSLP,
                                            bank_obj: {
                                                id: slBANK?.id,
                                                acount_no: slBANK?.item?.account_no,
                                                bank_name: slBANK?.item?.bank_name
                                            },
                                            user_profile: {
                                                id: user?.user_profile?.id,
                                                first_name: user?.user_profile?.first_name,
                                                last_name: user?.user_profile?.last_name,
                                            },
                                            create_by: {
                                                id: user?.id,
                                                user_name: user?.name
                                            },
                                            paid_slip:{
                                                id: SLP?.id,
                                                url: SLP?.url,
                                                name: SLP?.name,
                                            }
                                        },
                                        { headers: { Authorization: `Bearer ${TOKEN}` } }
                                        )
                                    .then((response) => {
                                        setmdSubmit(true)
                                        // setconFopen(true)
                                    })
                                    .catch(error => {
                                        faildNoti()
                                        cancelODR();
                                    });
                                }}
                            >
                                <Card style={{marginBottom: "1.5rem"}}>
                                    <Form.Item
                                        label="ราคาสินค้าที่ต้องชำระ"
                                        className="disable_alert"
                                    >
                                        <Input 
                                            value={productVal ? nfformat(productVal) : ""}
                                            disabled
                                            size="large"
                                        />
                                    </Form.Item>

                                    <Form.Item label="มูลค่าภาษี (VAT 7%)">
                                        <Input
                                            value={productVat ? nfformat(productVat) : ""}
                                            disabled
                                            placeholder={t("มูลค่าภาษี (VAT 7%)")}
                                            size="large"
                                        />
                                    </Form.Item>

                                    <Form.Item label="ยอดรวมสุทธิที่ต้องชำระ">
                                        <Input
                                            value={topupVal ? nfformat(topupVal) : ""}
                                            disabled
                                            placeholder={t("ยอดรวมสุทธิที่ต้องชำระ")}
                                            size="large"
                                        />
                                    </Form.Item>
                                </Card>

                                <Card>
                                    <Row gutter={[24,24]} style={{marginBottom: "1rem"}}>
                                        <Col className="gutter-row" span={7}>
                                            <div style={{marginTop: "10px"}}>วันที่และเวลา ที่ชำระเงิน<span style={{color: "#ff4d4f"}}> *</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={17}>
                                            <Form.Item
                                                name="topup_timestamp"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select appointment date.'
                                                    },
                                                ]}
                                                style={{marginBottom: 0}}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : "",
                                                })}
                                            >
                                                <DatePicker
                                                    style={{width: "100%"}}
                                                    size="large"
                                                    showTime={{ format: 'HH:mm' }}
                                                    onChange={(date, dateString) => changeslip_Date(dateString)}
                                                    format={format}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={7}>
                                            <div style={{marginTop: "10px"}}>จำนวนเงินที่ชำระ<span style={{color: "#ff4d4f"}}> *</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={17}>
                                            <Form.Item
                                                name="total_amount"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter amount.'
                                                    },
                                                ]}
                                                style={{marginBottom: 0}}
                                            >
                                                {/* <InputNumber<number>
                                                    controls={false}
                                                    parser={(value) => value ? value?.replace(/\$\s?|(,*)/g, '') as unknown as number : 0}
                                                    // formatter={(value) => `${value}.00`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    formatter={(value: any) => Number.parseFloat(value ? value : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    placeholder={t("ยอดรวมสุทธิที่ต้องชำระ")}
                                                    size="large"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }}
                                                    style={{width: "100%"}}
                                                    disabled
                                                /> */}
                                                <Input 
                                                    disabled
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name="slip_topup"
                                        valuePropName="fileList"
                                        getValueProps={(data) => getValueProps(data, API_URL)}

                                        rules={[
                                            {
                                                required: true, message: 'Please select image.'
                                            },
                                        ]}
                                    >

                                        <Upload.Dragger
                                            {...props}
                                        >
                                            <div style={{padding: "1rem"}}>
                                                <div>
                                                    <span style={{fontSize: "30px", marginRight: "0.5rem"}}><DownloadOutlined /></span>
                                                    <span style={{fontSize: "20px"}}>{t("แนบหลักฐานการชำระเงิน")}<span style={{color: "#ff4d4f"}}> *</span></span>
                                                </div>
                                                <div style={{color: "#FF5252"}}>
                                                    Maximum File size: 5MB
                                                </div>
                                                <div style={{color: "#FF5252"}}>
                                                    (File : JPG,GIF,PNG,PDF)
                                                </div>
                                            </div>
                                        </Upload.Dragger>
                                    </Form.Item>

                                    <Form.Item>
                                        <Checkbox checked={isConfirm} onChange={onCheckConfirm}>
                                            <span style={{color: isAlert}}>ฉันได้ตรวจสอบข้อมูลอย่างถูกต้องแล้ว<span style={{color: "#ff4d4f"}}> *</span></span>
                                        </Checkbox>
                                    </Form.Item>
                                    
                                    <Button
                                        type="primary"
                                        size="large"
                                        // htmlType="submit"
                                        block
                                        style={{marginBottom: "1rem", height: "50px", backgroundColor: "#F48515"}}
                                        onClick={() => clickSubmit()}
                                    >
                                        {t("ยืนยันการชำระเงิน")}
                                    </Button>

                                    <Button
                                        type="primary"
                                        size="large"
                                        block
                                        danger
                                        // onClick={confirm}
                                        onClick={() => setmdCancel(true)}
                                        style={{height: "50px"}}
                                    >
                                        {t("ยกเลิกการชำระเงิน")}
                                    </Button>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>

            <ModalConfirm 
                open={mdSubmit} 
                setopen={setmdSubmit} 
                type="success"
                afterSubmit={(e: any) => {if(e == "submit"){push(`/billing`);}else if(e == "cancel"){}}} 
                footerButton="1btn"
                title="Thank You !"
                oktxt="OK"
                description={
                    <div>
                        <div>Payment will be confirmed within</div>
                        <div>1-3 working days from payment date</div>
                    </div>
                }
            />

            <ModalConfirm 
                open={mdCancel} 
                setopen={setmdCancel} 
                type="error" 
                afterSubmit={(e: any) => {if(e == "submit"){push(`/cart/cart-detail/${pageTOKEN}`);}else if(e == "cancel"){}}} 
                title="Are you sure ?"
                description="If you confirm, This process cannot be undone."
            />
        </Spin>
    )
}