import { BarsOutlined, DashboardOutlined, LeftOutlined, LogoutOutlined, RightOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { CanAccess, ITreeMenu, useActiveAuthProvider, useLink, useLogout, useMenu, useNavigation, useRefineContext, useRouterContext, useRouterType, useTitle, useTranslate, useWarnAboutChange, useIsExistAuthentication, pickNotDeprecated, useGetIdentity, useList, usePermissions } from "@refinedev/core";
import { Button, Divider, Drawer, Grid, Layout, Menu, Tag, theme } from "antd";
import React, { CSSProperties} from "react";
import { RefineThemedLayoutV2SiderProps, ThemedTitleV2, useThemedLayoutContext } from "@refinedev/antd";
import { useNavigate } from "react-router-dom";
import CustomMENU from "./custom_menu";

//ICON

const { SubMenu } = Menu;
const { useToken } = theme;

// let permission: any = undefined;
export const Sider: React.FC<RefineThemedLayoutV2SiderProps> = ({
  Title: TitleFromProps,
  render,
  meta,
  fixed,
  activeItemDisabled = false,
}) => {
    const { token } = useToken();
    const {
        siderCollapsed,
        setSiderCollapsed,
        mobileSiderOpen,
        setMobileSiderOpen,
    } = useThemedLayoutContext();

    const isExistAuthentication = useIsExistAuthentication();
    const routerType = useRouterType();
    const NewLink = useLink();
    const { warnWhen, setWarnWhen } = useWarnAboutChange();
    const { Link: LegacyLink } = useRouterContext();
    const Link = routerType === "legacy" ? LegacyLink : NewLink;
    const TitleFromContext = useTitle();
    const RenderToTitle = TitleFromProps ?? TitleFromContext ?? ThemedTitleV2;
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
    const breakpoint = Grid.useBreakpoint();
    const { hasDashboard } = useRefineContext();
    const authProvider = useActiveAuthProvider();
    const { mutate: mutateLogout } = useLogout();
    const { data: user } = useGetIdentity<any>();
    const navigate = useNavigate();

    const { replace } = useNavigation();

    const role_data = usePermissions<any>();
    const irole = role_data?.data;

    const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;
  
    const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
        return tree.map((item: ITreeMenu) => {
            const {
                icon,
                label,
                route,
                key,
                name,
                children,
                parentName,
                meta,
                options,
            } = item;
                if (children.length > 0){
                    return (
                        <CanAccess
                            key={item.key}
                            resource={name.toLowerCase()}
                            action="list"
                            params={{
                                resource: item,
                            }}
                        >
                            <SubMenu
                                key={item.key}
                                icon={icon ?? <UnorderedListOutlined />}
                                title={label}
                            >
                                {renderTreeView(children, selectedKey)}
                            </SubMenu>
                        </CanAccess>
                    );
                }
            // }

            const isSelected = key === selectedKey;
            const isRoute = !(
                pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
                    undefined && children.length === 0
            );

            const linkStyle: CSSProperties = activeItemDisabled && isSelected ? { pointerEvents: "none" }: {};

            return (
                <CanAccess
                    key={item.key}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{
                        resource: item,
                    }}
                >
                    {/* {checkedPermission?.active == true && */}
                        <Menu.Item
                            key={item.key}
                            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                            style={linkStyle}
                        >
                            <Link to={route ?? ""} style={linkStyle}>
                                {label}
                            </Link>
                            {!siderCollapsed && isSelected && (
                                <div className="ant-menu-tree-arrow" />
                            )}
                        </Menu.Item>
                    {/* } */}
                </CanAccess>
            );
        });
    };

    const handleLogout = () => {
        if (warnWhen) {
            const confirm = window.confirm(
                translate(
                    "warnWhenUnsavedChanges",
                    "Are you sure you want to leave? You have unsaved changes.",
                ),
            );

            if (confirm) {
                setWarnWhen(false);
                // replace('/login');
                mutateLogout();
            }
        } else {
            // replace('/login');
            mutateLogout();
        }
    };

    const logout = isExistAuthentication && (
        <Menu.Item
            key="logout"
            onClick={() => {
                mutateLogout();
            }}
            icon={<LogoutOutlined />}
        >
            Logout
        </Menu.Item>
    );

    const dashboard = hasDashboard ? (
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/">{translate("dashboard.title", "Dashboard")}</Link>
            {!siderCollapsed && selectedKey === "/" && (
                <div className="ant-menu-tree-arrow" />
            )}
        </Menu.Item>
    ) : null;

    const items = renderTreeView(menuItems, selectedKey);

    const renderSider = () => {
        if (render) {
            return render({
                dashboard,
                items,
                logout,
                collapsed: siderCollapsed,
            });
        }

        return(<CustomMENU />);
    };

    const renderMenu = () => {
        return (
            <>
                <Menu
                    selectedKeys={selectedKey ? [selectedKey] : []}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    style={{
                        paddingTop: "8px",
                        border: "none",
                        overflow: "auto",
                        // height: "calc(100% - 72px)",
                        height: "calc(100% - 95px)",
                    }}
                    onClick={() => {
                        setMobileSiderOpen(false);
                    }}
                >
                    {renderSider()}
                </Menu>
            </>
        );
        
    };

    const renderDrawerSider = () => {
        return (
        <>
            <Drawer
                open={mobileSiderOpen}
                onClose={() => setMobileSiderOpen(false)}
                placement="left"
                closable={false}
                width={220}
                bodyStyle={{
                    padding: 0,
                }}
                maskClosable={true}
            >
                <Layout.Sider
                    width={220}
                    style={{
                        height: "100vh",
                        backgroundColor: "#202134",
                        // backgroundColor: token.colorBgContainer,
                        borderRight: `1px solid ${token.colorBgElevated}`,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            padding: "0 16px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            height: "64px",
                            backgroundColor: token.colorBgElevated,
                            borderBottom: `1px solid ${token.colorBorder}`,
                        }}
                    >
                        <RenderToTitle collapsed={false} />
                    </div>
                    {renderMenu()}
                </Layout.Sider>
            </Drawer>
            <Button
                // style={drawerButtonStyles}
                style={{position: "fixed", zIndex: 99}}
                size="large"
                onClick={() => setMobileSiderOpen(true)}
                icon={<BarsOutlined />}
            />
        </>
        );
    };

    if (isMobile) {
        return renderDrawerSider();
    }

    const siderStyles: React.CSSProperties = {
        backgroundColor: "#fff",
        borderRight: `1px solid ${token.colorBgElevated}`,
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 5px 0px",
        zIndex: 666,
    };

    if (fixed) {
        siderStyles.position = "fixed";
        siderStyles.top = 0;
        siderStyles.height = "100vh";
        siderStyles.zIndex = 999;
    }

    return (<>
        <Layout.Sider
            style={siderStyles}
            collapsible
            collapsed={siderCollapsed}
            onCollapse={(collapsed, type) => {
                if (type === "clickTrigger") {
                    setSiderCollapsed(collapsed);
                }
            }}
            collapsedWidth={80}
            width={200}
            breakpoint="lg"
            trigger={
                <Button
                    type="text"
                    style={{
                        borderRadius: 0,
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#fff",
                        borderRight: "1px solid #fff"
                    }}
                >
                    {siderCollapsed ? (
                        <RightOutlined
                            style={{
                                color: token.colorPrimary,
                            }}
                        />
                    ) : (
                        <LeftOutlined
                            style={{
                                color: token.colorPrimary,
                            }}
                        />
                    )}
                </Button>
            }
        >
            <div 
                className="sider_custom"
                style={{
                    position: "fixed",
                    width: siderCollapsed ? "80px" : "200px",
                    transition: "0.3s"
                }}
            >
                <div
                    style={{
                        width: siderCollapsed ? "80px" : "200px",
                        padding: siderCollapsed ? "0" : "0 16px",
                        display: "flex",
                        justifyContent: siderCollapsed
                            ? "center"
                            : "flex-start",
                        alignItems: "center",
                        height: "64px",
                        fontSize: "14px",
                    }}
                >
                    <RenderToTitle collapsed={siderCollapsed} />
                </div>

                {/* <Divider style={{margin: 0}}/> */}
                
                
                {renderMenu()}
                
                {/* <div style={{textAlign: "center"}}><Tag color="volcano"><div style={{ fontWeight: "bold", transition: "0.3s" }}>{!siderCollapsed ? "VERSION : 1.0.0" : "1.0.0"}</div></Tag></div> */}
            </div>
        </Layout.Sider>
    </>
  );
};
