import { IResourceComponentsProps, useCustom, useGetIdentity, useTranslate } from "@refinedev/core"
import { API_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import { axiosInstance } from "@refinedev/strapi-v4";
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import phoneList from './world.json.custom/list.json'
import { CustomPhoneInput } from "components/phoneInput";
import "./styles.css";
import { FormOrganization, FormPersonal } from "./form";

export const ProfilePage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { data: user } = useGetIdentity<any>();
    const USER: any = localStorage.getItem("USER");
    const USERID: any = localStorage.getItem("USERID");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);

    const [form] = Form.useForm<any>();

    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [modal, contextHolder] = Modal.useModal();

    const [accountType, setaccountType] = useState<any>("Personal");
    const [OGN, setOGN] = useState<any>("");
    const [branchID, setbranchID] = useState<any>();
    
    //////////// personal ////////////
    const [personalAdds, setpersonalAdds] = useState<any>({
        address: undefined,
        province: undefined,
        country: undefined,
        tambon: undefined,
        amphure: undefined,
        postcode: undefined,
        short_tel: undefined,
        tel: undefined,
        dial_code: undefined,
        first_name: undefined,
        last_name: undefined
    });
    const [personalPhone, setpersonalPhone] = useState<any>({
        value: "TH",
        label: `${"TH"}  +${"66"}`,
        short: "TH",
        tel_no: "",
    });
    //////////// organization ////////////
    const [organizationAdds, setorganizationAdds] = useState<any>({
        org_address: undefined,
        organization_name: undefined,
        tax_id: undefined,
        branch_type: undefined,
        branch_id: undefined,
        org_last_name: undefined,
        org_first_name: undefined,
        org_province: undefined,
        org_amphure: undefined,
        org_tambon: undefined,
        org_postcode: undefined,
        org_country: undefined,
        org_tel: undefined,
        org_short_tel: undefined,
        dial_code: undefined,
    });
    const [organizePhone, setorganizePhone] = useState<any>({
        value: "TH",
        label: `${"TH"}  +${"66"}`,
        short: "TH",
        tel_no: "",
    });
    const [branchType, setbranchType] = useState<any>("Other");
    /////////////////////////////////////
    
    const { data: dataAPI, refetch } = useCustom<any>({
        url: `${API_URL}/api/user-profiles/UserPermission`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: USER
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataProfile: any = data?.data[0]?.user_detail;
                let dialPersonal: any = phoneList?.filter((item: any) => item?.code == dataProfile?.short_tel)[0];
                let dialOrganiz: any = phoneList?.filter((item: any) => item?.code == dataProfile?.org_short_tel)[0];

                //////////// personal ////////////
                setpersonalAdds({
                    address: dataProfile?.address,
                    province: dataProfile?.province,
                    country: dataProfile?.country,
                    tambon: dataProfile?.tambon,
                    amphure: dataProfile?.amphure,
                    postcode: dataProfile?.postcode,
                    short_tel: dataProfile?.short_tel,
                    tel: dataProfile?.tel,
                    dial_code: dialPersonal?.dial_code,
                    first_name: dataProfile?.first_name,
                    last_name: dataProfile?.last_name,
                });
                setpersonalPhone({
                    value: dataProfile?.short_tel ? dataProfile?.short_tel : "TH",
                    label: `${dataProfile?.short_tel ? dataProfile?.short_tel : "TH"}  +${dialPersonal ? dialPersonal?.dial_code: "66"}`,
                    short: dataProfile?.short_tel ? dataProfile?.short_tel : "TH",
                    tel_no: dataProfile?.tel ? dataProfile?.tel : undefined,
                });

                //////////// organization ////////////
                setorganizationAdds({
                    org_address: dataProfile?.org_address,
                    organization_name: dataProfile?.organization_name,
                    tax_id: dataProfile?.tax_id,
                    branch_type: dataProfile?.branch_type,
                    branch_id: dataProfile?.branch_id,
                    org_last_name: dataProfile?.org_last_name,
                    org_first_name: dataProfile?.org_first_name,
                    org_province: dataProfile?.org_province,
                    org_amphure: dataProfile?.org_amphure,
                    org_tambon: dataProfile?.org_tambon,
                    org_postcode: dataProfile?.org_postcode,
                    org_country: dataProfile?.org_country,
                    org_tel: dataProfile?.org_tel,
                    org_short_tel: dataProfile?.org_short_tel,
                    dial_code: dialOrganiz?.dial_code,
                });
                setorganizePhone({
                    value: dataProfile?.org_short_tel ? dataProfile?.org_short_tel : "TH",
                    label: `${dataProfile?.org_short_tel ? dataProfile?.org_short_tel : "TH"}  +${dialOrganiz ? dialOrganiz?.dial_code: "66"}`,
                    short: dataProfile?.org_short_tel ? dataProfile?.org_short_tel : "TH",
                    tel_no: dataProfile?.org_tel ? dataProfile?.org_tel : undefined,
                });
                setbranchID(dataProfile?.branch_id)
                /////////////////////////////////////
                

                if(dataProfile?.account_type == "Personal"){
                    setFields(dataProfile, "personal")
                    setbranchType(undefined);
                }else if(dataProfile?.account_type == "Organization"){
                    setFields(dataProfile, "organization")
                    setbranchType(dataProfile?.branch_type);
                }else{
                    setFields(dataProfile, "personal")
                    setbranchType(undefined);
                }

                setaccountType(dataProfile?.account_type ? dataProfile?.account_type: "Personal");
                form?.setFieldsValue({
                    account_type: dataProfile?.account_type ? dataProfile?.account_type : "Personal",
                })

                setLoading(false);
            },
        },
    });

    const confirm = () => {
        modal.confirm({
          title: 'คุณต้องการ Update ข้อมูลโปรไฟล์',
          icon: <ExclamationCircleOutlined />,
          content: 'หากยืนยัน ระบบจะทำการ Update ข้อมูลโปรไฟล์',
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
          okType: 'danger',
          onOk() {
            onSubmit()
          }
        });
    };

    function onchangeBranchID(e: any){
        let res: any = e?.target?.value?.length;
        let id: any;

        if(res < 5 && res > 0){
            let loop: any = 5 - res;
            let numberPlus: any = e?.target?.value;
            for (let index = 0; index < loop; index++) {
                numberPlus = "0" + numberPlus;
            }

            id = numberPlus;
        }else if(res == 5){
            id = e?.target?.value;
        }else if(res == 0){
            id = undefined;
        }

        setbranchID(id);
        onChangeProfile(id, "branch_id", "organization")
    }

    function onChangeAccount(e: any){
        setaccountType(e);
        setLoading(true);
        if(e == "Personal"){
            setFields(personalAdds, "personal")
        }else if(e == "Organization"){
            setFields(organizationAdds, "organization")
        }
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }

    function onChangeProfile(e: any, field: any, mode: "personal" | "organization"){
        if(e && e !== ""){
            if(mode == "personal"){
                let newDT: any = personalAdds;
                newDT[field] = e;
                settk(!tk);
                setpersonalAdds((pre: any) => newDT);
            }else if(mode == "organization"){
                let newDT: any = organizationAdds;
                newDT[field] = e;
                settk(!tk);
                setorganizationAdds((pre: any) => newDT);
            }
        }
    }

    function setFields(data: any, mode: "personal" | "organization"){
        if(mode == "personal"){
            form?.setFieldsValue({
                first_name: data?.first_name ? data?.first_name : user?.user_profile?.first_name,
                last_name: data?.last_name ? data?.last_name : user?.user_profile?.last_name,
                address: data?.address,
                province: data?.province,
                country: data?.country,
                tambon: data?.tambon,
                amphure: data?.amphure,
                postcode: data?.postcode,
            });
        }else if(mode == "organization"){
            form?.setFieldsValue({
                org_first_name: data?.org_first_name,
                org_last_name: data?.org_last_name,
                org_address: data?.org_address,
                org_province: data?.org_province,
                org_country: data?.org_country,
                org_tambon: data?.org_tambon,
                org_amphure: data?.org_amphure,
                org_postcode: data?.org_postcode,
                organization_name: data?.organization_name,
                tax_id: data?.tax_id,
                branch_type: data?.branch_type ? data?.branch_type: "Other",
                branch_id: data?.branch_id
            });
        }
    }

    function onSubmit(){
        let personalTel: any = personalPhone?.tel_no ? personalPhone?.tel_no[0] : undefined;
        let organizationTel: any = organizePhone?.tel_no ? organizePhone?.tel_no[0] : undefined;
        
        axiosInstance.post(`${API_URL}/api/user-profiles/UpdateUser`,
            {
                user_id: USER,
                profile_id: USERID,
                account_type: accountType ? accountType : "Personal",
                //////////// personal ////////////
                first_name: personalAdds?.first_name,
                last_name: personalAdds?.last_name,
                address: personalAdds?.address,
                short_tel: personalPhone?.short,
                tel: personalTel !== undefined ? personalTel == "0" ? personalPhone?.tel_no : "0" + personalPhone?.tel_no : undefined,
                province: personalAdds?.province,
                country: personalAdds?.country,
                tambon: personalAdds?.tambon,
                amphure: personalAdds?.amphure,
                postcode: personalAdds?.postcode,
                //////////// organization ////////////
                org_address: organizationAdds?.org_address,
                organization_name: organizationAdds?.organization_name,
                tax_id: organizationAdds?.tax_id,
                branch_type: organizationAdds?.branch_type,
                branch_id: branchID,
                org_last_name: organizationAdds?.org_last_name,
                org_first_name: organizationAdds?.org_first_name,
                org_province: organizationAdds?.org_province,
                org_amphure: organizationAdds?.org_amphure,
                org_tambon: organizationAdds?.org_tambon,
                org_postcode: organizationAdds?.org_postcode,
                org_country: organizationAdds?.org_country,
                org_tel: organizationTel !== undefined ? organizationTel == "0" ? organizePhone?.tel_no : "0" + organizePhone?.tel_no : undefined,
                org_short_tel: organizePhone?.short,
                /////////////////////////////////////
            },
            { headers: { Authorization: `Bearer ${TOKEN}` } }
            )
        .then((response) => {
            localStorage.setItem("ACCOUNT","T");
            window?.location?.reload();
        })
        .catch(error => {

        });
    }

    return (
        <Spin spinning={isLoading}>
            <div className="layout_account">
                {contextHolder}
                <div>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={async (values: any) => {
                            confirm()
                        }}
                    >
                        <div style={{marginBottom: "1rem"}}>
                            <Row gutter={24}>
                                <Col className="gutter-row" span={12}> {/* ACCOUNT TYPE */}
                                    <Form.Item 
                                        label="Account Type"
                                        name="account_type"
                                        style={{marginBottom: 15}}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select account type"
                                            },
                                        ]}
                                    >
                                        <Select 
                                            options={[
                                                {
                                                    value: "Personal", label: "Personal"
                                                },
                                                {
                                                    value: "Organization", label: "Organization"
                                                }
                                            ]}
                                            defaultValue={"Personal"}
                                            onChange={(e) => onChangeAccount(e)}
                                        />
                                    </Form.Item>
                                </Col>
                                {accountType == "Organization" ?
                                    <FormOrganization
                                        dataProfile={[]}
                                        organizationPhone={organizePhone}
                                        setorganizationPhone={setorganizePhone}
                                        onChangeProfile={onChangeProfile}
                                        branchType={branchType}
                                        setbranchType={setbranchType}
                                        onchangeBranchID={onchangeBranchID}
                                    />
                                :accountType == "Personal" &&
                                    <FormPersonal 
                                        dataProfile={[]}
                                        personalPhone={personalPhone}
                                        setpersonalPhone={setpersonalPhone}
                                        onChangeProfile={onChangeProfile}
                                    />
                                }
                            </Row>
                        </div>

                        <Button
                            type="primary"
                            size="large"
                            // htmlType="submit"
                            style={{backgroundColor: "#ff8d00"}}
                            block
                            onClick={() => form?.submit()}
                            icon={<SaveOutlined />}
                        >
                            {t("Update Profile")}
                        </Button>
                    </Form>
                </div>
            </div>
        </Spin>
    )
}