import { Col, Layout, Row } from "antd"
import { HeadernoAuth } from "components/unAuth/header"
import "./styles.css";
import { DOMAIN_URL } from "../../constants";

export const ContactPage: React.FC = () => {
    return(
        <Layout className="layout_noauth">
            <div className="body_noauth">
                <HeadernoAuth sticky/>
                <div style={{display: "flex", justifyContent: "center", backgroundColor: "#fff", padding: "3rem 0"}}>
                    <div className="container_landding_noauth">
                        <div className="banner_contact_noauth">
                            <div className="fadeup_animations">
                                <div style={{fontSize: "2.5rem", fontWeight: 700}}>
                                    <span style={{color: "#F48515"}}>GET IN</span>
                                    <span style={{color: "#fff"}}> TOUCH</span>
                                </div>
                                <div style={{fontSize: "1rem", marginTop: "0.5rem"}}>
                                    <span style={{color: "#fff"}}>
                                        We would love to help you on your journey.<br />
                                        Please use the line, email or phone us directly and let us know how
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", backgroundColor: "#fff"}}>
                    <div className="container_landding_noauth">
                        <Row gutter={12} style={{padding: "0 3rem"}} className="fadeup_animations">
                            <Col className="gutter-row" span={18}>
                                <div style={{color: "#F48515", fontSize: "1.3rem", fontWeight: 700}}>บริษัท เหนือเมฆ โซลูชั่น จำกัด สำนักงานใหญ่</div>
                                <Row gutter={[12, 12]} style={{marginTop: "1rem"}}>
                                    <Col className="gutter-row" span={1}>
                                        <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/icon/icon_location.svg`} alt="ICON"/>
                                    </Col>
                                    <Col className="gutter-row" span={23}>
                                        <div style={{fontSize: "16px", marginTop: "3px", color: "#7D7D7D", fontWeight: 500}}>
                                            172 ซอยลาซาล 48 ถนนสุขุมวิท 105<br />
                                            แขวงบางนาใต้ เขตบางนา กรุงเทพฯ 10260
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <div style={{color: "#F48515", fontSize: "1.3rem", fontWeight: 700}}>Contact Details</div>
                                <Row gutter={[12, 12]} style={{marginTop: "1rem"}}>
                                    <Col className="gutter-row" span={3}>
                                        <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/icon/icon_email.svg`} alt="ICON"/>
                                    </Col>
                                    <Col className="gutter-row" span={21}>
                                        <div style={{fontSize: "16px", marginTop: "3px", color: "#7D7D7D", fontWeight: 500}}>
                                            Email : info@nueamek.com
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={3}>
                                        <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/icon/icon_tel.svg`} alt="ICON"/>
                                    </Col>
                                    <Col className="gutter-row" span={21}>
                                        <div style={{fontSize: "16px", marginTop: "3px", color: "#7D7D7D", fontWeight: 500}}>
                                            Mobile : 062-686-5444
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={3}>
                                        <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/icon/icon_line.svg`} alt="ICON"/>
                                    </Col>
                                    <Col className="gutter-row" span={21}>
                                        <div style={{fontSize: "16px", marginTop: "3px", color: "#7D7D7D", fontWeight: 500}}>
                                            Line ID : @nueamek
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {/* <div className="bg_line"/> */}
        </Layout>
    )
}