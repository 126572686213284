import { Col, Form, Input, Row, Select } from "antd";
import { CustomPhoneInput } from "components/phoneInput";

interface IPROFILE {
    dataProfile: any
    organizationPhone: any
    setorganizationPhone: any
    branchType: any
    setbranchType: any
    onChangeProfile: (e: any, field: any, mode: "organization" | "organization") => void
    onchangeBranchID: (e: any) => void
}

export const FormOrganization: React.FC<IPROFILE> = ({
    organizationPhone,
    setorganizationPhone,
    onChangeProfile,
    branchType = "Other",
    setbranchType,
    onchangeBranchID
})=> {
    return(
        <>
            <Col className="gutter-row" span={12}> {/* COMPANY NAME */}
                <Form.Item 
                    label="Organization Name"
                    name="organization_name"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter organization"
                        },
                    ]}
                >
                    <Input
                        onChange={(e) => onChangeProfile(e?.target?.value, "organization_name", "organization")}
                    />
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* TAX ID */}
                <Form.Item 
                    label="Tax ID"
                    name="tax_id"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter tax id"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value.length == 13) {
                                    return Promise.resolve();
                                }

                                if (value.length < 13) {
                                    return Promise.reject(new Error('Tax ID is 13 charactor only'));
                                }

                                if (value.length == 0) {
                                    return Promise.reject(new Error('Please enter tax id'));
                                }
                            },
                        }),
                    ]}
                >
                    <Input
                        minLength={13}
                        maxLength={13}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                        onChange={(e) => onChangeProfile(e?.target?.value, "tax_id", "organization")}
                    />
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* BRANCH */}
                <Row gutter={6}>
                    <Col className="gutter-row" span={branchType == "Other" ? 12: 24}>
                        <Form.Item 
                            label="Branch"
                            name="branch_type"
                            rules={[{ required: true, message: "Please select branch type" }]}
                            style={{marginBottom: 0}}
                        >
                            <Select 
                                options={[
                                    {
                                        value: "Other", label: "Other"
                                    },
                                    {
                                        value: "Head Office", label: "Head Office"
                                    }
                                ]}
                                defaultValue={"Other"}
                                onChange={(e) => {
                                    setbranchType(e);
                                    onChangeProfile(e, "branch_type", "organization")
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {branchType == "Other" &&
                        <Col className="gutter-row" span={12}>
                            <Form.Item 
                                label=" "
                                name="branch_id"
                                rules={[{ required: true, message: "Please enter branch id" }]}
                                style={{marginBottom: 0, marginTop: "31px"}}
                                className="disable_txt"
                            >
                                <Input maxLength={5} onChange={(e) => onchangeBranchID(e)}/>
                            </Form.Item>
                        </Col>
                    }
                </Row>
            </Col>
            <Col className="gutter-row" span={12}> {/* FIRST NAME */}
                <Form.Item 
                    label="First Name"
                    name="org_first_name"
                    rules={[{ required: true, message: "Please Enter First Name" }]}
                    style={{marginBottom: 15}}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "org_first_name", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* LAST NAME */}
                <Form.Item 
                    label="Last Name"
                    name="org_last_name"
                    rules={[{ required: true, message: "Please Enter Last Name" }]}
                    style={{marginBottom: 15}}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "org_last_name", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* ADDRESS */}
                <Form.Item 
                    label="Address"
                    name="org_address"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter address"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "org_address", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* SUB DISTICT */}
                <Form.Item 
                    label="Sub Distict"
                    name="org_tambon"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter sub distict"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "org_tambon", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* DISTICT */}
                <Form.Item 
                    label="Distict"
                    name="org_amphure"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter distict"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "org_amphure", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* CITY */}
                <Form.Item 
                    label="City"
                    name="org_province"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter city"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "org_province", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* POSTAL */}
                <Form.Item 
                    label="Postal"
                    name="org_postcode"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter postal"
                        },
                    ]}
                >
                    <Input
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                        onChange={(e) => onChangeProfile(e?.target?.value, "org_postcode", "organization")}
                    />
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* COUNTRY */}
                <Form.Item 
                    label="Country"
                    name="org_country"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter country"
                        },
                    ]}
                >
                    <Input  onChange={(e) => onChangeProfile(e?.target?.value, "org_country", "organization")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* TEL */}
                <Form.Item
                    label="Tel"
                    // name="tel"
                    style={{marginBottom: 15}}
                >
                    <CustomPhoneInput 
                        defaultLocal="TH" 
                        selectWidth="60%"
                        popupWidth={330}
                        userPhone={organizationPhone}
                        setuserPhone={setorganizationPhone}
                    />
                </Form.Item>
            </Col>
        </>
    )
}