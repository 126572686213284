import { List as AntdList, Button, Card, Col, ConfigProvider, Divider, Form, Modal, Row, Segmented, Spin, Tabs, notification, } from "antd";
import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core"
import { DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { List } from "@refinedev/antd";
import { axiosInstance } from "@refinedev/strapi-v4";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import "./styles.css";

let dtProduct: any = [];
let dtLocal: any = [];
let nf = new Intl.NumberFormat('en-US');
export const ProductlistPage: React.FC<IResourceComponentsProps> = () => {

    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const t = useTranslate();
    const { TabPane } = Tabs;
    const { data: user } = useGetIdentity<any>();
    const { push } = useNavigation();
    const [modal, contextHolder] = Modal.useModal();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [tabm, settabm] = useState<any>("windows");
    const [selectSV, setSV] = useState<any>();
    const [selectLC, setLC] = useState<any>();

    const { data: dataProduct, refetch: refetchProduct } = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/product/findAll`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                let newDT: any = [];
                for (let i = 0; i < dataLoad?.length; i++) {  
                    newDT.push({
                        id: dataLoad[i]?._id,
                        name: dataLoad[i]?.name_th,
                        name_en: dataLoad[i]?.name_en,
                        price: Number(dataLoad[i]?.price),
                        is_active: dataLoad[i]?.is_active,
                        type: dataLoad[i]?.type,
                        service: []
                    });

                    if(dataLoad[i]?.product_detail?.length > 0){
                        let dtservice: any =[];

                        for (let ix = 0; ix < dataLoad[i]?.product_detail?.length; ix++) {
                            dtservice?.push({
                                id: dataLoad[i]?.product_detail[ix]?.id,
                                name: dataLoad[i]?.product_detail[ix]?.name_th,
                                name_en: dataLoad[i]?.product_detail[ix]?.name_en,
                                desc: dataLoad[i]?.product_detail[ix]?.description
                            })
                        }

                        newDT[i].service = dtservice;
                    }
                }

                if(newDT?.length > 0){ 
                    dtProduct = newDT;
                    onLoading();
                }
            },
        },
    });

    const { data: dataLocal, refetch: refetchLocal } = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/servlocation/findAll`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];
                for (let i = 0; i < dataLoad?.length; i++) {  
                    newDT.push({
                        id: dataLoad[i]?._id,
                        name: dataLoad[i]?.name_th,
                        name_en: dataLoad[i]?.name_en,
                        shortname: dataLoad[i]?.short_name,
                        flagURL: dataLoad[i]?.logo_url,
                    });
                }

                if(newDT?.length > 0){ 
                    dtLocal = newDT;
                    onLoading();
                }
                // setLoading(false);
            },
        },
    });

    const renderItem = (item: any) => {
        const {
            id,
            name,
            price,
            service,
        }: any = item;

        return (
            <AntdList.Item 
                key={id}
            >
                <AntdList.Item>
                    <Card
                        bordered={false}
                        className={`card_no_padding card-dp ${selectSV?.id == id ? "card-dp-selected": ""}`}
                        style={{borderRadius: "10px"}}
                    >
                        <div className="head_product_list">
                            <div>{name}</div>
                        </div>
                        <div className="sc_head_product_list">
                            {nf?.format(price) + "฿ / เดือน"}
                        </div>
                        <div className="body_product_list">
                            {service?.length > 0 && service?.map((item: any, index: any) => {
                                return (
                                    <div>
                                        <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{item?.name}</div>
                                        {service?.length -1 !== index && <Divider style={{margin: "10px 0"}}/>}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="footer_product_list">
                            <Button
                                className="btn_sl_noauth"
                                type="primary"
                                size="large"
                                style={{
                                    width: "100%",
                                    color: "#fff"
                                }}
                                // onClick={() => replace(`/products/product-config/${id}`)}
                                onClick={() => push(`/products/product-config/${id}`)}
                            >
                                ซื้อสินค้า
                            </Button>
                        </div>
                    </Card>
                </AntdList.Item>
            </AntdList.Item>
        )
    }

    const renderLoc = (item: any) => {
        const {
            id,
            name,
            shortname,
            flagURL,
        }: any = item;

        return (
            <AntdList.Item 
                key={id} 
                onClick={() => setLC({
                    id: id,
                    item: item
                })}
            >
                <AntdList.Item>
                    <Card
                        bordered={true}
                        // style={{cursor: "pointer"}}
                        className={`card-lc ${selectLC?.id == id ? "card-lc-selected": ""}`}
                    >
                        <div>
                            <Row gutter={6}>
                                <Col className="gutter-row" span={6}>
                                    <img src={flagURL} style={{width: "50px", height: "35px", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}/>
                                </Col>
                                <Col className="gutter-row" span={18}>
                                    <div>
                                        <div style={{fontWeight: 600, fontSize: "1rem"}}>{name}</div>
                                        <div style={{color: "#b3b3b3"}}>{shortname}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </AntdList.Item>
            </AntdList.Item>
        )
    }

    const faildNoti = () => {
        notification['error']({
          message: "Faild",
          description: t("ดำเนินการล้มเหลว กรุณาลองใหม่อีกครั้ง"),
        });
    };

    function onLoading(){
        if(dtProduct?.length > 0 && dtLocal?.length){
            setLoading(false);
        }
    }

    function Deploy(){
        if(selectSV && selectLC){
            axiosInstance.post(`${SOURCE_URL}/api/v1/transaction/create`,
                {
                    select_product: selectSV?.id,
                    product_obj: {
                        id: selectSV?.item?.id,
                        name: selectSV?.item?.name,
                        name_en: selectSV?.item?.name_en,
                    },
                    payment_method: "2",
                    paid_status: "3",
                    server_location_obj: {
                        id: selectLC?.id,
                        name_th: selectLC?.item?.name,
                        name_en: selectLC?.item?.name_en,
                        logo_url: selectLC?.item?.flagURL,
                    },
                    user_profile: {
                        id: user?.user_profile?.id,
                        first_name: user?.user_profile?.first_name,
                        last_name: user?.user_profile?.last_name,
                    },
                    user: {
                        id: user?.id,
                        username: user?.name,
                        email: user?.name
                    },
                    create_by: {
                        id: user?.id,
                        user_name: user?.name
                    },
                },
                { headers: { Authorization: `Bearer ${TOKEN}` } }
                )
            .then((response) => {
                let res: any = response?.data;
                if(res?.code == "200"){
                    localStorage.setItem("AFTERPAY","1");
                    push(`/products`);
                }else if(res?.code == "400"){
                    faildNoti();
                }
            })
            .catch(error => {

            });
        }else{

        }
    }
    
    return (
        <div className="layout_page">
            <List
                title={<div className="list_title_custom">{t("Products")}</div>}
                headerProps={{extra:<></>}}
                breadcrumb={false}
            >
                <div className="nav_breadcrumbs_custom" style={{display: "flex", marginBottom: "2rem"}}>
                    <div style={{cursor: "pointer", marginRight: "5px"}} onClick={() => push("/products")}>{"Products"}</div>
                    <div style={{marginRight: "5px"}}>{"/"}</div>
                    <div style={{color: "#EE642A", cursor: "pointer"}} onClick={() => push("/products/list")}>{"Add Products"}</div>
                </div>
                {contextHolder}
                <Spin spinning={isLoading}>
                    <div style={{backgroundColor: "#fff", padding: "30px 40px", borderRadius: "10px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
                        <div style={{margin: "1rem 0", display: "flex"}}>
                            <div 
                                style={{
                                    backgroundColor: "#F48515",
                                    padding: "10px 20px",
                                    borderRadius: "50px",
                                    color: "#fff",
                                    fontSize: "1.1rem",
                                    fontWeight: 700
                                }}
                            >
                                1
                            </div>
                            <div
                                style={{
                                    marginTop: "0.6rem",
                                    marginLeft: "1rem",
                                    color: "#F48515",
                                    fontSize: "1.3rem", 
                                    fontWeight: 700,
                                }}
                            >
                                เลือกแพ็คเกจ
                            </div>
                        </div>

                        {/* <div style={{marginBottom: "3rem"}}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Segmented: {
                                            itemSelectedBg: "#F48515",
                                        },
                                    },
                                }}
                            >
                                <Segmented 
                                    options={[
                                        {
                                            label: (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px"}}>
                                                <img className="icon" style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/windows-icon.svg`} alt="ICON"/>
                                                <div className="txt" style={{marginLeft: "10px"}}>Windows</div>
                                            </div>
                                            ),
                                            value: 'windows',
                                        },
                                        {
                                            label: (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px"}}>
                                                <img className="icon" style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/linux-icon.svg`} alt="ICON"/>
                                                <div className="txt" style={{marginLeft: "10px"}}>Linux</div>
                                            </div>
                                            ),
                                            value: 'linux',
                                        },
                                    ]} 
                                    block
                                    size="large"
                                    className="segment_custom_2"
                                    style={{width: "300px"}}
                                    onChange={(e: any) => settabm(e)}
                                />
                            </ConfigProvider>
                        </div> */}

                        <div style={{marginTop: "4rem"}}>
                            <AntdList
                                    rowKey={"id"}
                                    grid={{ gutter: [12,32], xs: 1, sm: 2, md: 5, lg: 5, xl: 5, xxl: 5 }}
                                    dataSource={dtProduct}
                                    renderItem={renderItem}
                                    style={{ position: "relative" }}
                                    pagination={false}
                                />
                            {/* {tabm == "windows" ?
                                <AntdList
                                    rowKey={"id"}
                                    grid={{ gutter: [12,32], xs: 1, sm: 2, md: 5, lg: 5, xl: 5, xxl: 5 }}
                                    dataSource={dtProduct?.filter((f: any) => f?.type == "window")}
                                    renderItem={renderItem}
                                    style={{ position: "relative" }}
                                    pagination={false}
                                />
                            :tabm == "linux" &&
                                <AntdList
                                    rowKey={"id"}
                                    grid={{ gutter: [12,32], xs: 1, sm: 2, md: 5, lg: 5, xl: 5, xxl: 5 }}
                                    dataSource={dtProduct?.filter((f: any) => f?.type == "linux")}
                                    renderItem={renderItem}
                                    style={{ position: "relative" }}
                                    pagination={false}
                                />
                            } */}
                        </div>

                        {/* <Divider />

                        <div style={{margin: "1rem 0", display: "flex"}}>
                            <div 
                                style={{
                                    backgroundColor: "#F48515",
                                    padding: "10px 20px",
                                    borderRadius: "50px",
                                    color: "#fff",
                                    fontSize: "1.1rem",
                                    fontWeight: 700
                                }}
                            >
                                2
                            </div>
                            <div
                                style={{
                                    marginTop: "0.6rem",
                                    marginLeft: "1rem",
                                    color: "#F48515",
                                    fontSize: "1.3rem", 
                                    fontWeight: 700
                                }}
                            >
                                เลือกที่ตั้ง Server
                            </div>
                        </div> */}

                        {/* <div>
                            <AntdList
                                rowKey={"id"}
                                grid={{ gutter: 12, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }}
                                dataSource={dtLocal}
                                renderItem={renderLoc}
                                style={{ position: "relative" }}
                                pagination={false}
                            />
                        </div>

                        <Divider /> */}

                        {/* <div 
                            className="footer" 
                            style={{
                                height: "120px",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                // position: "sticky",
                                // bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div style={{padding: "10px", width: "100%"}}>
                                <Row gutter={6}>
                                    <Col className="gutter-row" span={6}>
                                        <div style={{fontSize: "1.3rem"}}>
                                            <span>Price : </span>
                                            <span style={{color: "#FF9823", fontSize: "1.8rem", fontWeight: 700}}>{(selectSV ? nf.format(selectSV?.item?.price) : 0) + " ฿"}</span>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={14} />
                                    <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Button 
                                            size="large" 
                                            type="primary" 
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#F48515",
                                                boxShadow: "unset",
                                                fontWeight: 700
                                            }}
                                            disabled={selectSV && selectLC ? false: true}
                                            // onClick={() => ConfirmPayment(selectSV?.item?.name)}
                                        >
                                            ซื้อแพ็คเกจ
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div> */}
                    </div>
                </Spin>
            </List>
        </div>
    )
}