// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container-verify-bg {
    background-color: #fff;
    display: flex;
    justify-content: center;
    height: 100vh;
}

.layout-container-verify {
    width: 500px;
    height: 100%;
    background-color: #fff;
    padding: 2rem;
}

.txt_5vw {
    font-size: 28px;
}

.margin-right-icon {
    margin-right: 2rem;
}

.blog-warning{
    background-color: #EEEEEE;
    text-align: center;
    padding: 15px 15px 10px 15px;
    border-radius: 5px;
    margin-top: 1rem;
}

@media screen and (max-width: 600px) {
    .layout-container-verify {
        width: 100%;
    }

    .txt_5vw {
        font-size: 5vw;
    }

    .margin-right-icon {
        margin-right: 5vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/verify/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["#container-verify-bg {\r\n    background-color: #fff;\r\n    display: flex;\r\n    justify-content: center;\r\n    height: 100vh;\r\n}\r\n\r\n.layout-container-verify {\r\n    width: 500px;\r\n    height: 100%;\r\n    background-color: #fff;\r\n    padding: 2rem;\r\n}\r\n\r\n.txt_5vw {\r\n    font-size: 28px;\r\n}\r\n\r\n.margin-right-icon {\r\n    margin-right: 2rem;\r\n}\r\n\r\n.blog-warning{\r\n    background-color: #EEEEEE;\r\n    text-align: center;\r\n    padding: 15px 15px 10px 15px;\r\n    border-radius: 5px;\r\n    margin-top: 1rem;\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n    .layout-container-verify {\r\n        width: 100%;\r\n    }\r\n\r\n    .txt_5vw {\r\n        font-size: 5vw;\r\n    }\r\n\r\n    .margin-right-icon {\r\n        margin-right: 5vw;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
