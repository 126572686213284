// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel_logo {
    width: 65%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.panel_object {
    width: 35%;
    display: flex;
    justify-content: flex-end;
}

.btn_lgn_noauth {
    width: 100%;
    background-color: #F48515;
}

.btn_lgn_noauth:hover {
    background-color: #FF9D3B !important;
}

.col_flex {
    display: flex;
}

.col_center {
    justify-content: center;
    align-items: center;
}

.menu_nav_noauth {
    color: #939393;
    transition: 0.3s;
    cursor: pointer;
    text-transform: uppercase;
}

.menu_nav_noauth:hover {
    color: #FF9D3B;
}

.container_header_noauth {
    width: 1480px;
    display: flex;
    justify-content: center;
}

.container_footer_noauth {
    width: 1480px;
}

@media screen and (max-width: 1480px) {
    .container_footer_noauth {
        width: 95%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/unAuth/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".panel_logo {\r\n    width: 65%;\r\n    display: flex;\r\n    align-items: center;\r\n    padding-left: 10px;\r\n}\r\n\r\n.panel_object {\r\n    width: 35%;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.btn_lgn_noauth {\r\n    width: 100%;\r\n    background-color: #F48515;\r\n}\r\n\r\n.btn_lgn_noauth:hover {\r\n    background-color: #FF9D3B !important;\r\n}\r\n\r\n.col_flex {\r\n    display: flex;\r\n}\r\n\r\n.col_center {\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.menu_nav_noauth {\r\n    color: #939393;\r\n    transition: 0.3s;\r\n    cursor: pointer;\r\n    text-transform: uppercase;\r\n}\r\n\r\n.menu_nav_noauth:hover {\r\n    color: #FF9D3B;\r\n}\r\n\r\n.container_header_noauth {\r\n    width: 1480px;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.container_footer_noauth {\r\n    width: 1480px;\r\n}\r\n\r\n@media screen and (max-width: 1480px) {\r\n    .container_footer_noauth {\r\n        width: 95%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
