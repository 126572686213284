import { List } from "@refinedev/antd";
import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Col, Form, Input, Row, Space, Tabs, notification, List as AntdList, Card, Divider, Badge, Spin } from "antd";

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { useEffect, useState } from "react";
import { DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";

let countNoti: number = 0;
// let dtProduct: any = [];
let dtProduct_FULL: any = [];
export const ProductList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { TabPane } = Tabs;
    const { push } = useNavigation();

    const { data: user } = useGetIdentity<any>();
    // const VERIFY: any = localStorage.getItem("VERIFY");
    const AFTERPAY: any = localStorage.getItem("AFTERPAY");
    const USERID: any = localStorage.getItem("USERID");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);

    const [isLoading, setLoading] = useState<boolean>(true);
    const [selectSV, setSV] = useState<any>();
    const [dtProduct, setdtProduct] = useState([]);

    useEffect(() => {
        if(AFTERPAY == "1" && countNoti == 0){
            countNoti = countNoti + 1;
            successNoti();
            localStorage.removeItem("AFTERPAY");
        }
    }, [AFTERPAY])

    const { data: dataProduct, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/findAll2`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: USERID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data?.filter((item: any) => item?.paid_status == "1" || item?.paid_status == "6" );
                let newDT: any = [];
                for (let i = 0; i < dataLoad?.length; i++) {  
                    newDT.push({
                        id: dataLoad[i]?._id,
                        name: dataLoad[i]?.product_obj?.name,
                        is_active: dataLoad[i]?.is_active,
                        status: dataLoad[i]?.paid_status == "1" ? "SETUP" : dataLoad[i]?.paid_status == "6" && "READY"
                    });
                }

                if(newDT?.length > 0){ 
                    setdtProduct((pre: any) => newDT);
                    dtProduct_FULL = newDT;
                }else {
                    setdtProduct((pre: any) => []);
                    dtProduct_FULL = [];
                }

                setLoading(false);
            },
        },
    });

    const renderItem = (item: any) => {
        const {id, name, is_active, status}: any = item;

        return (
            <AntdList.Item 
                key={id} 
                onClick={() => 
                    {
                        setSV({id: id, item: item});
                        onslserv(id);
                        setLoading(true);
                    }
                }
            >
                <AntdList.Item className="antdlist_custom_bagde">
                    <Badge.Ribbon 
                        color={status == "SETUP" ? "#DADADA" :status == "READY" ? "#F48515" : "#DADADA"}
                        // text={status == "SETUP" ? "OFFLINE" : "ONLINE"} 
                        text={<span style={{color : status == "SETUP" ? "#8B8B8B" :status == "READY" ? "#fff" : "#8B8B8B"}}>{status == "SETUP" ? "OFFLINE" : "ONLINE"}</span>}
                        placement="start"
                    >
                        <Card
                            bordered={true}
                            style={{cursor: "pointer"}}
                            className={`card-pd ${selectSV?.id == id ? "card-pd-selected": ""}`}
                        >
                            <div className="item_server">
                                {/* <div style={{fontSize: "1rem", fontWeight: 600, textAlign: "center"}}>{name}</div> */}
                                <img className="icon_server" src={`${DOMAIN_URL}/images/svg/icon/icon_computer.svg`} alt="ICON"/>
                            </div>
                        </Card>
                    </Badge.Ribbon>
                </AntdList.Item>

                <div style={{fontSize: "1rem", fontWeight: 600, textAlign: "center"}}>{name}</div>
            </AntdList.Item>
        )
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("ดำเนินการเสร็จสิ้น ระบบกำลังเตรียมเซิฟเวอร์ให้ท่าน"),
            duration: 3
        });
    };

    function onSearch(label: any){
        setTimeout(() => {
            if(label !== undefined){
                let newDT: any = [];
                newDT = dtProduct_FULL?.filter((e:any) => e?.name.toUpperCase()?.includes(label?.toUpperCase()));
                setdtProduct((pre: any) => newDT);
            }else{
                setdtProduct((pre: any) => dtProduct_FULL);
            }

            setSV(undefined);
            setLoading(false);
        }, 300);
    }

    function onslserv(id: any){
        setTimeout(() => {
            push(`/products/overviews/${id}`);
        }, 300);
    }

    return (
        <div className="layout_product">
            <List
                title={<div className="list_title_custom">{t("Products")}</div>}
                headerProps={{extra:<></>}}
                breadcrumb={false}
            >
                <Row gutter={[6,24]} style={{marginTop: "1rem"}}>
                    <Col className="gutter-row" span={4} style={{display: "flex", alignItems: "center"}}>
                        <div style={{fontSize: "24px", fontWeight: 500, marginTop: "10px"}}>{t("My Product")}</div>
                    </Col>
                    <Col className="gutter-row" span={20} style={{display: "flex", justifyContent: "end"}}>
                        <Input
                            size="large"
                            style={{height: "50px" ,width: "300px", marginRight: "1.5rem"}} 
                            onChange={(e) => 
                                {
                                    setLoading(true);
                                    if(e.target.value.length > 0){ onSearch(e?.target?.value)}else{onSearch(undefined)}
                                }
                            }
                            placeholder={"Server name"}
                        />
                        <Form.Item name="" style={{marginBottom: 0}}>
                            <Button 
                                size="large" 
                                icon={<AddIcon/>}
                                className="btn_add_product"
                                style={{backgroundColor: "#F48515"}}
                                // disabled={VERIFY == "true" ? false : true}
                                // onClick={()=> create("products")}
                                onClick={()=> push("/products/list")}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Spin spinning={isLoading}>
                    <div 
                        className="layout" 
                        style={{
                            marginTop: "1rem",
                            border: "1px solid #dedede", 
                            height: "500px",
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "start",
                            padding: "2.5rem"
                        }}
                    >
                        <div style={{width: "100%"}}>
                            <AntdList
                                rowKey={"id"}
                                grid={{ gutter: 12, xs: 1, sm: 2, md: 3, lg: 5, xl: 5, xxl: 5 }}
                                dataSource={dtProduct?.length > 0 ? dtProduct : []}
                                renderItem={renderItem}
                                style={{ position: "relative" }}
                                pagination={false}
                            />
                        </div>
                    </div>
                </Spin>
            </List>
        </div>
    );
};
