// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_blackdrop_custom_admin {
    padding: 15px 2rem;
    background-color: #F0F0F0;
    border-radius: 5px;
}

.panel_list_custom_admin {
    margin-bottom: 1rem;
}

.label_card_blackdrop {
    margin-bottom: 10px;
}

.txt_card_blackdrop {
    color: #777777;
}

.custom_input_height input {
    padding-left: 2rem !important;
    height: 50px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,uBAAuB;AAC3B","sourcesContent":[".card_blackdrop_custom_admin {\r\n    padding: 15px 2rem;\r\n    background-color: #F0F0F0;\r\n    border-radius: 5px;\r\n}\r\n\r\n.panel_list_custom_admin {\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n.label_card_blackdrop {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.txt_card_blackdrop {\r\n    color: #777777;\r\n}\r\n\r\n.custom_input_height input {\r\n    padding-left: 2rem !important;\r\n    height: 50px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
