import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

export const Termsdt: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    
    return(
        <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{width: "980px", backgroundColor: "#fff", padding: "1rem 2rem"}}>
                <div style={{textAlign: "center", margin: "3rem 0", fontSize: "26px", fontWeight: "bold"}}>Terms and Conditions</div>
                <div style={{marginBottom: "1rem", fontSize: "18px"}}>
                    <span style={{fontWeight: 500}}>Agreement Terms and Conditions </span>
                    The service user acknowledges and agrees to comply Agreement Terms and conditions of <span style={{fontWeight: 500, color: "#FFA233"}}> nueamek.cloud </span> as follows:
                </div>
                <div style={{marginBottom: "1rem"}}>
                    1. Any action related to publishing both in the form of email and/or the website of the user, it is considered to be an action that is under the sole responsibility of the user. The service provider is not involved in any way.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    2. Information that the user provides to the service provider must be true and correct. If the user imports false information, the service provider will not action according to the service request.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    3. The user must submit government-issued documents to confirm and verify information, to prevent bad actors from using other people's names and information to open illegal services. Examples of documents used include a copy of the company certificate, in the case of entity, a copy of ID card in the case of personal. However, if the service is canceled, the service provider will destroy documents immediately.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    4. The  registered domain name cannot be changed. If the user wants a new name, the user must pay for new domain registration services.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    5. The service provider has no policy to refund service fees in any case, except for violation of conditions by the service provider.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    6. The service provider is not responsible for damages to website/application/server that occurs in every case such as a fault with the equipment, the users delete their own information, or from any other causes caused by the service provider or the user themselves.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    7. In case that the service provider receives a complaint, request, or finds any suspicious reasons, the service provider reserves the right to cancel or suspend service to those users temporarily to investigate and find the cause.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    8. The service provider has the right to cancel service immediately without any conditions in case that the user uses a fake credit card or stolen without permission from the card owner to purchase services.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    9. The service provider reserves the right to use or disclose the user's information when receiving a request from a government official due the service provider cooperates with the Royal Thai Police in the investigation website/application/server that contains messages or content that is inappropriate and contrary to public order. If anyone has seen or knows the whereabouts, please provide information so that we can take action to those website/application/server.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    10. Content and design of websites/applications/server created by the service provider, the service provider reserves the right to prohibit users from copying, duplicating or using it elsewhere without written permission.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    11. The service provider will contact the user via the email address ordered on the web page. If you want to change the administrator or change the email used for contact, please use the email address you ordered to notify the service provider. If unable to use the original email address, the service provider may request additional documents e.g. company certificate, receipt for verification. If there is no such information, the service provider cannot provide confidential information to the requester in all cases.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    12. For software/script/website implemented by others (not the service provider), the service provider reserves the right not to answer such questions about problems related to programming or usage of script/website. The service provider will provide answers to questions related to the web service provided only.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    13. For any files that users use on website/application/server/cloud backup such as Website Files, E-Mail Files, Database Files, etc., if a virus attack is found, it will be automatically deleted from the system without prior notice.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    14. Use of the service must be for lawful purposes only. and it must not be used to promote or support any action or activity that is illegal. Use of the service must be used for communication related to business, trade, education, research and development, dissemination of information or any news that does not violate any laws or regulations which is considered prohibited, such as violating the copyright of others, threatening or obscene information that is considered a trade secret, etc. In case that such action occurs, the service users who have done such things must pay compensation for various damages that occur to the service provider or all those who receive damages. The user accepts that if another person sues or is accused of committing an illegal act, or act in violation of another person resulting from the use of the service, the user will protect and save the service provider not to be liable to that other person in any case.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    15. The service provider does not allow the user to use the right to use, release, or resell it to other persons or legal entities.
                </div>
                <div style={{marginBottom: "3rem"}}>
                    16. The user must pay the service fee as specified in the purchase order. The service fee must be paid within 7 days from the due date. If payment is not made within the specified period, it will be considered that the user's account has not been renewed. Account not renewed service may be temporarily suspended. Suspension of this service does not relieve the user of the obligation to pay the service fee.
                </div>


                {/* ////////////////////////////////// */}


                <div style={{marginBottom: "1rem", fontSize: "18px"}}>
                    <span style={{fontWeight: 500}}>Types of usage that are not allowed </span>
                </div>
                <div style={{marginBottom: "1rem"}}>
                    1. Illegal website/application/server that is an attack create, disgrace, or destroy stability against the monarchy and religion. Attacking and creating chaos and disgrace to others.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    2. Website/Application/Server that is obscene or has an obscene tendency.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    3. Website/Application/Server that is about gambling
                </div>
                <div style={{marginBottom: "1rem"}}>
                    4. Website/Application/Server that provides BitTorrent, Tracker, Online Streaming, Pirate Software, Crack, Hack, Warez services, distributes viruses, internet worms, Torjans, or uses the services of this server to attack other servers such as Mail Bombs, Hack Attacks. , Spamming, Port Scanning or Port Probing, Download, MP3, Software, VDO & Voice Clips, Music Online whether legal or not.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    5. Sending emails that disqualify or threaten others
                </div>
                <div style={{marginBottom: "1rem"}}>
                    6. Website/Application/Server that sends mass e-mail, commercial mass emailing.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    7. Sending a chain message to generate many replies, and sending the same repeated messages to others. 
                </div>
                <div style={{marginBottom: "1rem"}}>
                    8. Website/Application/Server that sends “email spamˮ (sending large amounts of e-mail where the recipient has not consented to receive the message) by sending the email using a non-existent return address or randomly send such as tony@gmail.com, trump@gmail.com, lady@gmail.com
                </div>
                <div style={{marginBottom: "1rem"}}>
                    9. Using a program or system for sending email that results in a large number of emails being bounced or affecting the operation of the server, such as being shot by a Web Board or sending email that uses a non-existent return address. (If users are not sure that the email actually exists, try testing it from free e-mail first.) 
                </div>
                <div style={{marginBottom: "1rem"}}>
                    10. Website/Application/Server related to system hacking, virus propagation, or any distribution that is illegal and immorality to create chaos and suffering to others.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    11. Website/Application/Server that uses scripts or any programs that interfere with the server's ability to process and an abnormally large amount of memory. If the service provider detects it, it will be temporarily suspended until the issue is resolved by the user. If the user still disobey, the service provider will close that website/application/server immediately and is not responsible for any damages at all.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    12. Website/Application/Server that infringes on trademarks, copyrights, or intellectual property rights of other people or groups of other people in every country around the world.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    13. Website/Application/Server that threatens, cheats, reports false information, spread obscene things and drugs.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    14. Website/Application/Server that is an offense according to the Act Concerning Computer Crimes, B.E. 2007.
                </div>
                <div style={{marginBottom: "1rem"}}>
                    15. Website/Application/Server which brings disrepute to the service provider.
                </div>
                <div style={{marginBottom: "3rem"}}>
                    16. Other websites that the service provider considers inappropriate. 
                </div>

                <div style={{marginBottom: "3rem"}}>Note: The service provider reserves the right to change the terms of service above without prior notice.</div>
            </div>
        </div>
    )
}