import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core"
import { DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { Form, Input, Popconfirm, Popover, Select, Spin, Table, Tag } from "antd";
import { FormOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import "./styles.css";
import { List } from "@refinedev/antd";

// let nf: any = new Intl.NumberFormat('th-TH', {style: "currency",currency: "THB"});
export const BillinghisPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const [formSearch] = Form.useForm<any>();
    const { data: user } = useGetIdentity<any>();
    const USER: any = localStorage.getItem("USER");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const [isLoading, setLoading] = useState<boolean>(true);

    const [dtLoad, setdtLoad] = useState<any>([]);
    const [dataTB, setdataTB] = useState<any>([]);
    const [pageSize, setPageSize] = useState<any>(10);
    const [tk, settk] = useState<boolean>(true);

    const { push } = useNavigation();

    const { data: dataProduct, refetch } = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionAll`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: USER
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data?.filter((item: any) => (item?.paid_status !== "3")).reverse();
                // let dataLoad: any = data?.data?.reverse();
                setdtLoad((pre: any) => dataLoad);
                mergeData(dataLoad)
            },
        },
    });

    const columns:any = [
        {
            title: 'Invoice #',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
            width: 200,
            sorter: (a:any, b:any) => a?.invoice_id?.localeCompare(b?.invoice_id),
            render: (value:any, record:any) => {
                return (<span className="billing_id_table" onClick={() => push(`/cart/cart-detail/${record?.id}`)}>{record?.invoice_id}</span>)
            },
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            sorter: (a:any, b:any) => a?.product_obj?.name_en?.localeCompare(b?.product_obj?.name_en),
            render: (value:any, record:any) => {
                return (<span>{record?.product_obj?.name_en}</span>)
            },
        },
        {
            title: 'Hostname',
            dataIndex: 'hostname',
            key: 'hostname',
            width: 200,
            sorter: (a:any, b:any) => a?.hostname?.localeCompare(b?.hostname),
            render: (value:any, record:any) => {
                return (<span>{record?.hostname}</span>)
            },
        },
        {
            title: 'Invoice date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            width: 200,
            sorter: (a:any, b:any) => a?.invoice_date?.localeCompare(b?.invoice_date),
            render: (value:any, record:any) => {
                return (<span>{dayjs(record?.invoice_date).format("DD/MM/YYYY")}</span>)
            },
        },
        {
            title: 'Due date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: 200,
            sorter: (a:any, b:any) => a?.due_date?.localeCompare(b?.due_date),
            render: (value:any, record:any) => {
                return (<span>{dayjs(record?.due_date).format("DD/MM/YYYY")}</span>)
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: 200,
            sorter: (a:any, b:any) => a?.total - b?.total,
            render: (value:any, record:any) => {
                return (<span>{nfformat(record?.total)}</span>)
            },
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Status',
            dataIndex: 'is_confirm',
            key: 'is_confirm',
            width: 200,
            sorter: (a:any, b:any) => a?.status?.localeCompare(b?.status),
            render: (value:any, record:any) => {
                return (
                    record?.status == "2" ?
                    <Popover 
                        content={<div style={{width: "auto", maxWidth: "250px"}}>{record?.description}</div>}
                        title={false}
                    >
                        <span style={{color: "#F8A626", fontWeight: "500"}}>
                            {t("Rejected")}
                        </span>
                    </Popover>
                    :
                    <span style={{color: 
                        record?.status === "1" ? "#FF76FA" :
                        record?.status === "4" ? "#FF5252" :
                        record?.status === "5" ? "#0978F2" :
                        record?.status === "6" ? "#0EAD69" :
                        record?.status === "7" ? "#6D6D6D" : ""
                        ,
                        fontWeight: "500"
                    }}>
                        {record?.status == "1" ?
                            "Paid"
                        :record?.status == "4" ?
                            "Unpaid"
                        : record?.status == "5" ?
                            "Wait for Approve"
                        : record?.status == "6" ?
                            "Completed"
                        : record?.status == "7" &&
                            "Overdue"
                        }
                    </span>
                )
            },
        },
        {
            title: ' ',
            dataIndex: 'actions',
            key: 'actions',
            width: 50,
            align: "center",
            render: (value:any, record:any) => {
                return (<FormOutlined style={{cursor: "pointer", color: "#A0A0A0", fontSize: "20px"}} onClick={() => push(`/cart/cart-detail/${record?.id}`)}/>)
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    function onSearch(){
        setLoading(true);

        setTimeout(() => {
            let values: any = formSearch?.getFieldsValue();
            let data: any = dtLoad;
            let invoice_no: any = values?.invoice_no && values?.invoice_no !== "" ? values?.invoice_no : undefined;
            let status: any = values?.status && values?.status !== "" ? values?.status : undefined;
            
            let newDT: any = [];
            newDT = data?.filter((e:any) => 
                (invoice_no ? e?.invoice_no?.toUpperCase()?.includes(invoice_no?.toUpperCase()) : e?.invoice_no !== undefined)  && 
                (status ? e?.paid_status?.includes(status) : e?.paid_status !== undefined)
            );

            mergeData(newDT);
        }, 300);
    }

    function mergeData(data: any){
        let newData: any = [];
        for (let index = 0; index < data?.length; index++) {
            newData?.push({
                id: data[index]?._id,
                invoice_id: data[index]?.invoice_no,
                total: data[index]?.total_price,
                invoice_date: data[index]?.invoice_date,
                due_date: data[index]?.invoice_due_date,
                status: data[index]?.paid_status,
                product_obj: data[index]?.product_obj,
                description: data[index]?.paid_status == "2" ? "Reject Message: " + data[index]?.reject_reason : "",
                hostname: data[index]?.host_name
            })
        }

        settk(!tk)
        setdataTB((pre: any) => newData);
        setLoading(false);
    }

    return (
        <div className="layout_panel">
            <Spin size="large" spinning={isLoading}>
                <Form
                    layout="vertical"
                    form={formSearch}
                    style={{display: "flex", gap: "20px", margin: "1rem 0 2rem 0"}}
                >
                    <div style={{width: "250px"}}>
                        <Form.Item 
                            label=" "
                            name="invoice_no"
                            style={{marginBottom: 0}}
                            className="disable_txt"
                        >
                            <Input placeholder="Invoice No" size="large" onChange={onSearch} allowClear/>
                        </Form.Item>
                    </div>
                    <div style={{width: "250px"}}>
                        <Form.Item 
                            label=" "
                            name="status"
                            style={{marginBottom: 0}}
                            className="disable_txt"
                        >
                            {/* <Input placeholder="Status" size="large" onChange={onSearch} allowClear/> */}
                            <Select
                                placeholder="Select Status"
                                options={[
                                    {
                                        label: "Paid",
                                        value: "1",
                                    },
                                    {
                                        label: "Rejected",
                                        value: "2",
                                    },
                                    {
                                        label: "Unpaid",
                                        value: "4",
                                    },
                                    {
                                        label: "Wait for Approve",
                                        value: "5",
                                    },
                                    {
                                        label: "Completed",
                                        value: "6",
                                    },
                                    {
                                        label: "Overdue",
                                        value: "7",
                                    },
                                ]}
                                allowClear
                                size="large"
                                onChange={onSearch}
                            />
                        </Form.Item>
                    </div>
                </Form>
                <Table 
                    dataSource={dataTB ? dataTB : []} 
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '30', '50', '100'], 
                        showSizeChanger: true, 
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 900 }}
                />
            </Spin>
        </div>
    )
}