import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core"
import { API_URL, DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useRef, useState } from "react";
import { Col, Row, Spin, Image, Divider, Button, Tag, Modal, Input, Form, Card, DatePicker, InputNumber, Avatar, Tooltip } from "antd";
import { PHcustom } from "components/layout/pageheader";
import { axiosInstance } from "@refinedev/strapi-v4";
import { CheckOutlined, EyeOutlined, CloseOutlined, PrinterOutlined, WarningOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import "./styles.css";
import { ModalConfirm } from "components/confirm_md";
import { useReactToPrint } from "react-to-print";
import React from "react";
import { TransectionAdmin } from "./transection";

export const AdminVerifyPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TextArea } = Input;
    const { push } = useNavigation();
    const format = 'YYYY-MM-DD HH:mm';
    const [form] = Form.useForm<any>();
    const [formReject] = Form.useForm<any>();
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const { data: user } = useGetIdentity<any>();

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[4].split("?");
    let tokenSPLIT: any = pageSPLIT[1].split(/\W+/);

    let pmTOKEN: any = tokenSPLIT[1];
    let transTOKEN: any = tokenSPLIT[3];

    const [isLoading, setLoading] = useState<boolean>(true);
    const [dtPayment, setdtPayment] = useState<any>();
    const [dtTrans, setdtTrans] = useState<any>();
    const [dateSLP, setdateSLP] = useState<any>();

    const [mdSuccess, setmdSuccess] = useState<boolean>(false);
    const [mdSubmit, setmdSubmit] = useState<boolean>(false);
    const [mdReject, setmdReject] = useState<boolean>(false);
    const [mdTrans, setmdTrans] = useState<boolean>(false);
    const [amount, setamount] = useState<any>()

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "nxc_"+dtTrans?.invoice_no+"_invoice",
    });

    const { data: dataAPI, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/topup/getPaymentById`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                payment_id: pmTOKEN,
                trans_id: transTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                setdtPayment(dataLoad?.data_payment);
                setdtTrans(dataLoad?.data_trans);
                setdateSLP(dataLoad?.data_payment?.topup_timestamp);
                setamount(Number(dataLoad?.data_payment?.create_by?.amount_after_vat).toFixed(2))

                form?.setFieldsValue({
                    payment_timestamp: dataLoad?.data_payment?.create_date,
                    total_amount: dataLoad?.data_payment?.create_by?.amount_after_vat
                })
                setLoading(false);
            },
        },
    });

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    const changeslip_Date :any= (dateString: string) => {
        if(dateString !== ""){
            setdateSLP(dayjs(dateString).toISOString());
        }else if(dateString == ""){
            setdateSLP(undefined);
        }
    }

    function ApproveTrans(){
        let values = form?.getFieldsValue();

        let body: any = {
            paid_amount: String(values?.total_amount),
            confirm_topup_timestamp: dateSLP,
            trans_id: dtTrans?._id,
            status: "1",
            confirm_by: user?.user_profile?.id
        }

        axiosInstance.put(`${SOURCE_URL}/api/v1/transaction/confirmPay`, body, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((response) => {
            setLoading(true);

            setTimeout(() => {
                setmdSuccess(true);
            }, 500);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    function RejectTrans(values: any){
        let body: any = {
            trans_id: dtTrans?._id,
            status: "2",
            reject_reason: values?.reject_reason,
            reject_by: user?.user_profile?.id
        }

        axiosInstance.put(`${SOURCE_URL}/api/v1/transaction/confirmPay`, body, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((response) => {
            setLoading(true);

            setTimeout(() => {
                push(`/pn-admin`);
            }, 500);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }
    class ComponentToPrint extends React.Component {
        render() {
          return (
            <TransectionAdmin dataLoad={dtTrans} mode="print"/>
          );
        }
    }


    return (
        <div className="layout_page">
            <Spin spinning={isLoading}>
                <div style={{display: "none"}}><ComponentToPrint ref={componentRef} /></div>
                <PHcustom 
                    title="Verify Transaction"
                    is_subtitle={true}
                    subtitle={` #${dtTrans?.invoice_no}`}
                    pathURL={`pn-admin`}
                />

                <div style={{display: "flex", justifyContent: "start", backgroundColor: "#fff", width: "90%", marginTop: "2rem", borderRadius: "10px"}}>
                    <div style={{width: "90%", padding: "2rem 2rem"}}>
                        <Row gutter={42}>
                            <Col className="gutter-row" span={14}>
                                <div className="panel_list_custom_admin" style={{marginBottom: "3rem"}}>
                                    <div className="label_card_blackdrop">{t("ธนาคารที่โอนเงิน")}</div>
                                    <div className="card_blackdrop_custom_admin">
                                        <Row gutter={12}>
                                            <Col className="gutter-row" span={4}>
                                                <Avatar src={dtPayment?.bank_obj?.logo} size={66}/>
                                            </Col>
                                            <Col className="gutter-row" span={20}>
                                                <div>{dtPayment?.bank_obj?.bank_name}</div>
                                                <div>{dtPayment?.bank_obj?.acount_no}</div>
                                                <div>{t("บริษัท เหนือเมฆ โซลูชั่น จำกัด")}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="panel_list_custom_admin">
                                    <div className="label_card_blackdrop">{t("มูลค่าสินค้าก่อนภาษี")}</div>
                                    <div className="card_blackdrop_custom_admin">
                                        <span className="txt_card_blackdrop">{nfformat(dtPayment?.create_by?.amount_before_vat)}</span>
                                    </div>
                                </div>
                                <div className="panel_list_custom_admin">
                                    <div className="label_card_blackdrop">{t("มูลค่าภาษี (7%)")}</div>
                                    <div className="card_blackdrop_custom_admin">
                                        <span className="txt_card_blackdrop">{nfformat(dtPayment?.create_by?.amount_vat)}</span>
                                    </div>
                                </div>
                                <div className="panel_list_custom_admin">
                                    <div className="label_card_blackdrop">{t("มูลค่ารวมสุทธิที่ต้องชำระ")}</div>
                                    <div className="card_blackdrop_custom_admin">
                                        <span className="txt_card_blackdrop">{nfformat(dtPayment?.create_by?.amount_after_vat)}</span>
                                    </div>
                                </div>

                                <Divider />

                                <Form
                                    layout="vertical"
                                    form={form}
                                    onFinish={async (values: any) => {setmdSubmit(true)}}
                                >
                                    <Form.Item
                                        label={t("วันที่และเวลาที่ชำระเงิน*")}
                                        name="payment_timestamp"
                                        rules={[
                                            {
                                                required: true, message: 'Please select date.'
                                            },
                                        ]}
                                        style={{marginBottom: "15px"}}
                                        getValueProps={(value) => ({
                                            value: value ? dayjs(value) : "",
                                        })}
                                    >
                                        <DatePicker
                                            style={{width: "100%", height: "50px", paddingLeft: "2rem"}}
                                            size="large"
                                            showTime={{ format: 'HH:mm' }}
                                            format={format}
                                            onChange={(date, dateString) => changeslip_Date(dateString)}
                                            disabled={dtTrans?.status_code == "5" ? false: true}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t("จำนวนเงินที่ชำระ")}
                                        name="total_amount"
                                        className="custom_input_height"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter amount.'
                                            },
                                        ]}
                                        style={{marginBottom: "15px"}}
                                    >
                                        <InputNumber<number>
                                            controls={false}
                                            parser={(value) => value ? value?.replace(/\$\s?|(,*)/g, '') as unknown as number: 0}
                                            // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            formatter={(value: any) => Number.parseFloat(value ? value : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            placeholder={t("ยอดรวมสุทธิที่ต้องชำระ")}
                                            size="large"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }}
                                            style={{width: "100%"}}
                                            disabled={dtTrans?.status_code == "5" ? false: true}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col className="gutter-row" span={10}>
                                <Image src={dtPayment?.paid_slip?.url} style={{borderRadius: "15px"}}/>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div
                    className="float_menu_cart"
                    style={{
                        position: "fixed",
                        right: 0,
                        bottom: "50%",
                        width: "auto",
                        backgroundColor: "#fff",
                        padding: "0.5rem",
                        borderRadius: "10px 0 0 10px",
                        zIndex: 88,
                        boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px"
                    }}
                >
                    <div style={{marginBottom: dtTrans?.status_code == "5" ? "0.5rem" : "0"}}>
                        <Tooltip placement="left" title={"VIEW INVOICE"} color={"#C9C9C9"}>
                            <Button
                                type="primary"
                                style={{width: 70, height: 70, backgroundColor: "#C9C9C9"}}
                                icon={ <EyeOutlined style={{fontSize: "22px"}}/> }
                                onClick={() => setmdTrans(true)}
                            />
                        </Tooltip>
                    </div>

                    {dtTrans?.status_code == "5" && 
                    <>
                        <div style={{marginBottom: "0.5rem"}}>
                            <Tooltip placement="left" title={"REJECT"} color={"#ff4d4f"}>
                                <Button
                                    type="primary"
                                    style={{width: 70, height: 70, backgroundColor: "#ff4d4f"}}
                                    icon={ <CloseOutlined style={{fontSize: "22px", color: "#fff"}}/> }
                                    // onClick={() => push(`/products/list`)}
                                    onClick={() => setmdReject(true)}
                                />
                            </Tooltip>
                        </div>

                        <div>
                            <Tooltip placement="left" title={"APPROVE"} color={"#FF9823"}>
                                <Button
                                    type="primary" 
                                    style={{width: 70, height: 70, backgroundColor: "#ff8d00"}}
                                    icon={ <CheckOutlined style={{fontSize: "22px"}}/> }
                                    // onClick={() => form?.submit()}
                                    onClick={() => form?.submit()}
                                    className="btn_checkout_product"
                                >
                                    {/* Checkout */}
                                </Button>
                            </Tooltip>
                        </div>
                    </>
                    }
                </div>

                <ModalConfirm 
                    open={mdSuccess} 
                    setopen={setmdSuccess} 
                    type="success"
                    footerButton="1btn"
                    afterSubmit={(e: any) => {if(e == "submit"){push(`/pn-admin`)}}} 
                    title="Your Payment was approved"
                    description="We’ll send a confirmation to customer."
                />

                <ModalConfirm 
                    open={mdSubmit} 
                    setopen={setmdSubmit} 
                    type="error"
                    footerButton="2btn"
                    afterSubmit={(e: any) => {if(e == "submit"){ApproveTrans()}else if(e == "cancel"){}}} 
                    title="Approve this payment ?"
                    description={
                        <div>
                            <div>{"If you press confirm,"}</div>
                            <div>{"The information will be sent to the customer."}</div>
                        </div>
                    }
                />

                <Modal
                    open={mdReject}
                    closeIcon={false}
                    zIndex={999999999}
                    footer={<></>}
                    width={"650px"}
                >
                    <div className="layout_mdconfirm" style={{margin: "2.5rem 5px", padding: "0 2rem"}}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "2.5rem"}}>
                            <div className="cc_error"><WarningOutlined className="icon_error"/></div>
                        </div>
                        <div className="title_mdconfirm"><span className={`error`}>{t("Are you sure to reject this payment ?")}</span></div>
                        
                        <div className="md_txt" style={{marginTop: "1rem"}}>
                            <Form
                                layout="vertical"
                                form={formReject}
                                onFinish={async (values: any) => {
                                    RejectTrans(values);
                                }}
                            >
                                <Form.Item 
                                    label=" "
                                    name="reject_reason"
                                    style={{marginBottom: 0}}
                                    className="disable_txt"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter reject reason'
                                        },
                                    ]}
                                >
                                    <TextArea style={{height: "100px", maxHeight: "150px", borderRadius: 0}} placeholder="Enter reason for rejection*"/>       
                                </Form.Item>
                            </Form>
                        </div>

                        <div style={{marginTop: "3rem"}}>
                            <div style={{display: "flex", gap: "10px"}}>
                                <Button style={{width: "50%", height: "50px", letterSpacing: "1px"}} onClick={() => setmdReject(false)} size="large">{t("Cancel")}</Button>
                                <Button style={{width: "50%", height: "50px", letterSpacing: "1px"}} type="primary" className="btn_normal_color" onClick={() => formReject?.submit()} size="large">{t("Confirm")}</Button>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* <ModalConfirm 
                    open={mdReject} 
                    setopen={setmdReject} 
                    type="error"
                    footerButton="2btn"
                    afterSubmit={(e: any) => {if(e == "submit"){RejectTrans(formReject?.getFieldsValue(["reject_reason"]))}else if(e == "cancel"){formReject?.resetFields();}}} 
                    title="Sure to reject this payment ?"
                    propAllow={true}
                    prop={
                        <Form
                            layout="vertical"
                            form={formReject}
                            onFinish={async (values: any) => {}}
                        >
                            <Form.Item 
                                label=" "
                                name="reject_reason"
                                style={{marginBottom: 0}}
                                className="disable_txt"
                            >
                                <TextArea style={{height: "100px", maxHeight: "150px", borderRadius: 0}} placeholder="Enter reason for rejection*"/>       
                            </Form.Item>
                        </Form>
                    }
                /> */}

                <Modal
                    open={mdTrans}
                    closeIcon={false}
                    closable={false}
                    maskClosable={true}
                    zIndex={999999999}
                    footer={<></>}
                    width={"1080px"}
                    onCancel={() => setmdTrans(false)}
                >
                    <Row gutter={12} style={{margin: "2rem 0 3rem 0"}}>
                        <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", gap: "5px", alignContent: "center"}}>
                            <div>
                                <div style={{marginBottom: "1rem"}}>
                                    <div style={{fontSize: "26px", fontWeight: "bold", color: "#000000B2"}}>{"Invoice #" + dtTrans?.invoice_no}</div>
                                </div>
                                <Tag color={dtTrans?.status == "unpaid" ? "#FF5353": "#90E278"} style={{padding: "7px 50px"}}>
                                    <span style={{textTransform: "uppercase", fontSize: "16px"}}>{dtTrans?.status}</span>
                                </Tag>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "end", gap: "15px"}}>
                            <div><Button icon={<PrinterOutlined />} onClick={()=> handlePrint()} size="large">Print Invoice</Button></div>
                        </Col>
                    </Row>
                    <Card>
                        <TransectionAdmin dataLoad={dtTrans} mode="show"/>
                    </Card>
                </Modal>
            </Spin>
        </div>
    )
}
