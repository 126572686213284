import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core"
import { Button, Card, Col, ConfigProvider, Row, Spin, Tabs } from "antd"
import { useState } from "react";
import { RedoOutlined } from '@ant-design/icons';
import { SOURCE_URL, TOKEN_KEY } from "../../constants";
import { OverviewDashboard, OverviewDetails } from "./overviews";

export const ProductdetailPage: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { push } = useNavigation();
    const { data: fileUser } = useGetIdentity<any>();

    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[5];

    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dataLoad, setdataLoad] = useState<any>([])
    const [Tab, setTab] = useState<any>('detail');

    const { data: dataTarn, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionDetail`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: pageTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataLoad((pre: any) => dataLoad);
                setLoading(false);
            },
        },
    });
    
    return (
        <div className="layout_product_detail">
            <Spin spinning={isLoading} size="large">
                <Row gutter={[6,24]} style={{marginTop: "1rem"}}>
                    <Col className="gutter-row" span={18} style={{display: "flex", alignItems: "center"}}>
                        <div>
                            <div className="nav_breadcrumbs_custom" style={{display: "flex", marginBottom: "1rem"}}>
                                <div style={{cursor: "pointer", marginRight: "5px"}} onClick={() => push("/products")}>{"Products"}</div>
                                <div style={{marginRight: "5px"}}>{"/"}</div>
                                <div style={{cursor: "pointer", marginRight: "5px"}} onClick={() => push("/products")}>{"My Product"}</div>
                                <div style={{marginRight: "5px"}}>{"/"}</div>
                                <div style={{color: "#EE642A", cursor: "pointer"}}>{"Overviews"}</div>
                            </div>
                            <div className="list_title_custom" style={{fontWeight: "bold"}}>{t("Overviews")}</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                        {/* <Button 
                            size="large"
                            icon={<RedoOutlined />}
                        >
                            {t("Renew Package")}
                        </Button> */}
                    </Col>
                </Row>

                <div style={{paddingLeft: "1.5rem", marginTop: "1rem"}}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Tabs: {
                                    itemColor: "#49454FCC",
                                    // itemHoverColor: "#EE642A",
                                    itemSelectedColor: "#EE642A",
                                    inkBarColor: "#49454F",
                                },
                            },
                        }}
                    >
                        <Tabs 
                            defaultActiveKey="detail" 
                            items={[
                                {
                                    key: 'detail',
                                    label: 
                                        <div style={{width: "150px", textAlign: "center", fontSize: "20px", fontWeight: "500"}}>{t('Detail')}</div>,
                                },
                                {
                                    key: 'dashboard',
                                    label: 
                                        <div style={{width: "150px", textAlign: "center", fontSize: "20px", fontWeight: "500"}}>{t('Dashboard')}</div>,
                                },
                            ]}
                            onChange={(e) => setTab(e)}
                        />
                    </ConfigProvider>
                </div>
                <div style={{marginTop: "1.5rem"}}>
                    {
                        Tab == "detail" ?
                            <OverviewDetails dataLoad={dataLoad}/>
                        :
                            <OverviewDashboard dataLoad={dataLoad} />
                    }
                </div>
            </Spin>
        </div>
    )
}