import { Avatar, Badge, Button, Card, Checkbox, Form, Input, Layout, Modal, Segmented, Spin, Tag, notification } from "antd";
import { HeadernoAuth } from "components/unAuth/header";
import "./styles.css";
import { API_URL, DOMAIN_URL, TOKEN_KEY } from "../../constants";
import { useNavigation, useTranslate } from "@refinedev/core";
import { axiosInstance } from "@refinedev/strapi-v4";
import { useState } from "react";
import { SafetyOutlined, ArrowRightOutlined, RedoOutlined, CheckCircleFilled } from '@ant-design/icons';

export const ForgotpwdPage: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<any>();
    const { push } = useNavigation();
    
    const [Step, setStep] = useState<any>(0);
    const [mdopen, setmdopen] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[3].split("?");
    let pageTOKEN: any = pageSPLIT[1];

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("เปลี่ยนรหัสผ่านสำเร็จ! อีก 3วินาทีจะไปยังหน้า /login"),
            duration: 3,
            onClose() {
                if(pageTOKEN == undefined){
                    onclickSN("un-token");
                }else if(pageTOKEN !== undefined){
                    onclickSN("token");
                }
            },
        });
    };

    const warningNoti = () => {
        notification['warning']({
            message: "Warning",
            description: t("ส่งข้อมูลยืนยันไปยัง E-mail สำเร็จแล้ว กรุณานำรหัสมายืนยัน"),
            duration: 3
        });
    };

    const failNoti = (type: any) => {
        notification['error']({
            message: "Faild",
            description: type == "TM" ? t("รูปแบบของ E-mail ผิดพลาดกรุณากรอกข้อมูลใหม่")
                :type == "IP" ? t("กรุณาใส่ E-mail ที่ถูกต้องสำหรับการตั้งรหัสผ่าน")
                :type == "WM" ? t("ไม่พบ E-mail นี้ในระบบ กรุณาตรวจสอบใหม่อีกครั้ง")
                :type == "WC" && t("Verify Code ไม่ถูกต้องกรุณาตรวจสอบใหม่อีกครั้ง"),
            duration: 3,
        });
    };

    function onclickSN(mode: any){
        if(mode == "un-token"){
            push(`/login`);
        }else if(mode == "token"){
            push(`/login?${pageTOKEN}`)
        }
    }

    function sendMAIL(){
        let email: any = form?.getFieldValue(["email"]);
        let checkMAIL: any = email?.search("@")
        if((email?.length > 0 || email !== undefined) && checkMAIL !== -1){
            axiosInstance.post(`${API_URL}/api/user-profiles/forgetPass`,
                {
                    identifier: String(email)
                },
                { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }
                )
            .then((response) => {
                let res = response?.data;
                if(res?.result == "not found"){
                    failNoti("WM");
                }else if(res?.result == "success"){
                    warningNoti();
                    setStep(1);
                }
            })
            .catch(error => {

            });
        }else{
            if(email?.length > 0 && checkMAIL == -1){
                failNoti("TM");
            }
            
            if(email?.length == 0 || email == undefined){
                failNoti("IP");
            }
        }
    }

    return(
        <Layout className="layout-forgot-pwd">
            <HeadernoAuth sticky/>
            <div id="container-page-bg" style={{display: "flex", alignItems: "center", height: "92.4vh", justifyContent: "center"}}>
                <div className="layout_forgot_pwd">
                    <div style={{padding: "2rem 0"}}>
                        <Spin spinning={isLoading}>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={async (values: any) => {
                                    let email: any = form?.getFieldValue(["email"]);
                                    axiosInstance.post(`${API_URL}/api/user-profiles/forgetPassConfirm`,
                                        {
                                            identifier: String(email),
                                            code: values?.verify_code,
                                            password: values?.password2
                                        },
                                        { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }
                                        )
                                    .then((response) => {
                                        let res = response?.data;
                                        if(res?.status == 200){
                                            setLoading(true);
                                            setTimeout(() => {
                                                successNoti();
                                            },300)
                                        }else if(res?.status == 404){
                                            failNoti("WC");
                                        }
                                    })
                                    .catch(error => {
                        
                                    });
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <div style={{marginBottom: "2rem"}}>
                                    <div style={{textAlign: "center", fontSize: "26px", fontWeight: 500}}>
                                        <SafetyOutlined/>
                                        <span style={{marginLeft: "0.5rem"}}>Reset your password</span>
                                    </div>
                                    <div style={{textAlign: "center", opacity: "0.5"}}>Enter your email address to send the verification code that will be used to reset your password</div>
                                </div>

                                {Step == 0 &&
                                    <Form.Item
                                        name="email"
                                        label=" "
                                        className="disable_txt"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <Input type="email" size="large" placeholder="Email"/>
                                    </Form.Item>
                                }

                                {Step == 1 &&
                                    <div className="visible_for_step">
                                        <Form.Item
                                            name="verify_code"
                                            label=" "
                                            className="disable_txt"
                                            style={{ marginBottom: "10px" }}
                                            rules={[{ required: true, message: 'please input your verify code' }]}
                                        >
                                            <Input size="large" placeholder="Verify code"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="password1"
                                            label=" "
                                            className="disable_txt"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'please input your password',
                                                },
                                                ({ getFieldValue }) => ({
                                                    async validator(_, value) {
                                                        if((value?.length <= 8 && value?.length >= 1) || value?.length >= 16){
                                                            return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                        }else if(value?.length >= 8 || value?.length <= 16){
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                }),
                                            ]}
                                            hasFeedback
                                            style={{marginBottom: "10px"}}
                                        >
                                            <Input.Password
                                                type="password"
                                                placeholder="New Password"
                                                size="large"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="password2"
                                            label=" "
                                            className="disable_txt"
                                            dependencies={['password1']}
                                            hasFeedback
                                            rules={[{ required: true, message: 'please input your password' },
                                            ({ getFieldValue }) => ({
                                                async validator(_, value) {
                                                    if((value?.length <= 8 && value?.length >= 1) || value?.length >= 16){
                                                        return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                    }else if(value?.length >= 8 || value?.length <= 16){
                                                        if (!value || getFieldValue('password1') === value) {
                                                            return Promise.resolve();
                                                        }else{
                                                            return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                        }
                                                    }
                                                },
                                            }),
                                            ]}
                                            style={{marginBottom: "10px"}}
                                        >
                                            <Input.Password
                                                type="password"
                                                placeholder="Confirm Password"
                                                size="large"
                                            />
                                        </Form.Item>
                                    </div>
                                }

                                <Button
                                    className="btn_reset_pwd"
                                    type="primary"
                                    size="large"
                                    block
                                    style={{boxShadow: "unset", backgroundColor: "#FF9823"}}
                                    icon={Step == 0 ? <ArrowRightOutlined />:Step == 1 && <RedoOutlined />}
                                    onClick={() => {Step == 0 ? sendMAIL(): Step == 1 && form?.submit()}}
                                >
                                    {Step == 0 ? t("Continue") : Step == 1 && t("Reset Password")}
                                </Button>

                                <div style={{color: "#838383", marginTop: "2rem", textAlign: "center"}}>
                                    <span style={{marginRight: "1rem"}}>Already have an account? </span>
                                    <span 
                                        style={{
                                            color: "#FF9823",
                                            cursor: "pointer"
                                        }} 
                                        onClick={() => {
                                            pageTOKEN == undefined ? 
                                                onclickSN("un-token")
                                            : pageTOKEN !== undefined && 
                                                onclickSN("token")
                                        }}
                                    >
                                        Login
                                    </span>
                                </div>
                            </Form>
                        </Spin>
                    </div>
                </div>
            </div>
        </Layout>
    )
}