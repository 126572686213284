import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

export const Policydt: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    
    return(
        <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{width: "980px", backgroundColor: "#fff", padding: "1rem 2rem"}}>
                <div style={{textAlign: "center", margin: "3rem 0", fontSize: "26px", fontWeight: "bold"}}>Privacy Policy for Customer</div>
                <div style={{marginBottom: "1rem", fontSize: "18px"}}>
                    <span style={{fontWeight: 500}}>Nueamek Solution Co., Ltd. </span>
                    recognizes the importance of the protection of your personal data. This Privacy Policy explains our practices regarding the collection, use or disclosure of personal data including other rights of the Data Subjects in accordance with the Personal Data Protection Laws.
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Collection of Personal Data */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Collection of Personal Data</div>
                    <div>We will collect your personal data that receive directly from you as following:</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Your account registration</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Telephone</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>E-mail address</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Facebook Login</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Google Login</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Apple Login</span>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Types of Data Collected */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Types of Data Collected</div>
                    <div style={{paddingLeft: "1rem", marginBottom: "1rem"}}>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Personal data such as name, surname, age, date of birth, nationality, identification card, passport, etc.</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Contact information such as address, telephone number, e-mail address, etc.</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Account details such as username, password, transactions history, etc.</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Proof of identity such as copy of identification card, copy of passport, etc.</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Transaction and Financial information such as purchase history, credit card details, bank account, etc.</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Technical data such as IP address, Cookie ID, Activity Log, etc.</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Other such as photo, video, and other information that is considered personal data under the Personal Data Protection Laws.</span>
                        </div>
                    </div>
                    <div>We may collect, use or disclose your sensitive data that is specially categorized by law when we have obtained explicit consent from you or where necessary for us as permissible under law. We may collect, use or disclose your sensitive personal data as following:</div>
                    <div style={{paddingLeft: "1rem", marginBottom: "1rem"}}>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Racial</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Political opinions</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Cult</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Religious or philosophical beliefs</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Criminal records</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Health</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Disability </span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Trade union information </span>
                        </div>
                    </div>
                    <div>Any data which may affect the data subject in the same manner, as prescribed by the Personal Data Protection Committee.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Children */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Children</div>
                    <div>If you are under the age of 20 or having legal restrictions, we may collect use or disclose your personal data. We require your parents or guardian to be aware and provide consent to us or allowed by applicable laws. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Collection of Personal Data */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Storage of Data</div>
                    <div>We store your personal data as hard copy and soft copy.</div>
                    <div>We store your personal data by using the following systems:</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Our server in Thailand</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Our server outside of Thailand</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Third-party server service providers in Thailand</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>Third-party server service providers outside of Thailand</span>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Use of Data */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Use of Data</div>
                    <div>We use the collected data for various purposes:</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To create and manage accounts</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To provide products or services</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To improve products, services, or user experiences</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To share and manage information within organization</span>
                        </div>
                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To conduct marketing activities and promotions</span>
                        </div>                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To provide after-sales services</span>
                        </div>                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To gather user’s feedback</span>
                        </div>                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To process payments of products or services</span>
                        </div>                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To comply with our Terms and Conditions</span>
                        </div>                        <div>
                            <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                            <span>To comply with laws, rules, and regulatory authorities</span>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Disclosure of Personal Data */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Disclosure of Personal Data</div>
                    <div>We may disclose your personal data to the following parties in certain circumstances:</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Organization</div>
                            <div>We may disclose your personal data within our organization to provide and develop our products or services. We may combine information internally across the different products or services covered by this Privacy Policy to help us be more relevant and useful to you and others.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Service Providers</div>
                            <div>We may use service providers to help us provide our services such as payments, marketing and development of products or services. Please note that service providers have their own privacy policy.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Business Partners</div>
                            <div>In relation with our business partners, we may disclose certain personal data to them in order to coordinate and provide our products or services to you and provide necessary information about the availability of our products or services.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Data Retention</div>
                            <div>We will retain your personal data for as long as necessary during the period you are a customer or under relationship with us, or for as long as necessary in connection with the purposes set out in this Privacy Policy, unless law requires or permits a longer retention period. We will erase, destroy or anonymize your personal data when it is no longer necessary or when the period lapses.</div>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Data Subject Rights */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Data Subject Rights</div>
                    <div>Subject to the Personal Data Protection Laws thereof, you may exercise any of these rights in the following:</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Withdrawal of consent</div>
                            <div>If you have given consent to us to collect, use or disclose your personal data whether before or after the effective date of the Personal Data Protection Laws, you have the right to withdraw such consent at any time throughout the period your personal data available to us, unless it is restricted by laws or you are still under beneficial contract.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Data access</div>
                            <div>You have the right to access your personal data that is under our responsibility; to request us to make a copy of such data for you; and to request us to reveal as to how we obtain your personal data.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Data portability</div>
                            <div>You have the right to obtain your personal data if we organize such personal data in automatic machine-readable or usable format and can be processed or disclosed by automatic means; to request us to send or transfer the personal data in such format directly to other data controllers if doable by automatic means; and to request to obtain the personal data in such format sent or transferred by us directly to other data controller unless not technically feasible.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Objection</div>
                            <div>You have the right to object to collection, use or disclosure of your personal data at any time if such doing is conducted for legitimate interests of us, corporation or individual which is within your reasonable expectation; or for carrying out public tasks.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Data erasure or destruction</div>
                            <div>You have the right to request us to erase, destroy or anonymize your personal data if you believe that the collection, use or disclosure of your personal data is against relevant laws; or retention of the data by us is no longer necessary in connection with related purposes under this Privacy Policy; or when you request to withdraw your consent or to object to the processing as earlier described.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Suspension</div>
                            <div>You have the right to request us to suspend processing your personal data during the period where we examine your rectification or objection request; or when it is no longer necessary and we must erase or destroy your personal data pursuant to relevant laws but you instead request us to suspend the processing.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Rectification</div>
                            <div>You have the right to rectify your personal data to be updated, complete and not misleading.</div>
                        </div>
                        <div style={{marginBottom: "0.5rem"}}>
                            <div style={{fontWeight: 500}}>Complaint lodging </div>
                            <div>You have the right to complain to competent authorities pursuant to relevant laws if you believe that the collection, use or disclosure of your personal data is violating or not in compliance with relevant laws.</div>
                        </div>
                    </div>
                    <div>You can exercise these rights as the Data Subject by contacting our Data Protection Officer as mentioned below. We will notify the result of your request within 30 days upon receipt of such request. If we deny the request, we will inform you of the reason via SMS, email address, telephone, registered mail (if applicable).</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Advertising and Marketing */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Advertising and Marketing</div>
                    <div>We may send certain information or newsletter for the purpose of utilizing your preference via your email. If you no longer want to receive the communications from us, you can click the "unsubscribe" link in the email or contact us through our email.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Cookies */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Cookies</div>
                    <div>To enrich and perfect your experience, we use cookies or similar technologies to display personalized content, appropriate advertising and store your preferences on your computer. We use cookies to identify and track visitors, their usage of our website and their website access preferences. If you do not wish to have cookies placed on your computer you should set their browsers to refuse cookies before using our website.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Data Security */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Data Security</div>
                    <div>We endeavor to protect your personal data by establishing security measures in accordance with the principles of confidentiality, integrity, and availability to prevent loss, unauthorized or unlawful access, destruction, use, alteration, or disclosure including administrative safeguard, technical safeguard, physical safeguard and access controls.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* CooData Breach Notificationkies */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Data Breach Notification</div>
                    <div>We will notify the Office of the Personal Data Protection Committee without delay and, where feasible, within 72 hours after having become aware of it, unless such personal data breach is unlikely to result in a risk to the rights and freedoms of you. If the personal data breach is likely to result in a high risk to the rights and freedoms of you, we will also notify the personal data breach and the remedial measures to you without delay through our website, SMS, email address, telephone or registered mail (if applicable).</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Changes to this Privacy Policy */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Changes to this Privacy Policy</div>
                    <div>We may change this Privacy Policy from time to time. Any changes of this Privacy Policy, we encourage you to frequently check on our website.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Links to Other Sites */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Links to Other Sites</div>
                    <div>The purpose of this Privacy Policy is to offer products or services and use of our website. Any websites from other domains found on our site is subject to their privacy policy which is not related to us.</div>
                </div>

                <div style={{marginBottom: "1rem"}}> {/* Contact Information */}
                    <div style={{fontWeight: 500, textDecoration: "underline"}}>Contact Information</div>
                    <div style={{marginBottom: "1rem"}}>If you have any questions about this Privacy Policy or would like to exercise your rights, you can contact us by using the following details:</div>
                    <div style={{fontWeight: 500}}>Data Controller</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div>Nueamek Solution Co., Ltd.</div>
                        <div>172 Soi Lasalle 48, Sukhumvit 105 Road, Bang Na Tai, Bang Na, Bangkok, 10260</div>
                        <div>Email: @nueamek.com</div>
                        <div>Website: https://nueamek.com</div>
                        <div>Phone. 062-686-5444</div>
                    </div>
                </div>
            </div>
        </div>
    )
}