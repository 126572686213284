// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_cart_custom {
    background-color: #F7F7F7;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #dedede;
    border-radius: 5px 5px 0 0;
}

.body_cart_custom {
    /* padding: 1.5rem; */
}

.body_cart_custom_has_footer {
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.btn_checkout_product {
    background-color: #FF9823;
    animation: btn_checkout 1s;
    animation-iteration-count: 999;
}

.alert_tel_custom {
    color: #FF5353;
    position: absolute;
}

.alert_show {
    animation: fadeshow 0.5s;
    opacity:1;
}

.alert_hide {
    animation: fadehide 0.5s;
    opacity:0;
}

@keyframes fadeshow {
    from {
        opacity:0;
        transform: translateY(-10px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
}

@keyframes fadehide {
    from {
        opacity:1;
        transform: translateY(0);
    }
    to {
        opacity:0;
        transform: translateY(-10px);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/cart/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,oBAAoB;IACpB,gCAAgC;IAChC,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI;QACI,SAAS;QACT,4BAA4B;IAChC;IACA;QACI,SAAS;QACT,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,SAAS;QACT,wBAAwB;IAC5B;IACA;QACI,SAAS;QACT,4BAA4B;IAChC;AACJ","sourcesContent":[".header_cart_custom {\r\n    background-color: #F7F7F7;\r\n    padding: 1rem 1.5rem;\r\n    border-bottom: 1px solid #dedede;\r\n    border-radius: 5px 5px 0 0;\r\n}\r\n\r\n.body_cart_custom {\r\n    /* padding: 1.5rem; */\r\n}\r\n\r\n.body_cart_custom_has_footer {\r\n    padding: 1.5rem 1.5rem 0 1.5rem;\r\n}\r\n\r\n.btn_checkout_product {\r\n    background-color: #FF9823;\r\n    animation: btn_checkout 1s;\r\n    animation-iteration-count: 999;\r\n}\r\n\r\n.alert_tel_custom {\r\n    color: #FF5353;\r\n    position: absolute;\r\n}\r\n\r\n.alert_show {\r\n    animation: fadeshow 0.5s;\r\n    opacity:1;\r\n}\r\n\r\n.alert_hide {\r\n    animation: fadehide 0.5s;\r\n    opacity:0;\r\n}\r\n\r\n@keyframes fadeshow {\r\n    from {\r\n        opacity:0;\r\n        transform: translateY(-10px);\r\n    }\r\n    to {\r\n        opacity:1;\r\n        transform: translateY(0);\r\n    }\r\n}\r\n\r\n@keyframes fadehide {\r\n    from {\r\n        opacity:1;\r\n        transform: translateY(0);\r\n    }\r\n    to {\r\n        opacity:0;\r\n        transform: translateY(-10px);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
