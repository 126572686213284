import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Card, Col, Row } from "antd";
import dayjs from "dayjs";

interface IFC {
    dataLoad: any
}

export const OverviewDetails: React.FC<IFC> = ({
    dataLoad
})=> {
    const t = useTranslate();
    
    return (
        <Row gutter={42}>
            <Col className="gutter-row" span={12}>
                <Card style={{paddingLeft: "40px", paddingTop: "20px"}}>
                    <div style={{color: "#F48515", fontSize: "22px", fontWeight: "bold"}}>{"Package Details"}</div>
                    <Row gutter={[42, 24]} style={{margin: "2rem 0 3rem 0"}}>
                        <Col className="gutter-row" span={12} style={{paddingLeft: "30px"}}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Package")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.product_data ? dataLoad?.product_data?.name_en: '-'}</div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Recurring Amount")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.billing_cycle ? dataLoad?.billing_cycle?.label: '-'}</div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{paddingLeft: "30px"}}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Registration Date")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.regis_date ? dayjs(dataLoad?.regis_date).format("DD/MM/YYYY"): "-"}</div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Status")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: dataLoad?.status_code == "1" ? "#898989" :dataLoad?.status_code == "6" ? "#61BC81" : "#898989", fontWeight: "500"}}>
                                <span style={{marginRight: "10px"}}>•</span>
                                <span>{dataLoad?.status_code == "1" ? "OFFLINE" :dataLoad?.status_code == "6" && "ONLINE"}</span>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{paddingLeft: "30px"}}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Expire Date")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.expire_date ? dayjs(dataLoad?.expire_date).format("DD/MM/YYYY"): "-"}</div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Next Invoice")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.next_invoice ? dayjs(dataLoad?.next_invoice).format("DD/MM/YYYY"): "-"}</div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col className="gutter-row" span={12}>
                <Card style={{paddingLeft: "40px", paddingTop: "20px"}}>
                    <div style={{color: "#F48515", fontSize: "22px", fontWeight: "bold"}}>{"Server Details"}</div>
                    <Row gutter={[42, 24]} style={{margin: "2rem 0 3rem 0"}}>
                        <Col className="gutter-row" span={12} style={{paddingLeft: "30px"}}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Hostname")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.host_name ? dataLoad?.host_name: '-'}</div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("OS Template")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.os ? dataLoad?.os?.label: '-'}</div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{paddingLeft: "30px"}}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Uptime")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{'-'}</div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("CPU")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.product_data?.product_detail?.find((item: any) => item?.desc == "CPU")?.name_en}</div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{paddingLeft: "30px"}}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Disk Space")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.product_data?.product_detail?.find((item: any) => item?.desc == "STORAGE")?.name_en}</div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={{fontWeight: "bold", fontSize: "18px", textDecoration: "underline", color: "#464255"}}>{t("Memory")}</div>
                            <div style={{fontSize: "16px", marginTop: "10px", color: "#898989"}}>{dataLoad?.product_data?.product_detail?.find((item: any) => item?.desc == "RAM")?.name_en}</div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}