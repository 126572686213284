import { IResourceComponentsProps, useCustom, useTranslate } from "@refinedev/core"
import { API_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import { axiosInstance } from "@refinedev/strapi-v4";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import "./styles.css";

let currentPWD: any = undefined;
export const PasswordPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const bcrypt = require('bcryptjs');
    const USER: any = localStorage.getItem("USER");
    const USERID: any = localStorage.getItem("USERID");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const PATH: any = localStorage.getItem("PATH");

    const [form] = Form.useForm<any>();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [pwdMode, setpwdMode] = useState<boolean>(true);
    const [modal, contextHolder] = Modal.useModal();

    const { data: dataProduct, refetch } = useCustom<any>({
        url: `${API_URL}/api/user-profiles/findUserById`,
        method: "get",
        config: {
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: USERID
            }
        },
        queryOptions: {
            onSuccess(data) {
                let dataLoad: any = data?.data;

                currentPWD = dataLoad?.user?.password;
                setpwdMode(dataLoad?.is_set_pwd);
                setLoading(false);
            },
        },
    });

    const confirm = () => {
        modal.confirm({
            title: 'คุณต้องการ Update ข้อมูลโปรไฟล์',
            icon: <ExclamationCircleOutlined />,
            content: 'หากยืนยัน ระบบจะทำการ Update ข้อมูลโปรไฟล์',
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            okType: 'danger',
            onOk() {
                form?.submit();
            }
        });
    };

    return (
        <Spin spinning={isLoading}>
            <div className="layout_account_pwd">
                {contextHolder}
                <div>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={async (values: any) => {
                            axiosInstance.post(`${API_URL}/api/user-profiles/UpdateUser`,
                                {
                                    user_id: USER,
                                    profile_id: USERID,
                                    password: values?.password1,
                                },
                                { headers: { Authorization: `Bearer ${TOKEN}` } }
                            )
                                .then((response) => {
                                    localStorage.setItem("PASSWORD", "T");
                                    if(PATH){localStorage?.removeItem("PATH")}
                                    window?.location?.reload();
                                })
                                .catch(error => {

                                });
                        }}
                    >
                        <div style={{marginBottom: "2rem"}}>
                            {pwdMode == false ?
                            <div>
                                <Form.Item
                                    name="password1"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                        ({ getFieldValue }) => ({
                                            async validator(_, value) {
                                                if((value?.length < 8 && value?.length >= 1) || value?.length > 16){
                                                    return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                }else if(value?.length >= 8 || value?.length <= 16){
                                                    return Promise.resolve();
                                                }else if(value?.length == 0 || value == "" || value == undefined){
                                                    return Promise.reject(new Error('please input your password'));
                                                }
                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                    style={{marginBottom: 15}}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="New Password"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password2"
                                    label="Confirm Password"
                                    dependencies={['password1']}
                                    hasFeedback
                                    rules={[{ required: true, message: '' },
                                    ({ getFieldValue }) => ({
                                        async validator(_, value) {
                                            if((value?.length < 8 && value?.length >= 1) || value?.length > 16){
                                                return Promise.reject(new Error('Password must be 8-16 characters only '));
                                            }else if(value?.length >= 8 || value?.length <= 16){
                                                if (!value || getFieldValue('password1') === value) {
                                                    return Promise.resolve();
                                                }else{
                                                    return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                }
                                            }else if(value?.length == 0 || value == "" || value == undefined){
                                                return Promise.reject(new Error('please input your password'));
                                            }
                                        },
                                    }),
                                    ]}
                                    style={{marginBottom: 15}}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="Confirm Password"
                                    />
                                </Form.Item>
                            </div>
                            :
                            <div>
                                <Form.Item
                                    name="current_password"
                                    label="Current Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                        ({ getFieldValue }) => ({
                                            async validator(_, value) {
                                                if((value?.length < 8 && value?.length >= 1) || value?.length > 16){
                                                    return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                }else if(value?.length >= 8 || value.length <= 16){
                                                    let result = await bcrypt.compare(value, currentPWD);
                                                    if (result) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject(new Error('Passwords do not match'));
                                                    }
                                                }else if(value?.length < 0 || value == "" || value == undefined){
                                                    return Promise.reject(new Error('please input your password'));
                                                }
                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                    style={{marginBottom: 15}}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="Current Password"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password1"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                        ({ getFieldValue }) => ({
                                            async validator(_, value) {
                                                if((value?.length < 8 && value?.length >= 1) || value?.length > 16){
                                                    return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                }else if(value?.length >= 8 || value?.length <= 16){
                                                    // return Promise.resolve();
                                                    let result = await bcrypt.compare(value, currentPWD);
                                                    if (!result) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject(new Error('New password is a same current password!'));
                                                    }
                                                }else if(value?.length == 0 || value == "" || value == undefined){
                                                    return Promise.reject(new Error('please input your password'));
                                                }
                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                    style={{marginBottom: 15}}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="New Password"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password2"
                                    label="Confirm Password"
                                    dependencies={['password1']}
                                    hasFeedback
                                    rules={[{ required: true, message: '' },
                                    ({ getFieldValue }) => ({
                                        async validator(_, value) {
                                            if((value?.length < 8 && value?.length >= 1) || value?.length > 16){
                                                return Promise.reject(new Error('Password must be 8-16 characters only '));
                                            }else if(value?.length >= 8 || value?.length <= 16){
                                                if (!value || getFieldValue('password1') === value) {
                                                    let result = await bcrypt.compare(value, currentPWD);
                                                    if (!result) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject(new Error('New password is a same current password!'));
                                                    }
                                                }else{
                                                    return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                }
                                            }else if(value?.length == 0 || value == "" || value == undefined){
                                                return Promise.reject(new Error('please input your password'));
                                            }
                                        },
                                    }),
                                    ]}
                                    style={{marginBottom: 15}}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="Confirm Password"
                                    />
                                </Form.Item>
                            </div>
                            }
                        </div>
                            
                        <Button
                            type="primary"
                            size="large"
                            // htmlType="submit"
                            block
                            onClick={confirm}
                            style={{backgroundColor: "#ff8d00"}}
                        >
                            {pwdMode == false ? t("Save Password") : t("Change Password")}
                        </Button>
                    </Form>
                </div>
            </div>
        </Spin>
    )
}