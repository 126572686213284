import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select, Spin, Tooltip, message } from "antd";
import type { CheckboxProps, RadioChangeEvent } from 'antd';
import { useState } from "react";
import { DeleteFilled, ShoppingCartOutlined, CheckOutlined, ShopOutlined} from '@ant-design/icons';
import { API_URL, DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { CustomPhoneInput } from "components/phoneInput";
import phoneList from './world.json.custom/list.json'
import { ModalTerms } from "components/term_md";
import { PHcustom } from "components/layout/pageheader";
import { ModalConfirm } from "components/confirm_md";
import "./styles.css";

// let nf: any = new Intl.NumberFormat('th-TH', {style: "currency",currency: "THB"});
export const CartPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const [form] = Form.useForm<any>();
    const { data: user } = useGetIdentity<any>();
    const { TextArea } = Input;
    const { push } = useNavigation();
    const [messageApi, contextHolder] = message.useMessage();

    const USER: any = localStorage.getItem("USER");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);

    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[4];

    const [isLoading, setLoading] = useState<boolean>(true);
    const [typeAdds, settypeAdds] = useState<any>("new");
    const [branchType, setbranchType] = useState<any>("Other");
    const [branchID, setbranchID] = useState<any>();
    const [accountType, setaccountType] = useState<any>("Personal");
    const [tk, settk] = useState<boolean>(true);
    const [dataPD, setdataPD] = useState<any>();

    const [mdConf, setmdConf] = useState(false);
    const [mdDel, setmdDel] = useState(false);
    const [borderAlert, setborderAlert] = useState<boolean>(false);

    /////////////////////////////////////

    const [itemRemove, setitemRemove] = useState<any>();
    const [userPhone, setuserPhone] = useState<any>({
        value: "TH",
        label: `${"TH"}  +${"66"}`,
        short: "TH",
        tel_no: undefined,
    });
    const [dtAfterSubmit, setdtAfterSubmit] = useState<any>();

    const [defaultAdds, setdefaultAdds] = useState<any>({
        address: undefined,
        city: undefined,
        country: undefined,
        sub_distict: undefined,
        distict: undefined,
        postal: undefined,
        account_type: undefined,
        short_tel: undefined,
        tel: undefined,
        dial_code: undefined,
        tax_id: undefined,
        branch_type: undefined,
        branch_id: undefined,
        first_name: undefined,
        last_name: undefined,
        org_address: undefined,
        organization_name: undefined,
        org_last_name: undefined,
        org_first_name: undefined,
        org_province: undefined,
        org_amphure: undefined,
        org_tambon: undefined,
        org_postcode: undefined,
        org_country: undefined,
        org_tel: undefined,
        org_short_tel: undefined,
    });

    const [newAdds, setnewAdds] = useState<any>({
        address: undefined,
        city: undefined,
        country: undefined,
        sub_distict: undefined,
        distict: undefined,
        postal: undefined,
        account_type: undefined,
        organization_name: undefined,
        short_tel: undefined,
        tel: undefined,
        dial_code: undefined,
        tax_id: undefined,
        branch_type: undefined,
        branch_id: undefined,
        first_name: undefined,
        last_name: undefined
    });

    const { data: dataTarn, refetch: refetchTran} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionDetail`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: pageTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataPD((pre: any) => dataLoad);
                setLoading(false);
            },
        },
    });

    const { data: dataProfile, refetch: refetchProfile } = useCustom<any>({
        url: `${API_URL}/api/user-profiles/UserPermission`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: USER
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data[0]?.user_detail;
                let dialPersonal: any = phoneList?.filter((item: any) => item?.code == dataLoad?.short_tel)[0];
                let dialOrganiz: any = phoneList?.filter((item: any) => item?.code == dataLoad?.org_short_tel)[0];

                setdefaultAdds({
                    address: dataLoad?.address,
                    city: dataLoad?.province,
                    country: dataLoad?.country,
                    sub_distict: dataLoad?.tambon,
                    distict: dataLoad?.amphure,
                    postal: dataLoad?.postcode,
                    account_type: dataLoad?.account_type,
                    short_tel: dataLoad?.short_tel,
                    tel: dataLoad?.tel,
                    dial_code: dialPersonal?.dial_code,
                    tax_id: dataLoad?.tax_id,
                    branch_type: dataLoad?.branch_type ? dataLoad?.branch_type: "Other",
                    branch_id: dataLoad?.branch_id,
                    first_name: dataLoad?.first_name ? dataLoad?.first_name: user?.user_profile?.first_name,
                    last_name: dataLoad?.last_name ? dataLoad?.last_name: user?.user_profile?.last_name,

                    organization_name: dataLoad?.organization_name,
                    org_address: dataLoad?.org_address,
                    org_first_name: dataLoad?.org_first_name ? dataLoad?.org_first_name: user?.user_profile?.first_name,
                    org_last_name: dataLoad?.org_last_name ? dataLoad?.org_last_name: user?.user_profile?.last_name,
                    org_province: dataLoad?.org_province,
                    org_amphure: dataLoad?.org_amphure,
                    org_tambon: dataLoad?.org_tambon,
                    org_postcode: dataLoad?.org_postcode,
                    org_country: dataLoad?.org_country,
                    org_tel: dataLoad?.org_tel,
                    org_short_tel: dataLoad?.org_short_tel,
                    org_dial_code: dialOrganiz?.dial_code,
                })
            },
        },
    });

    const onChangetypeAdds = (e: RadioChangeEvent) => {
        let checked: any = e.target.value;
        setLoading(true);
        if(defaultAdds?.first_name == undefined){refetchProfile()}
        
        setTimeout(() => {
            if(checked == "default"){
                let newDT: any = newAdds;
                let spldial: any = userPhone?.label?.split("+");
                newDT.tel = userPhone?.tel_no;
                newDT.short_tel = userPhone?.short;
                newDT.dial_code = spldial[1];

                if(defaultAdds?.account_type == "Personal"){
                    setuserPhone({
                        value: defaultAdds?.short_tel ? defaultAdds?.short_tel : "TH",
                        label: `${defaultAdds?.short_tel ? defaultAdds?.short_tel : "TH"}  +${defaultAdds?.dial_code ? defaultAdds?.dial_code: "66"}`,
                        short: defaultAdds?.short_tel ? defaultAdds?.short_tel : "TH",
                        tel_no: defaultAdds?.tel ? defaultAdds?.tel : undefined,
                    });
                }else if(defaultAdds?.account_type == "Organization"){
                    setuserPhone({
                        value: defaultAdds?.org_short_tel ? defaultAdds?.org_short_tel : "TH",
                        label: `${defaultAdds?.org_short_tel ? defaultAdds?.org_short_tel : "TH"}  +${defaultAdds?.org_dial_code ? defaultAdds?.org_dial_code: "66"}`,
                        short: defaultAdds?.org_short_tel ? defaultAdds?.org_short_tel : "TH",
                        tel_no: defaultAdds?.org_tel ? defaultAdds?.org_tel : undefined,
                    });
                }
    
                setaccountType(defaultAdds?.account_type ? defaultAdds?.account_type : "Personal");
                setbranchType(defaultAdds?.branch_type ? defaultAdds?.branch_type: "Other");
                setbranchID(defaultAdds?.branch_id ? defaultAdds?.branch_id : undefined);
                if(defaultAdds?.account_type == "Personal"){setFields("personal", "default") }else if(defaultAdds?.account_type == "Organization"){setFields("organization", "default")}
            }else if(checked == "new"){
                setuserPhone({
                    value: newAdds?.short_tel ? newAdds?.short_tel : "TH",
                    label: `${newAdds?.short_tel ? newAdds?.short_tel : "TH"}  +${newAdds?.dial_code ? newAdds?.dial_code: "66"}`,
                    short: newAdds?.short_tel ? newAdds?.short_tel : "TH",
                    tel_no: newAdds?.tel ? newAdds?.tel : undefined,
                });
    
                setaccountType(newAdds?.account_type ? newAdds?.account_type: "Personal");
                setbranchType(newAdds?.branch_type ? newAdds?.branch_type: "Other");
                setFields("personal", "new")
            }

            setborderAlert(false);
            settypeAdds(checked);
            settk(!tk);
            setLoading(false);
        },300)
    };

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Please checked Terms of Service',
        });
    };

    function setFields(mode: "personal" | "organization", typeadds: "default" | "new"){
        if(typeadds == "default"){
            if(mode == "personal"){
                form?.setFieldsValue({
                    address: defaultAdds?.address,
                    city: defaultAdds?.city,
                    country: defaultAdds?.country,
                    sub_distict: defaultAdds?.sub_distict,
                    distict: defaultAdds?.distict,
                    postal: defaultAdds?.postal,
                    account_type: "Personal",
                    first_name: defaultAdds?.first_name,
                    last_name: defaultAdds?.last_name,
                })

            }else if(mode == "organization"){
                form?.setFieldsValue({
                    address: defaultAdds?.org_address,
                    city: defaultAdds?.org_province,
                    country: defaultAdds?.org_country,
                    sub_distict: defaultAdds?.org_tambon,
                    distict: defaultAdds?.org_amphure,
                    postal: defaultAdds?.org_postcode,
                    account_type: "Organization",
                    organization_name: defaultAdds?.organization_name,
                    tax_id: defaultAdds?.tax_id,
                    branch_type: defaultAdds?.branch_type ? defaultAdds?.branch_type : "Other",
                    branch_id: defaultAdds?.branch_id,
                    first_name: defaultAdds?.org_first_name,
                    last_name: defaultAdds?.org_last_name,
                });
            }
        }else if(typeadds == "new"){
            form?.setFieldsValue({
                address: newAdds?.address,
                city: newAdds?.city,
                country: newAdds?.country,
                sub_distict: newAdds?.sub_distict,
                distict: newAdds?.distict,
                postal: newAdds?.postal,
                account_type: newAdds?.account_type,
                organization_name: newAdds?.organization_name,
                tax_id: newAdds?.tax_id,
                branch_type: newAdds?.branch_type ? newAdds?.branch_type : "Other",
                branch_id: newAdds?.branch_id,
                first_name: newAdds?.first_name,
                last_name: newAdds?.last_name,
            });
        }
    }

    function removeitem(id: any) {
        axiosInstance.put(`${SOURCE_URL}/api/v1/transaction/update?id=${id}`,
            {
                paid_status: "0",
                update_by: {
                    user_id: user?.id,
                    user_name: user?.name
                }
            },
            { headers: { Authorization: `Bearer ${TOKEN}` } }
            )
        .then((response) => {
            let res = response?.data;
            window.location.href = `${DOMAIN_URL}/products`;
        })
        .catch(error => {

        });
    }
    
    function onChangeAdds(e: any, field: any){
        if(field == "account_type"){
            if(typeAdds == "new" && e){
                let newDT: any = newAdds;
                newDT[field] = e;
                if(e == "Personal"){ 
                    newDT.organization_name = undefined;
                    form?.resetFields(["organization_name"]);
                    form?.resetFields(["tax_id"]);
                    form?.resetFields(["branch_id"]);
                    form?.setFieldsValue({
                        branch_type: undefined
                    })
                }else{
                    form?.setFieldsValue({
                        branch_type: newDT?.branch_type ? newDT?.branch_type : newAdds?.branch_type ? newAdds?.branch_type : "Other"
                    })
                }
                settk(!tk);
                setnewAdds((pre: any) => newDT);
                setaccountType(e);
                setbranchType(newDT?.branch_type ? newDT?.branch_type : newAdds?.branch_type ? newAdds?.branch_type : "Other");
            }else if(typeAdds == "default" && e){
                settk(!tk);
                setaccountType(e);
                if(e == "Personal"){
                    setFields("personal", "default");
                    setuserPhone({
                        value: defaultAdds?.short_tel ? defaultAdds?.short_tel : "TH",
                        label: `${defaultAdds?.short_tel ? defaultAdds?.short_tel : "TH"}  +${defaultAdds?.dial_code ? defaultAdds?.dial_code: "66"}`,
                        short: defaultAdds?.short_tel ? defaultAdds?.short_tel : "TH",
                        tel_no: defaultAdds?.tel ? defaultAdds?.tel : undefined,
                    });
                    settk(!tk);
                }else if(e == "Organization"){
                    setFields("organization", "default");
                    setuserPhone({
                        value: defaultAdds?.org_short_tel ? defaultAdds?.org_short_tel : "TH",
                        label: `${defaultAdds?.org_short_tel ? defaultAdds?.org_short_tel : "TH"}  +${defaultAdds?.org_dial_code ? defaultAdds?.org_dial_code: "66"}`,
                        short: defaultAdds?.org_short_tel ? defaultAdds?.org_short_tel : "TH",
                        tel_no: defaultAdds?.org_tel ? defaultAdds?.org_tel : undefined,
                    });
                    settk(!tk);
                }
            }
        }else if(field == "branch_type"){
            if(typeAdds == "new" && e){
                let newDT: any = newAdds;
                newDT[field] = e;
                if(e == "Other"){ 
                    newDT.branch_id = undefined;
                    form?.resetFields(["branch_id"]);
                }else if(e == "Head Office"){
                    newDT.branch_id = undefined;
                    form?.resetFields(["branch_id"]);
                }
                settk(!tk);
                setnewAdds((pre: any) => newDT);
                setbranchType(e);
            }else if(typeAdds == "default" && e){
                setbranchType(e);
            }
        }else{
            if(typeAdds == "new" && e?.length > 0){
                let newDT: any = newAdds;
                newDT[field] = e;
                settk(!tk);
                setnewAdds((pre: any) => newDT);
            }else if(typeAdds == "new" && e == undefined){
                let newDT: any = newAdds;
                newDT[field] = undefined;
                settk(!tk);
                setnewAdds((pre: any) => newDT);
            }
        }
    }

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    function onchangeBranchID(e: any){
        let res: any = e?.target?.value?.length;
        let id: any;

        if(res < 5 && res > 0){
            let loop: any = 5 - res;
            let numberPlus: any = e?.target?.value;
            for (let index = 0; index < loop; index++) {
                numberPlus = "0" + numberPlus;
            }

            id = numberPlus;
        }else if(res == 5){
            id = e?.target?.value;
        }else if(res == 0){
            id = undefined;
        }

        onChangeAdds(id, "branch_id")
        setbranchID(id);
    }

    function onSubmit(values: any){
        let data: any = {
            first_name: values?.first_name,
            last_name: values?.last_name,
            address: values?.address,
            short_tel: userPhone?.short,
            tel: userPhone?.tel_no,
            province: values?.city,
            country: values?.country,
            tambon: values?.sub_distict,
            amphure: values?.distict,
            postcode: values?.postal,
            note: values?.note ? values?.note: null,
            organization: accountType == "Organization" ? values?.organization_name : null,
            account_type: String(accountType),
            tax_id: accountType == "Organization" ? values?.tax_id: null,
            branch_type: accountType == "Organization" ? String(branchType): null,
            branch_id: branchID,
        }

        setdtAfterSubmit((pre: any) => data);

        setTimeout(() => {
            setmdConf(true);
        },300)
    }

    function onFinish(values: any){
        let addressTel: any = userPhone?.tel_no[0];

        let body = {
            billing_address: {
                first_name: values?.first_name,
                last_name: values?.last_name,
                address: values?.address,
                short_tel: userPhone?.short,
                tel: addressTel == "0" ? userPhone?.tel_no : "0" + userPhone?.tel_no,
                province: values?.province,
                country: values?.country,
                tambon: values?.tambon,
                amphure: values?.amphure,
                postcode: values?.postcode,
            },
            trans_id: pageTOKEN,
            account_type: values?.account_type,
            organization: values?.organization,
            tax_id: values?.tax_id,
            branch_type: values?.branch_type,
            branch_id: values?.branch_id ? values?.branch_id: null,
            note: values?.note,
            paid_status: "4",
            invoice_date: new Date().toISOString(),
            update_by: {
                user_id: user?.id,
                user_name: user?.name
            }
        }

        axiosInstance.put(`${SOURCE_URL}/api/v1/transaction/update?id=${pageTOKEN}`,
            {body},
            { headers: { Authorization: `Bearer ${TOKEN}` } }
            )
        .then((response) => {
            let res = response?.data;
            window.location.href = `${DOMAIN_URL}/cart/cart-detail/${pageTOKEN}`;
        })
        .catch(error => {

        });
    }

    return (
        <Spin spinning={isLoading}>
            {contextHolder}
            <Form
                layout="vertical"
                form={form}
                onFinish={async (values: any) => {
                    if(borderAlert == false){
                        onSubmit(values);
                    }
                }}
            >
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "1280px", backgroundColor: "#fff", padding: "3rem 2rem", borderRadius: "10px", boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px"}}>
                        <Row gutter={42}>
                            <Col className="gutter-row" span={18} style={{display: "flex", alignItems: "center"}}>
                                <div style={{fontWeight: "500", fontSize: "20px"}}>Order Summary</div>
                            </Col>
                            <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end"}}>
                                <Button 
                                    danger 
                                    type="primary" 
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                        setmdDel(true);
                                        setitemRemove(pageTOKEN);
                                    }}
                                >
                                    Clear Order
                                </Button>
                            </Col>
                        </Row>

                        <Divider />

                        <div className="body_cart_custom">
                            <Row gutter={42}>
                                <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", paddingLeft: "2rem"}}>
                                    <div style={{textTransform: "uppercase", color: "#A9A9A9"}}>description</div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{textTransform: "uppercase", color: "#A9A9A9"}}>setup fee</div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{textTransform: "uppercase", color: "#A9A9A9"}}>price</div>
                                </Col>
                            </Row>

                            <Divider />

                            <div className="mockdt">
                                <Row gutter={42}>
                                    <Col className="gutter-row" span={12} style={{paddingLeft: "3rem"}}>
                                        <div style={{textTransform: "uppercase"}}>
                                            {`${dataPD?.product_data?.name_en} (${dataPD?.product_data?.type})-${dataPD?.expense_type}`}
                                            {/* VPS BUsiness SSD (Windows) - VPS-W1SS (nueamek.com) */}
                                        </div>
                                        <Row gutter={24} style={{marginTop: "1rem"}}>
                                            <Col className="gutter-row">
                                                <div 
                                                    style={{color: "#53A4FF", cursor: "pointer"}}
                                                    onClick={() => push(`/cart/edit-config/${pageTOKEN}`)}
                                                >
                                                    Edit Configuration
                                                </div>
                                            </Col>
                                            <Col className="gutter-row">
                                                <div 
                                                    style={{color: "#FF5353", cursor: "pointer"}}
                                                    onClick={() => {
                                                        setmdDel(true);
                                                        setitemRemove(pageTOKEN);
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                        <div style={{}}>-</div>
                                    </Col>
                                    <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                        <div style={{fontWeight: "500"}}>{nfformat(dataPD?.price)}</div>
                                    </Col>
                                </Row>
                            </div>

                            <Row gutter={42} style={{marginTop: "1rem"}}>
                                <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", paddingLeft: "3rem"}} />
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end"}}>
                                    <div style={{}}>Subtotal:</div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{}}>{nfformat(dataPD?.price)}</div>
                                </Col>
                            </Row>

                            <Divider style={{margin: "12px 0"}}/>

                            <Row gutter={42}>
                                <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", paddingLeft: "3rem"}} />
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end"}}>
                                    <div style={{}}>VAT @ 7%</div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{}}>{nfformat(dataPD?.vat)}</div>
                                </Col>
                            </Row>

                            <Divider style={{margin: "12px 0"}}/>

                            <Row gutter={42}>
                                <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", paddingLeft: "3rem"}} />
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end"}}>
                                    <div style={{}}>Total Recurring</div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{}}>{`${nfformat(dataPD?.total_price)} Monthly incl. Tax`}</div>
                                </Col>
                            </Row>
                            <Row gutter={42} style={{marginTop: '1rem'}}>
                                <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", paddingLeft: "3rem"}} />
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end"}}>
                                    <div style={{fontWeight: 500, marginTop: "10px"}}>Total Due Today</div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{fontWeight: "500", fontSize: "28px"}}>
                                        {/* {`฿${Intl.NumberFormat("es-ES")?.format(Number(dataPD?.total_price).toFixed(2))} THB`} */}
                                        {nfformat(dataPD?.total_price)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        
                        <Divider />

                        <Row gutter={42}>
                            <Col className="gutter-row" span={12} style={{display: "flex", alignItems: "center"}}>
                                <div style={{fontWeight: "500", fontSize: "20px"}}>BillingInfo</div>
                            </Col>
                            <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "end"}}>
                                {/* <Checkbox onChange={(e) => onCheckAddress(e)}>Use Profile Address</Checkbox> */}
                                <Radio.Group onChange={(e) => onChangetypeAdds(e)} defaultValue={"new"}>
                                    <Radio value={"new"}>New Address</Radio>
                                    <Radio value={"default"}>Default Address</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        <Divider />

                        <div className="body_cart_custom">
                            <Row gutter={[68, 8]}>
                                <Col className="gutter-row" span={12}> {/* Fields-Account Type */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Account Type <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="account_type"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select account type"
                                                    },
                                                ]}
                                            >
                                                <Select 
                                                    options={[
                                                        {
                                                            value: "Personal", label: "Personal"
                                                        },
                                                        {
                                                            value: "Organization", label: "Organization"
                                                        }
                                                    ]}
                                                    onChange={(e) => onChangeAdds(e, "account_type")}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                { accountType == "Organization" ?
                                <>
                                    <Col className="gutter-row" span={12}> {/* Fields-Organization Name */}
                                        <Row gutter={12}>
                                            <Col className="gutter-row" span={6}>
                                                <div style={{marginTop: "5px"}}>Company Name <span style={{color: "#FF5353"}}>*</span></div>
                                            </Col>
                                            <Col className="gutter-row" span={18}>
                                                <Form.Item 
                                                    label=" "
                                                    name="organization_name"
                                                    style={{marginBottom: 10}}
                                                    className="disable_txt"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter organization name"
                                                        },
                                                    ]}
                                                >
                                                    <Input 
                                                        onChange={(e: any) => {
                                                            if(e?.target?.value?.length > 0){
                                                                onChangeAdds(e?.target?.value, "organization_name")
                                                            }else if(e?.target?.value?.length == 0){
                                                                onChangeAdds(undefined, "organization_name")
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row" span={12}> {/* Fields-Tax ID */}
                                        <Row gutter={12}>
                                            <Col className="gutter-row" span={6}>
                                                <div style={{marginTop: "5px"}}>Tax ID <span style={{color: "#FF5353"}}>*</span></div>
                                            </Col>
                                            <Col className="gutter-row" span={18}>
                                                <Form.Item 
                                                    label=" "
                                                    name="tax_id"
                                                    style={{marginBottom: 10}}
                                                    className="disable_txt"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter tax id"
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (value.length == 13) {
                                                                    return Promise.resolve();
                                                                }
                    
                                                                if (value.length < 13) {
                                                                    return Promise.reject(new Error('Tax ID is 13 charactor only'));
                                                                }
                    
                                                                if (value.length == 0) {
                                                                    return Promise.reject(new Error('Please enter tax id'));
                                                                }
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        minLength={13}
                                                        maxLength={13}
                                                        onChange={(e: any) => {
                                                            if(e?.target?.value?.length > 0){
                                                                onChangeAdds(e?.target?.value, "tax_id")
                                                            }else if(e?.target?.value?.length == 0){
                                                                onChangeAdds(undefined, "tax_id")
                                                            }
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row" span={12}> {/* Fields-Branch */}
                                        <Row gutter={12} style={{marginBottom: 10}}>
                                            <Col className="gutter-row" span={6}>
                                                <div style={{marginTop: "5px"}}>Branch <span style={{color: "#FF5353"}}>*</span></div>
                                            </Col>
                                            <Col className="gutter-row" span={18}>
                                                <Row gutter={12}>
                                                    <Col className="gutter-row" span={branchType == "Other" ? 12: 24}>
                                                        <Form.Item 
                                                            label=" "
                                                            name="branch_type"
                                                            style={{marginBottom: 0}}
                                                            className="disable_txt"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please select branch type"
                                                                },
                                                            ]}
                                                        >
                                                            <Select 
                                                                options={[
                                                                    {
                                                                        value: "Other", label: "Other"
                                                                    },
                                                                    {
                                                                        value: "Head Office", label: "Head Office"
                                                                    }
                                                                ]}
                                                                defaultValue={"Other"}
                                                                onChange={(e) => {
                                                                    onChangeAdds(e, "branch_type");
                                                                    setbranchType(e);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    {branchType == "Other" &&
                                                        <Col className="gutter-row" span={12}>
                                                            <Form.Item 
                                                                label=" "
                                                                name="branch_id"
                                                                rules={[
                                                                    { 
                                                                        required: true, 
                                                                        message: "Please enter branch id" 
                                                                    }
                                                                ]}
                                                                style={{marginBottom: 0}}
                                                                className="disable_txt"
                                                            >
                                                                <Input 
                                                                    maxLength={5} 
                                                                    onChange={(e) => onchangeBranchID(e)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                                :accountType == "Personal" && <Col className="gutter-row" span={12} /> /* FREESPACE */
                                }
                                
                                {/* ===================================================== */}
                                <Col className="gutter-row" span={12}>{/* Fields-Address */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>First Name <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="first_name"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter first name"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "first_name")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "first_name")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={12}>{/* Fields-Address */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Last Name <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="last_name"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter last name"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "last_name")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "last_name")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={12}>{/* Fields-Address */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Address 1 <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="address"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter address"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "address")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "address")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={12}>{/* Fields-Sub Distict */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Sub Distict <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="sub_distict"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter sub distict"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "sub_distict")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "sub_distict")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                {/* ===================================================== */}
                                <Col className="gutter-row" span={12}>{/* Fields-Distict */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Distict <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="distict"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter distict"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "distict")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "distict")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={12}>{/* Fields-City */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>City <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="city"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter city"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "city")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "city")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                {/* ===================================================== */}
                                <Col className="gutter-row" span={12}> {/* Fields-Postal */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Postal <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="postal"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter postal"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "postal")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "postal")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={12}> {/* Fields-Country */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Country <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="country"
                                                style={{marginBottom: 10}}
                                                className="disable_txt"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter country"
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    onChange={(e: any) => {
                                                        if(e?.target?.value?.length > 0){
                                                            onChangeAdds(e?.target?.value, "country")
                                                        }else if(e?.target?.value?.length == 0){
                                                            onChangeAdds(undefined, "country")
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                {/* ===================================================== */}
                                <Col className="gutter-row" span={12}> {/* Fields-Tel */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Tel <span style={{color: "#FF5353"}}>*</span></div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                style={{marginBottom: 1}}
                                                className="disable_txt"
                                            >
                                                <CustomPhoneInput 
                                                    defaultLocal="TH" 
                                                    selectWidth="30%"
                                                    popupWidth={330}
                                                    userPhone={userPhone}
                                                    setuserPhone={setuserPhone}
                                                    allowborderAlert={true}
                                                    borderAlert={borderAlert}
                                                    setborderAlert={setborderAlert}
                                                />
                                            </Form.Item>
                                            {/* {borderAlert == true ?} */}
                                            <div className={`alert_tel_custom ${borderAlert == true ? `alert_show`: `alert_hide`}`}>{t("Please in put tel.")}</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={12}> {/* Fields-Country */}
                                    <Row gutter={12}>
                                        <Col className="gutter-row" span={6}>
                                            <div style={{marginTop: "5px"}}>Notes</div>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item 
                                                label=" "
                                                name="note"
                                                style={{marginBottom: 0}}
                                                className="disable_txt"
                                            >
                                                <TextArea style={{height: "30px", maxHeight: "150px"}} placeholder="Notes / Additional Information"/>       
                                            </Form.Item>      
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>                   
                        </div>

                        <div style={{marginBottom: "1rem"}}></div>
                    </div>
                </div>
            </Form>

            {/* /////////////////////////////////////////////////////////////////////////// */}

            <div
                className="float_menu_cart"
                style={{
                    position: "fixed",
                    right: 0,
                    bottom: "50%",
                    width: "auto",
                    backgroundColor: "#fff",
                    padding: "0.5rem",
                    borderRadius: "10px 0 0 10px",
                    zIndex: 8888,
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px"
                }}
            >
                <div style={{marginBottom: "0.5rem"}}>
                    <Tooltip placement="left" title={"CONTINUE SHOPPING"}>
                        <Button 
                            style={{width: 70, height: 70}}
                            icon={ <ShopOutlined style={{fontSize: "22px"}}/> }
                            onClick={() => push(`/products/list`)}
                        >
                            {/* Continue Shopping */}
                        </Button>
                    </Tooltip>
                </div>

                <div>
                    <Tooltip placement="left" title={"CHECK OUT"} color={"#FF9823"}>
                        <Button
                            style={{width: 70, height: 70, backgroundColor: "#ff8d00"}}
                            type="primary" 
                            icon={ <CheckOutlined style={{fontSize: "22px"}}/> }
                            // onClick={() => form?.submit()}
                            onClick={() =>{
                                if(userPhone?.tel_no == undefined || userPhone?.tel_no == ""){setborderAlert(true);}
                                form?.submit();
                            }}
                            className="btn_checkout_product"
                        >
                            {/* Checkout */}
                        </Button>
                    </Tooltip>
                </div>
            </div>

            {/* /////////////////////////////////////////////////////////////////////////// */}

            <ModalConfirm 
                open={mdDel} 
                setopen={setmdDel} 
                type="error" 
                afterSubmit={(e: any) => {if(e == "submit"){removeitem(itemRemove);}else if(e == "cancel"){setitemRemove(undefined);}}} 
                title="Are you sure you want to remove product from cart ?"
                description="If you confirm, This order will be removed."
            />
            
            <ModalConfirm 
                open={mdConf} 
                setopen={setmdConf} 
                type="error" 
                afterSubmit={(e: any) => {if(e == "submit"){onFinish(dtAfterSubmit)}}} 
                title="Do you want to save it ?"
                description={
                    <div>
                        <span>Please check information before saveing!</span>
                        <br />
                        <span>If you want to edit information click <span style={{fontWeight: "bold"}}>{t("cancel")}</span></span>
                    </div>
                }
                propAllow={true}
                prop={
                    <div>
                        <Row gutter={[12, 6]}>
                            {dtAfterSubmit?.account_type == "Organization" &&
                                <>
                                    <Col className="gutter-row" span={6}>
                                        <div className="md_txt">Company</div>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        <div className="md_txt">: {dtAfterSubmit?.organization}</div>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <div className="md_txt">Tax ID</div>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        <div className="md_txt">: {dtAfterSubmit?.tax_id}</div>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <div className="md_txt">Branch</div>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        <div className="md_txt">: {dtAfterSubmit?.branch_type == "Other" ? dtAfterSubmit?.branch_type + " (" + dtAfterSubmit?.branch_id + ")": dtAfterSubmit?.branch_type}</div>
                                    </Col>
                                </>
                            }
                            <Col className="gutter-row" span={6}>
                                <div className="md_txt">First Name</div>
                            </Col>
                            <Col className="gutter-row" span={18}>
                                <div className="md_txt">: {dtAfterSubmit?.first_name}</div>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <div className="md_txt">Last Name</div>
                            </Col>
                            <Col className="gutter-row" span={18}>
                                <div className="md_txt">: {dtAfterSubmit?.last_name}</div>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <div className="md_txt">Address</div>
                            </Col>
                            <Col className="gutter-row" span={18}>
                                <div className="md_txt">: {dtAfterSubmit?.address + ", " + dtAfterSubmit?.amphure + ", " + dtAfterSubmit?.tambon + ", " + dtAfterSubmit?.province + " " + dtAfterSubmit?.postcode + " " + dtAfterSubmit?.country}</div>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <div className="md_txt">Phone</div>
                            </Col>
                            <Col className="gutter-row" span={18}>
                                <div className="md_txt">: {dtAfterSubmit?.tel}</div>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <div className="md_txt">Note</div>
                            </Col>
                            <Col className="gutter-row" span={18}>
                                <div className="md_txt">: {dtAfterSubmit?.note ? dtAfterSubmit?.note : ""}</div>
                            </Col>
                        </Row>
                    </div>
                }
            />
        </Spin>
    )
}