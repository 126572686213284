import { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd"
import { useGetIdentity, useGetLocale, useSetLocale } from "@refinedev/core";
import { Avatar, MenuProps, theme, Layout as AntdLayout, Space, Dropdown, Button, Typography, } from "antd";
import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const { Text } = Typography;
const { useToken } = theme;

export const Footer: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky,}) => {
    const { token } = useToken();
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const { data: user } = useGetIdentity<any>();

    const currentLocale = locale();

    const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
        .sort()
        .map((lang: string) => ({
        key: lang,
        onClick: () => changeLanguage(lang),
        icon: (
            <span style={{ marginRight: 8 }}>
            <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
        ),
        label: lang === "en" ? "English" : "Thai",
    }));

    const footerStyles: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "100px",
    };

    if (sticky) {
        footerStyles.position = "sticky";
        footerStyles.bottom = 0;
        footerStyles.zIndex = 1;
    }


    return(
        <AntdLayout.Footer style={footerStyles}>

        </AntdLayout.Footer>
    )
}