import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation } from "@refinedev/core"
import { DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useRef, useState } from "react";
import { Button, Col, Divider, Row, Spin, Tag, Tooltip } from "antd";
import { WalletOutlined, PrinterOutlined, PhoneOutlined, PlusOutlined, MailOutlined, CreditCardOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import React from "react";
import ReactPDF from '@react-pdf/renderer';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Mail } from "@material-ui/icons";
import { Transaction } from "./transaction";

// let nf: any = new Intl.NumberFormat('th-TH', {style: "currency",currency: "THB"});
export const CartdetailPage: React.FC<IResourceComponentsProps> = () => {

    const { data: user } = useGetIdentity<any>();
    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[5];

    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const [isLoading, setLoading] = useState<boolean>(true);

    const [dataPD, setdataPD] = useState<any>();
    const { push } = useNavigation();

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "nxc_"+dataPD?.invoice_no+"_invoice",
    });

    const { data: dataTarn, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionDetail`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: pageTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataPD((pre: any) => dataLoad);
                setLoading(false);
            },
        },
    });

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    class ComponentToPrint extends React.Component {
        render() {
          return (
            <Transaction dataLoad={dataPD} mode="print"/>
          );
        }
    }

    return (
        <Spin spinning={isLoading}>
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "1280px", backgroundColor: "#fff", padding: "3rem 2rem", borderRadius: "10px", boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px"}}>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", gap: "5px", alignContent: "center"}}>
                            <div>
                                <div style={{marginBottom: "1rem"}}>
                                    <div style={{fontSize: "26px", fontWeight: 500}}>{"Invoice #" + dataPD?.invoice_no}</div>
                                    <div style={{cursor: "pointer", color: "#EE642A"}} onClick={() => push(`/billing`)}>{"Back to invoices"}</div>
                                </div>
                                <Tag color={dataPD?.status == "unpaid" && (dataPD?.status_code == "2" || dataPD?.status_code == "3" || dataPD?.status_code == "4" || dataPD?.status_code == "0") ? "#FF5353": "#90E278"} style={{padding: "7px 50px"}}>
                                    <span style={{textTransform: "uppercase", fontSize: "16px"}}>{dataPD?.status}</span>
                                </Tag>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "end", gap: "15px"}}>
                            {dataPD?.status == "unpaid" && dataPD?.status_code == "4" && <div><Button icon={<WalletOutlined />} type="primary" style={{backgroundColor: "#ff8d00"}} onClick={() => push(`/cart/payment/${pageTOKEN}`)} size="large">Pay Now!</Button></div>}
                            <div><Button icon={<PrinterOutlined />} onClick={()=> handlePrint()} size="large">Print Invoice</Button></div>
                        </Col>
                    </Row>

                    <div style={{border: "1px solid #dedede", borderRadius: "5px", backgroundColor: "#fff", marginTop: "3rem"}}>
                        <div style={{display: "none"}}><ComponentToPrint ref={componentRef} /></div>
                        {/* =============================================================================== */}
                        <Transaction dataLoad={dataPD} mode="show"/>
                    </div>
                </div>
            </div>
        </Spin>
    )
}
