import { Layout as AntdLayout, Button, Col, Row } from "antd";
import { DOMAIN_URL } from "../../constants";
import { HashLink as Link } from 'react-router-hash-link';
import "./styles.css";
import { useState } from "react";
import { ModalTerms } from "components/term_md";
import { ModalPolicy } from "components/policy_md";
import { useNavigation } from "@refinedev/core";

export const FooternoAuth: React.FC<any> = () => {
    const {push } = useNavigation();

    const [alertTerm, setalertTerm] = useState<boolean>(false);
    const [alertPolicy, setalertPolicy] = useState<boolean>(false);

    const footerStyles: React.CSSProperties = {
        backgroundColor: "#2F2F2F",
        padding: "40px 140px",
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const copyrightStyles: React.CSSProperties = {
        backgroundColor: "#5B5B5B",
        padding: "7px 0px",
        height: "auto",
        color: "#fff",
    };

    return(
        <div>
            <AntdLayout style={footerStyles}>
                <div className="container_footer_noauth">
                    <div
                        style={{display: "flex", justifyContent: "center"}}
                    >
                        <img style={{width: "200px", cursor: "pointer"}} src={`${DOMAIN_URL}/images/svg/logo_new_light.svg`} alt="NUEMEK" onClick={() => push(`/`)}/>
                    </div>
                    <Row gutter={30} style={{marginTop: "3rem", marginBottom: "1rem"}}>
                        <Col className="gutter-row" span={7}>
                            <div style={{lineHeight: 2, color: "#fff", fontSize: "16px"}}>
                                NUEAMEK SOLUTION CO., LTD. <br />
                                172 Lasalle 48, Sukhumvit 105 Rd. <br />
                                Bangna, Bangkok 10260 <br />
                                Thailand 
                            </div>
                        </Col>
                        <Col className="gutter-row" span={7} style={{display: "flex", justifyContent: "center", paddingLeft: "6rem"}}>
                            <div style={{lineHeight: 2, color: "#fff", fontSize: "16px"}}>
                                info@nueamek.co.th <br />
                                062-686-5444
                            </div>
                        </Col>
                        <Col className="gutter-row" span={5}>
                            <div style={{display: "flex", justifyContent: "flex-end", paddingRight: "3.5rem"}}>
                                <Link smooth={true} to={`${DOMAIN_URL}/#faq_panel`}>
                                    <div style={{lineHeight: 2, color: "#fff", fontSize: "16px"}}>
                                        FAQ  
                                    </div>
                                </Link>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <div style={{lineHeight: 2, color: "#fff", fontSize: "16px", cursor: "pointer"}} onClick={() => setalertTerm(true)}>
                                    Term & conditions  
                                </div>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={5}>
                            <div style={{display: "flex", justifyContent: "flex-end", paddingRight: "1.5rem"}}>
                                <Link smooth={true} to={`${DOMAIN_URL}/contact`}>
                                    <div style={{lineHeight: 2, color: "#fff", fontSize: "16px"}}>
                                        Contact  
                                    </div>
                                </Link>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <div style={{lineHeight: 2, color: "#fff", fontSize: "16px", cursor: "pointer"}} onClick={() => setalertPolicy(true)}>
                                    Privacy Policy  
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </AntdLayout>
            <AntdLayout style={copyrightStyles}>
                <div style={{textAlign: "center"}}>{"Copyright © 2024 NUEAMEK.CO.,LTD"}</div>
            </AntdLayout>

            <ModalTerms mdopen={alertTerm} setmdopen={setalertTerm}/>
            <ModalPolicy mdopen={alertPolicy} setmdopen={setalertPolicy}/>
        </div>
    )
}