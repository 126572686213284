import { Layout as AntdLayout, Button, Col, Row } from "antd";
import { DOMAIN_URL } from "../../constants";
import "./styles.css";
import { useNavigation } from "@refinedev/core";
// import { HashLink as Link } from 'react-router-hash-link';
import { HashLink as Link } from 'react-router-hash-link';

export const HeadernoAuth: React.FC<any> = ({ sticky,}) => {
    const {push } = useNavigation();
    
    const headerStyles: React.CSSProperties = {
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 50px",
        height: "70px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        width: "100%"
    };

    if (sticky) {
        headerStyles.position = "sticky";
        headerStyles.top = 0;
        headerStyles.zIndex = 1;
    }

    return(
        <AntdLayout.Header style={headerStyles}>
            <div className="container_header_noauth">
                <div className="panel_logo">
                    <img style={{width: "170px", cursor: "pointer"}} src={`${DOMAIN_URL}/images/svg/logo_new_dark.svg`} alt="NUEMEK" onClick={() => push(`/`)}/>
                </div>
                <div className="panel_object">
                    <Row gutter={12} style={{width: "100%"}}>
                        <Col className="gutter-row col_flex col_center" span={6}>
                            <div style={{paddingLeft: "2rem"}}>
                                <Link smooth={true} to={`https://blog.nueamek.cloud/`} target="_blank" rel="noopener noreferrer">
                                    <span 
                                        className="menu_nav_noauth"
                                    >
                                    Blog  
                                    </span>
                                </Link>
                            </div>
                        </Col>
                        <Col className="gutter-row col_flex col_center" span={6}>
                            <div>
                                <Link smooth={true} to={`${DOMAIN_URL}/#faq_panel`}>
                                    <span 
                                        className="menu_nav_noauth"
                                    >
                                    Faq  
                                    </span>
                                </Link>
                            </div>
                        </Col>
                        <Col className="gutter-row col_flex col_center" span={6} style={{paddingLeft: "0", paddingRight: "20px"}}>
                            <Link smooth={true} to={`${DOMAIN_URL}/contact`}>
                                <span 
                                    className="menu_nav_noauth"
                                >
                                    Contact  
                                </span>
                            </Link>
                        </Col>
                        <Col className="gutter-row col_flex col_center" span={6}>
                            <Button 
                                type="primary"
                                className="btn_lgn_noauth"
                                onClick={() => push(`/login`)}
                                style={{boxShadow: "unset"}}
                            >
                                SIGN IN
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </AntdLayout.Header>
    )
}