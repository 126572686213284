import { AuthBindings, useNavigation } from "@refinedev/core";
import { API_URL, DOMAIN_URL, TOKEN_KEY } from "./constants";
import axios from "axios";
import { AuthHelper } from "@pankod/refine-strapi-v4";
const mockUsers = [{ email: "demo@test.com" }];

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

// #                        _oo0oo_
// #                       o8888888o
// #                       88" . "88
// #                       (| -_- |)
// #                       0\  =  /0
// #                     ___/`---'\___
// #                   .' \|     |// '.
// #                  / \|||  :  |||// \
// #                 / _||||| -:- |||||- \
// #                |   | \\  -  /// |   |
// #                | \_|  ''\---/''  |_/ |
// #                \  .-\__  '-'  ___/-. /
// #              ___'. .'  /--.--\  `. .'___
// #           ."" '<  `.___\_<|>_/___.' >' "".
// #          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
// #          \  \ `_.   \_ __\ /__ _/   .-` /  /
// #      =====`-.____`.___ \_____/___.-`___.-'=====
// #                        `=---='

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    const { data, status } = await strapiAuthHelper.login(email, password);
    
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);

      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.jwt}`;

      let page = window?.location?.href.split("/");
      let pageSPLIT: any = page[3].split("?");
      let pageTOKEN: any = pageSPLIT[1];

      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        return {success: false};
      }else{
        let dataUser: any = undefined;
        const { data, status } = await strapiAuthHelper.me(token);
        const TOKEN = localStorage.getItem(TOKEN_KEY as string);
  
        const getProfile = await axios.get(API_URL + `/api/user-profiles/UserPermission?id=${data?.id}`, {headers: { Authorization: `Bearer ${TOKEN}` }}).then((res) => {
          dataUser = res?.data[0]?.user_detail;
        });

        if(dataUser?.role == "Admin"){
          return {
            success: true,
            redirectTo: `/pn-admin`,
          };
        }else if(dataUser?.role == "User"){
          if(pageTOKEN !== undefined){
            return {
              success: true,
              redirectTo: `/products/product-config/${pageTOKEN}`,
            };
          }else if(pageTOKEN == undefined){
            return {
              success: true,
              redirectTo: "/products",
            };
          }
        }
      }
    }
    
    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Invalid email or password",
      },
    };
  },
  logout: async () => {
    // const { replace } = useNavigation();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('role');
    localStorage.clear();

    // window.location.reload();
    // replace('/login');

    return {
      success: true,
      redirectTo: "/",
    };
    // return Promise.resolve();
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let page = window?.location?.href.split("/");
      let pageTOKEN:any = page[3] ? page[3] : undefined;

      let dataUser: any = undefined;
      const { data, status } = await strapiAuthHelper.me(token);
      const TOKEN = localStorage.getItem(TOKEN_KEY as string);

      const getProfile = await axios.get(API_URL + `/api/user-profiles/UserPermission?id=${data?.id}`, {headers: { Authorization: `Bearer ${TOKEN}` }}).then((res) => {
        dataUser = res?.data[0]?.user_detail;
      });
      
      if(pageTOKEN == undefined){
        if(dataUser?.role == "Admin"){
          window.location.href = `${DOMAIN_URL}/pn-admin`;
        }else if(dataUser?.role == "User"){
          window.location.href = `${DOMAIN_URL}/products`;
        }
      }
      else if(pageTOKEN !== undefined){
        if(dataUser?.role == "Admin"){
          if(pageTOKEN !== "pn-admin" && pageTOKEN !== "account"){
            window.location.href = `${DOMAIN_URL}/pn-admin`;
          }
        }else if(dataUser?.role == "User"){
          if(pageTOKEN == "pn-admin"){
            window.location.href = `${DOMAIN_URL}/products`;
          }
        }
      }

      return {
        authenticated: true,
      };
    }else{
      localStorage.clear();
    }

    return {
      authenticated: false,
      error: {
        message: "Check failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => {
    const role = await localStorage.getItem('role');
    return {
      role : role,
      permission : {}
    }
  },
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    let dataUser: any = undefined;
    const { data, status } = await strapiAuthHelper.me(token);
    const TOKEN = localStorage.getItem(TOKEN_KEY as string);
    
    const getProfile = await axios.get(API_URL + `/api/user-profiles/UserPermission?id=${data?.id}`, {headers: { Authorization: `Bearer ${TOKEN}` }}).then((res) => {
      dataUser = res?.data[0]?.user_detail;
      if(res?.data[0]?.is_set_pwd == false){
        localStorage.setItem("PATH", "GHOST");
      }else if(res?.data[0]?.is_set_pwd == true){
        localStorage?.removeItem("PATH");
      }
    });


    localStorage.setItem("USER", dataUser?.user?.id);
    localStorage.setItem("USERID", dataUser?.id);
    localStorage.setItem("VERIFY", dataUser?.is_verify);
    localStorage.setItem("SECRET_KEY", dataUser?.secret_key);

    if (status === 200 && dataUser) {
      const { id, username, email } = data;
      return {
        id,
        name: username,
        email,
        user_profile: dataUser
      };
    }

    return null;
  },
};
