import { Col, Divider, Row, Image } from "antd"
import { DOMAIN_URL } from "../../constants"
import { PhoneOutlined, PlusOutlined, MailOutlined, CreditCardOutlined } from '@ant-design/icons';
import { useGetIdentity } from "@refinedev/core";

interface IPANEL {
    dataLoad: any
    mode?: "show" | "print"
}

export const Transaction: React.FC<IPANEL> = ({
    dataLoad = undefined,
    mode = "show"
})=> {

    const { data: user } = useGetIdentity<any>();

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    return (
        <div style={{padding: mode == "show" ? "3rem" : "4rem 4.5rem 0 4.5rem"}}>
            <Row gutter={12}>
                <Col className="gutter-row" span={12}>
                    <img style={{width: "300px"}} src={`${DOMAIN_URL}/images/svg/logo_new_1.svg`} alt="ICON"/>
                </Col>
                <Col className="gutter-row" span={12}>
                    <div style={{display: "flex", justifyContent: "end"}}>
                        <div>
                            <div style={{float: "right"}}>{"(Original)"}</div>
                            <div style={{color: "#F48515", fontSize: mode == "show" ? "45px" : "32px", fontWeight: "bold", position: "absolute", right: "10px", top: "10px"}}>{"Invoice"}</div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row gutter={12} style={{marginTop: "2rem"}}>
                <Col className="gutter-row" span={mode == "show" ? 16 : 17} style={{paddingTop: "10px"}}>
                    <Row gutter={8}>
                        <Col className="gutter-row" span={15}>
                            <Row gutter={mode == "show" ? 12: 6}>
                                <Col className="gutter-row" span={5}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Seller</div>
                                </Col>
                                <Col className="gutter-row" span={1}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                </Col> 
                                <Col className="gutter-row" span={18}>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>Nueamek Solution Company Limited</div>
                                </Col>
                            </Row>
                            <Row gutter={mode == "show" ? 12: 6}>
                                <Col className="gutter-row" span={5}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Address</div>
                                </Col>
                                <Col className="gutter-row" span={1}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                </Col> 
                                <Col className="gutter-row" span={18}>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>172 Lasalle 48 Sukhumvit 105 Bangna Tai Bangna Bangkok Thailand 10260</div>
                                </Col>
                            </Row>
                            <Row gutter={mode == "show" ? 12: 6}>
                                <Col className="gutter-row" span={5}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Tax ID</div>
                                </Col>
                                <Col className="gutter-row" span={1}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                </Col> 
                                <Col className="gutter-row" span={18}>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>0105564025465 (Head Office)</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="gutter-row" span={9}>
                            <div style={{fontSize: mode == "show" ? "14px" : "10px"}}><span style={{marginRight: "10px"}}><PhoneOutlined /></span> 0626865444</div>
                            <div style={{fontSize: mode == "show" ? "14px" : "10px"}}><span style={{marginRight: "10px"}}><MailOutlined /></span> info@nueamek.com</div>
                        </Col>
                    </Row>

                    <Divider style={{margin: "10px 0"}}/>

                    <Row gutter={8}>
                        <Col className="gutter-row" span={15}>
                            <Row gutter={mode == "show" ? 12: 6}>
                                <Col className="gutter-row" span={5}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Customer</div>
                                </Col>
                                <Col className="gutter-row" span={1}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                </Col> 
                                <Col className="gutter-row" span={18}>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{dataLoad?.account_type == "Organization" ? dataLoad?.organization_name : dataLoad?.billing_address?.first_name + " " + dataLoad?.billing_address?.last_name}</div>
                                </Col>
                            </Row>
                            <Row gutter={mode == "show" ? 12: 6}>
                                <Col className="gutter-row" span={5}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Address</div>
                                </Col>
                                <Col className="gutter-row" span={1}>
                                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                </Col> 
                                <Col className="gutter-row" span={18}>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>
                                        {
                                            dataLoad?.billing_address?.address + " " +
                                            dataLoad?.billing_address?.amphure + " " +
                                            dataLoad?.billing_address?.tambon + " " +
                                            dataLoad?.billing_address?.province + " " +
                                            dataLoad?.billing_address?.country + " " +
                                            dataLoad?.billing_address?.postcode
                                        } 
                                    </div>
                                </Col>
                            </Row>
                            {dataLoad?.account_type == "Organization" &&
                            <>
                                <Row gutter={mode == "show" ? 12: 6}>
                                    <Col className="gutter-row" span={5}>
                                        <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Tax ID</div>
                                    </Col>
                                    <Col className="gutter-row" span={1}>
                                        <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                    </Col> 
                                    <Col className="gutter-row" span={18}>
                                        <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{dataLoad?.tax_id} ({dataLoad?.branch_type == "Other" ? dataLoad?.branch_type + " "+ dataLoad?.branch_id : dataLoad?.branch_type})</div>
                                    </Col>
                                </Row>
                                <Row gutter={mode == "show" ? 12: 6}>
                                    <Col className="gutter-row" span={5}>
                                        <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>Dear</div>
                                    </Col>
                                    <Col className="gutter-row" span={1}>
                                        <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                                    </Col> 
                                    <Col className="gutter-row" span={18}>
                                        <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{dataLoad?.billing_address?.first_name + " " + dataLoad?.billing_address?.last_name}</div>
                                    </Col>
                                </Row>
                            </>
                            }
                        </Col>
                        <Col className="gutter-row" span={9}>
                            <div style={{fontSize: mode == "show" ? "14px" : "10px"}}><span style={{marginRight: "10px"}}><PhoneOutlined /></span> {dataLoad?.billing_address?.tel}</div>
                            <div style={{fontSize: mode == "show" ? "14px" : "10px"}}><span style={{marginRight: "10px"}}><MailOutlined /></span> {dataLoad?.user?.email}</div>
                        </Col>
                    </Row>
                </Col>
                <Col className="gutter-row" span={mode == "show" ? 8 : 7}>
                    <div style={{backgroundColor: "#FFF3E6", borderRadius: "10px", padding: "10px 10px"}}>
                        <Row gutter={4}>
                            <Col className="gutter-row" span={8}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>Doc. No.</div>
                            </Col>
                            <Col className="gutter-row" span={1}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                            </Col> 
                            <Col className="gutter-row" span={15}>
                                <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"NXC-" + dataLoad?.invoice_no}</div>
                            </Col>
                        </Row>
                        <Row gutter={4}>
                            <Col className="gutter-row" span={8}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>Issue Date</div>
                            </Col>
                            <Col className="gutter-row" span={1}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                            </Col> 
                            <Col className="gutter-row" span={15}>
                                <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{dataLoad?.invoice_date}</div>
                                {/* <div>{dayjs(dataLoad?.invoice_date?.toISOString()).format("DD/MM/YYYY")}</div> */}
                            </Col>
                        </Row>
                        <Row gutter={4}>
                            <Col className="gutter-row" span={8}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>Credit</div>
                            </Col>
                            <Col className="gutter-row" span={1}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                            </Col> 
                            <Col className="gutter-row" span={15}>
                                <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"7 Days"}</div>
                            </Col>
                        </Row>
                        <Row gutter={4}>
                            <Col className="gutter-row" span={8}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>Due Date</div>
                            </Col>
                            <Col className="gutter-row" span={1}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                            </Col> 
                            <Col className="gutter-row" span={15}>
                                <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{dataLoad?.due_date}</div>
                                {/* <div>{dayjs(dataLoad?.due_date).format("DD/MM/YYYY")}</div> */}
                            </Col>
                        </Row>
                        <Row gutter={4}>
                            <Col className="gutter-row" span={8}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>Ref</div>
                            </Col>
                            <Col className="gutter-row" span={1}>
                                <div style={{fontWeight: "500", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                            </Col> 
                            <Col className="gutter-row" span={15}>
                                <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"-"}</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Divider style={{margin: "16px 0"}}/>

            <Row gutter={24}>
                <Col className="gutter-row" span={10}>
                    <div style={{fontWeight: "bold", color: "#68666C", fontSize: mode == "show" ? "16px" : "12px"}}>{"Description"}</div>
                </Col>
                {/* <Col className="gutter-row" span={2}>
                    <div>{"VAT"}</div>
                </Col> */}
                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end"}}>
                    <div style={{fontWeight: "bold", color: "#68666C", fontSize: mode == "show" ? "16px" : "12px"}}>{"Unit Price"}</div>
                </Col>
                <Col className="gutter-row" span={2}>
                    <div style={{fontWeight: "bold", color: "#68666C", fontSize: mode == "show" ? "16px" : "12px"}}>{"Quantity"}</div>
                </Col>
                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end", paddingRight: "41px"}}>
                    <div style={{fontWeight: "bold", color: "#68666C", fontSize: mode == "show" ? "16px" : "12px"}}>{"Total Price"}</div>
                </Col>
            </Row>

            <Row gutter={24} style={{marginTop: "1rem"}}>
                <Col className="gutter-row" span={10} style={{paddingLeft: "20px"}}>
                    <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>
                        <span style={{marginRight: "10px"}}>{dataLoad?.product_data?.name_en}</span>
                        <span style={{textTransform: "uppercase"}}>({dataLoad?.product_data?.type})</span>
                        <span style={{textTransform: "uppercase"}}> - {dataLoad?.expense_type}</span>
                    </div>
                    <div style={{marginTop: mode == "show" ? "0px" : "-5px"}}>
                        <span style={{marginRight: "10px"}}><PlusOutlined style={{fontSize: mode == "show" ? "10px" : "6px"}}/></span>
                        <span style={{fontSize: mode == "show" ? "14px" : "10px"}}>OS Template: {dataLoad?.expense_type}</span>
                    </div>
                    <div style={{marginTop: mode == "show" ? "0px" : "-5px"}}>
                        <span style={{marginRight: "10px"}}><PlusOutlined style={{fontSize: mode == "show" ? "10px" : "6px"}}/></span>
                        <span style={{fontSize: mode == "show" ? "14px" : "10px"}}>SSH-KEY: {}</span>
                    </div>
                    <div style={{marginTop: mode == "show" ? "0px" : "-5px"}}>
                        <span style={{marginRight: "10px"}}><PlusOutlined style={{fontSize: mode == "show" ? "10px" : "6px"}}/></span>
                        <span style={{fontSize: mode == "show" ? "14px" : "10px"}}>IP Address: {"x 1"}</span>
                    </div>
                </Col>
                {/* <Col className="gutter-row" span={2} style={{display: "flex", alignItems: "center"}}>
                    <div>{"Yes"}</div>
                </Col> */}
                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end", alignItems: "start"}}>
                    {/* <div>{`${nf?.format(dataLoad?.price)} THB`}</div> */}
                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{nfformat(dataLoad?.price)}</div>
                </Col>
                <Col className="gutter-row" span={2} style={{display: "flex", alignItems: "start"}}>
                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"1"}</div>
                </Col>
                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "end", alignItems: "start", paddingRight: "41px"}}>
                    {/* <div>{`${nf?.format(dataLoad?.price)} THB`}</div> */}
                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{nfformat(dataLoad?.price)}</div>
                </Col>
            </Row>

            <Divider style={{margin: "16px 0"}}/>

            <Row gutter={[24, 6]} style={{marginTop: "1rem"}}>
                <Col className="gutter-row" span={10}>
                    <div style={{backgroundColor: "#FFF3E6", borderRadius: "10px", padding: "15px 20px", minHeight: "110px", height: "auto", maxHeight: "500px"}}>
                        <div style={{textTransform: "uppercase", textDecoration: "underline", fontWeight: "bold", fontSize: mode == "show" ? "16px" : "12px"}}>{"notes"}</div>
                        {dataLoad?.note ? <div style={{paddingLeft: "10px", fontSize: mode == "show" ? "14px" : "10px"}}><span style={{marginRight: "0.5rem", fontWeight: "bold"}}>•</span>{dataLoad?.note}</div> : <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"-"}</div>}
                    </div>
                </Col>
                <Col className="gutter-row" span={2} />
                <Col className="gutter-row" span={12}>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={13} style={{display: "flex", justifyContent: "end", alignItems: "start"}}>
                            <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>{"Sub Total"}</div>
                        </Col>
                        <Col className="gutter-row" span={1}>
                            <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                        </Col> 
                        <Col className="gutter-row" span={10} style={{paddingRight: "35px"}}>
                            <div style={{textAlign: "right", fontSize: mode == "show" ? "14px" : "10px"}}>{nfformat(dataLoad?.price)}</div>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={13} style={{display: "flex", justifyContent: "end", alignItems: "start"}}>
                            <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>{"VAT (7.00%)"}</div>
                        </Col>
                        <Col className="gutter-row" span={1}>
                            <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                        </Col> 
                        <Col className="gutter-row" span={10} style={{paddingRight: "35px"}}>
                            <div style={{textAlign: "right", fontSize: mode == "show" ? "14px" : "10px"}}>{nfformat(dataLoad?.vat)}</div>
                        </Col>
                    </Row>

                    <div style={{backgroundColor: "#FFF3E6", borderRadius: "10px", padding: "20px 15px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "1rem", marginTop: "1rem"}}>
                        <div style={{fontWeight: "bold", fontSize: mode == "show" ? "26px" : "18px"}}>
                            <span style={{marginRight: "10px"}}>{"Grand Total"}</span>
                            <span style={{marginRight: "10px"}}>{":"}</span>
                            <span>{nfformat(dataLoad?.total_price)}</span>
                        </div>
                    </div>
                </Col>
            </Row>

            <Divider style={{margin: "16px 0"}}/>

            <Row gutter={[6, 6]} style={{marginTop: "1rem"}}>
                <Col className="gutter-row" span={12}>
                    <Row gutter={6}>
                        <Col className="gutter-row" span={7}>
                            <div style={{fontWeight: "bold", fontSize: mode == "show" ? "16px" : "12px"}}>
                                <span style={{marginRight: "10px"}}><CreditCardOutlined/></span>
                                <span>{"Payment"}</span>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={1}>
                            <div style={{fontWeight: "bold", fontSize: mode == "show" ? "14px" : "10px"}}>:</div>
                        </Col> 
                        <Col className="gutter-row" span={16}>
                            <Row gutter={12}>
                                <Col className="gutter-row">
                                    {/* <img style={{width: "30px", height: "30px"}} src={`${DOMAIN_URL}/images/svg/icon/bank_icon_1.svg`} alt="ICON"/> */}
                                    <Image src={`${DOMAIN_URL}/images/svg/icon/bank_icon.svg`} preview={false} style={{width: mode == "show" ? "30px" : "25px", height: mode == "show" ? "30px" : "25px"}}/>
                                </Col>
                                <Col className="gutter-row">
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"ธนาคารไทยพาณิชย์"}</div>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"1752663451"}</div>
                                    <div style={{fontSize: mode == "show" ? "14px" : "10px"}}>{"บริษัท เหนือเมฆ โซลูชั่น จำกัด"}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}