import { useCustomMutation, useLogin, useNavigation, useTranslate } from "@refinedev/core";
import { Avatar, Button, Card, Checkbox, Form, Input, Layout, Segmented, Tag, notification } from "antd";
import { useNavigate } from "react-router";
import "./styles.css";
import WarningIcon from '@mui/icons-material/Warning';
import { useEffect } from "react";
import liff from "@line/liff";
import { DOMAIN_URL } from "../../constants";

export interface IVerify {
    secret_key: string;
}

export const VerifyPage: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<any>();
    const { replace, list} = useNavigation();

    function onFinishKey(values: any){}

    return(
        <Layout className="layout-verify">
            <div id="container-verify-bg">
                <div className="layout-container-verify">
                    <img style={{width: "15%"}} src={`${DOMAIN_URL}/images/svg/icon-no-text-dark.svg`} alt="NUEMEK"/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <img style={{width: "45%", marginRight: "1rem"}} src={`${DOMAIN_URL}/images/svg/logo-connect-line.svg`} alt="NUEMEK"/>
                    </div>

                    <div style={{marginTop: "0.5rem"}}>
                        <span className="txt_5vw">เชื่อมต่อ เหนือเมฆ เข้ากับ LINE เพื่อ</span>
                        <br />
                        <span className="txt_5vw">รับการแจ้งเตือน</span>
                    </div>

                    <div className="blog-warning">
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div className="margin-right-icon"><WarningIcon style={{color: "#FFB231"}}/></div>
                            <div style={{fontSize: "16px", color: "#696969"}}>คุณยังไม่ได้เชื่อมต่อบัญชีเข้ากับ LINE</div>
                        </div>
                    </div>

                    <div style={{marginTop: "1.5rem"}}>
                        <Form<IVerify>
                            layout="vertical"
                            form={form}
                            onFinish={async (values: any) => {
                                onFinishKey(values);
                            }}
                            requiredMark={false}
                            initialValues={{
                                remember: false,
                            }}
                        >

                            <Form.Item
                                className="disable_req"
                                name="secret_key"
                                label=" "
                                rules={[{ required: true, message: "Please Enter Secret Key" }]}
                            >
                                <Input size="large" placeholder="Secret Key"/>
                            </Form.Item>

                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                block
                                style={{marginTop: "1rem"}}
                            >
                                {t("Connect to Line")}
                            </Button>

                            <div style={{ marginTop: "2rem", textAlign: "center"}}>
                                <span>ใช้ Secret Key ที่คุณได้รับใน Email สำหรับการเชื่อมต่อ</span>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}