import { IResourceComponentsProps, useCustom, useNavigation } from "@refinedev/core";
import { TransectionAdmin } from "./transection";
import { SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useRef, useState } from "react";
import { Button, Card, Col, Row, Spin, Tag } from "antd";
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from "react-to-print";
import React from "react";

export const InvoiceAdminPage: React.FC<IResourceComponentsProps> = () => {

    const { push } = useNavigation();
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[4].split("?");
    let tokenSPLIT: any = pageSPLIT[1].split(/\W+/);

    let pmTOKEN: any = tokenSPLIT[1];
    let transTOKEN: any = tokenSPLIT[3];

    const [isLoading, setLoading] = useState<boolean>(true);
    const [dtTrans, setdtTrans] = useState<any>();

    const { data: dataAPI, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/topup/getPaymentById`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                payment_id: pmTOKEN,
                trans_id: transTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdtTrans(dataLoad?.data_trans);
                setLoading(false);
            },
        },
    });

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "nxc_"+dtTrans?.invoice_no+"_invoice",
    });

    class ComponentToPrint extends React.Component {
        render() {
          return (
            <TransectionAdmin dataLoad={dtTrans} mode="print" />
          );
        }
    }
    
    return(
        <Spin spinning={isLoading}>
            <div style={{display: "none"}}><ComponentToPrint ref={componentRef} /></div>
            <div style={{display: "flex", justifyContent: "center"}}>
                
                <div style={{width: "1280px", backgroundColor: "#fff", padding: "3rem 2rem", borderRadius: "10px", boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px"}}>
                    <Row gutter={12} style={{margin: "2rem 0 3rem 0"}}>
                        <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", gap: "5px", alignContent: "center"}}>
                            <div>
                                <div style={{marginBottom: "1rem"}}>
                                    <div style={{fontSize: "26px", fontWeight: "bold", color: "#000000B2"}}>{"Invoice #" + dtTrans?.invoice_no}</div>
                                    <div style={{cursor: "pointer", color: "#EE642A"}} onClick={() => push(`/pn-admin`)}>{"Back to Admin panel"}</div>
                                </div>
                                <Tag color={(dtTrans?.status_code !== "5" && dtTrans?.status_code !== "1") ? "#FF5353": "#90E278"} style={{padding: "7px 50px"}}>
                                    <span style={{textTransform: "uppercase", fontSize: "16px"}}>{(dtTrans?.status_code !== "5" && dtTrans?.status_code !== "1") ? "unpaid" : "paid"}</span>
                                </Tag>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "end", gap: "15px"}}>
                            <div><Button icon={<PrinterOutlined />} onClick={()=> handlePrint()} size="large">Print Invoice</Button></div>
                        </Col>
                    </Row>
                    <Card>
                        <TransectionAdmin dataLoad={dtTrans} mode="show"/>
                    </Card>
                </div>
            </div>
        </Spin>
    )
}