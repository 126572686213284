import { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd"
import { useCustom, useGetIdentity, useGetLocale, useLogout, useNavigation, useSetLocale, useTranslate } from "@refinedev/core";
import { Avatar, MenuProps, theme, Layout as AntdLayout, Space, Dropdown, Button, Typography, message, Badge, Popover, Row, Col, Divider, Modal, } from "antd";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { DownOutlined, LogoutOutlined, EditOutlined, ShoppingCartOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";

let nf = new Intl.NumberFormat('en-US');
export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky,}) => {
    const t = useTranslate();
    const { Text } = Typography;
    const { useToken } = theme;
    const { token } = useToken();
    const { data: user } = useGetIdentity<any>();
    const { mutate: mutateLogout } = useLogout();
    const { replace, push } = useNavigation();

    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const USER: any = localStorage.getItem("USER");

    const [dtCart, setdtCart] = useState<any>();
    const [conFopen, setconFopen] = useState(false);
    const [Lineopen, setLineopen] = useState(false);
    const [itemRemove, setitemRemove] = useState<any>();

    const { data: dataCart, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionIncart`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                user_id: USER
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data?.filter((item: any) => (item?.paid_status !== "4" || item?.paid_status !== "5")).reverse();
                setdtCart((pre: any) => dataLoad);
            },
        },
    });
    
    const items: MenuProps['items'] = [
        {
            label: 'Edit Profile',
            key: 'edit-profile',
            icon: <EditOutlined />,
        },
        {
            label: 'Logout',
            key: 'logout',
            danger: true,
            icon: <LogoutOutlined />,
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if(key == "edit-profile"){
            push("/account")
        }else if(key == "logout"){
            mutateLogout();
        }
    };

    const headerStyles: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
    };

    if (sticky) {
        headerStyles.position = "sticky";
        headerStyles.top = 0;
        headerStyles.zIndex = 1;
    }

    function removeitem(id: any) {
        axiosInstance.put(`${SOURCE_URL}/api/v1/transaction/update?id=${id}`,
            {
                paid_status: "0",
                update_by: {
                    user_id: user?.id,
                    user_name: user?.name
                }
            },
            { headers: { Authorization: `Bearer ${TOKEN}` } }
            )
        .then((response) => {
            let res = response?.data;
            refetch();
            itemRemove(undefined);
        })
        .catch(error => {

        });
    }

    const contentUser = () => (
        <div style={{minWidth: "200px", maxWidth: "400px", padding: "1rem"}}>
            <Row gutter={12}>
                <Col className="gutter-row" span={6}>
                    <Avatar icon={<UserOutlined />} style={{backgroundColor: "#FF5353"}} size={40}/>
                </Col>
                <Col className="gutter-row" span={18} style={{paddingLeft: "10px"}}>
                    <div>{t("Welcom back!")}</div>
                    <div>{user?.user_profile?.first_name + " " + user?.user_profile?.last_name}</div>
                </Col>
            </Row>

            <Divider />

            <div className="menu_icon_header">
                <div style={{cursor: "pointer", marginBottom: "1rem"}} onClick={() => push("/account")}>
                    <span style={{marginRight: "1rem"}}><EditOutlined /></span>
                    <span>{t("Edit Profile")}</span>
                </div>
                <div style={{cursor: "pointer", color: "#ff4d4f"}} onClick={() => mutateLogout()}>
                    <span style={{marginRight: "1rem"}}><LogoutOutlined /></span>
                    <span>{t("Logout")}</span>
                </div>
            </div>
        </div>
    )

    const contentCart = (data: any) => (
        <div style={{width: "300px", padding: "1rem"}}>
            {data?.length > 0 && data?.map((item: any, index: any) => {return (
                <div className="cart_list_item" onClick={() => push(`/cart/${item?._id}`)} key={index}>
                    <Row gutter={42}>
                        <Col className="gutter-row" span={20}>
                            <div>
                                <span className="txt_item">{`#${item?.order_number}  (${item?.product_obj?.name})`}</span>
                            </div>
                            <div>
                                <span style={{color: "#BFBFBF"}}>{`OS: ${item?.product_obj?.os?.label}`}</span>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end", alignContent: "center"}}>
                            <DeleteOutlined 
                                style={{
                                    color: "#FF5353", 
                                    cursor: "pointer"
                                }} 
                                onClick={() => {
                                    setconFopen(true);
                                    setitemRemove(item?._id);
                                }}
                            />
                        </Col>
                    </Row>

                    {index !== data?.length -1 && <Divider style={{margin: "10px 0px"}}/>}
                </div>
            )})}
        </div>
    );

    return(
        <AntdLayout.Header style={headerStyles}>
            <Space>
                {user &&
                    <Space style={{ marginLeft: "8px" }} size="middle">
                        <div style={{lineHeight: "0"}}>
                            <div className="line_btn" onClick={() => setLineopen(true)}>
                                <div className="logo_line_btn"><img src={`${DOMAIN_URL}/images/svg/icon-line.svg`} alt="ICON"/></div>
                                <div className="txt_line_btn">More information</div>
                            </div>
                        </div>

                        <Divider type="vertical" style={{height: 30}}/>

                        <Popover content={contentUser} trigger="hover" placement="bottomRight">
                            <UserOutlined style={{fontSize: "20px", color: "#a6a6a6", marginRight: "0.5rem"}}/>
                            <Text 
                                strong 
                                style={{color: "#a6a6a6", marginRight: "0.5rem"}}
                            >
                                {
                                    user?.user_profile?.first_name == "" && user?.user_profile?.last_name =="" ?
                                    `User ${user?.user_profile?.id}`
                                    :
                                    user?.user_profile?.first_name
                                }
                            </Text>
                            <DownOutlined style={{color: "#a6a6a6"}}/>
                        </Popover>

                        <div style={{lineHeight: "0"}}>
                            <Badge count={dtCart?.length}>
                                <Popover content={ dtCart?.length > 0 && contentCart(dtCart)} trigger="hover">
                                    <ShoppingCartOutlined 
                                        style={{
                                            color: "#F48515", 
                                            fontSize: "26px", 
                                            cursor: "pointer"
                                        }}
                                        // onClick={() => replace(`/cart`)}
                                    />
                                </Popover>
                            </Badge>
                        </div>
                    </Space>
                }   
            </Space>

            <Modal
                open={Lineopen}
                // closeIcon={false}
                // closable={false}
                maskClosable={true}
                zIndex={999999999}
                footer={<></>}
                width={650}
                onCancel={() => setLineopen(false)}
            >
                <div style={{margin: "2rem 5px", padding: "0 2rem", display: "flex", justifyContent: "center"}}>
                    <img className="logoLogin" src={`${DOMAIN_URL}/images/LINE_QR_NOW.jpg`} alt="LINE QR CODE" style={{width: "250px"}}/>
                </div>
                <div style={{marginBottom: "2rem"}}>
                    <div style={{fontWeight: "bold", fontSize: "22px", textAlign: "center"}}>{t("Scan the QR code to add as a friend on LINE")}</div>
                    <div style={{textAlign: "center"}}>{t("Open your LINE app and user the in-app QR code reader.")}</div>
                </div>
            </Modal>

            <Modal
                title="Are you sure you want to remove product from cart ?"
                open={conFopen}
                onOk={() => {
                    removeitem(itemRemove)
                    setconFopen(false)
                }}
                onCancel={() => {
                    setitemRemove(undefined);
                    setconFopen(false)
                }}
                okText="Confirm"
                cancelText="Cancel"
                zIndex={999999999}
            />
        </AntdLayout.Header>
    )
}