import axios from "axios";
import { useEffect, useState } from "react";
import liff from "@line/liff";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Avatar, Button, Card, Checkbox, Form, Input, Layout, Segmented, Spin, Tag, notification } from "antd";
import { LIFFID, SOURCE_URL } from "../../constants";
import WarningIcon from '@mui/icons-material/Warning';
import { DOMAIN_URL } from "../../constants";
import { LoadingOutlined } from '@ant-design/icons';

export interface IVerify {
    secret_key: string;
}

let userAccount: any = undefined;
export const VerifyLINE: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const [form] = Form.useForm<any>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isVerify, setVerify] = useState<boolean>(false);

    useEffect(() => {
        const login = async () => {
            await liff.init({ liffId: LIFFID });
            
            if (liff.isLoggedIn()) {
                const profile = await liff.getProfile();

                userAccount = profile;
                
                let body: any = {
                    "line_id": profile?.userId,
                    "mode": "3",
                }
        
                let submit = await axios.post(SOURCE_URL + "/api/v1/verify/userVerify", body, { headers: { } })
                .then(async response => {
                    let res: any = response?.data;

                    setVerify(res);
                });

                liff.openWindow({
                    url: `${process.env.BASE_URL}/th/auth/verify?uuid=${profile.userId}&img=${profile.pictureUrl}`,
                    external: true,
                });

                setLoading(false);
                liff.closeWindow();
            } else {
                liff.login();

                userAccount = undefined;
            }
        };

        login();
    }, []);

    async function onFinishKey(values: any){
        let body: any = {
            "line_id": userAccount?.userId,
            "line_name": userAccount?.displayName,
            "line_pic_url": userAccount?.pictureUrl,
            "secret": String(values?.secret_key),
            "mode": "1",
        }

        let submit = await axios.post(SOURCE_URL + "/api/v1/verify/userVerify", body, { headers: { } })
        .then(async response => {
            let body: any = {
                "line_id": userAccount?.userId,
                "mode": "3",
            }
    
            let submit = await axios.post(SOURCE_URL + "/api/v1/verify/userVerify", body, { headers: { } })
            .then(async response => {
                let res: any = response?.data;

                setVerify(res);
            });
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    async function onFinishDIS(){
        let body: any = {
            "line_id": userAccount?.userId,
            "mode": "2",
        }

        let submit = await axios.post(SOURCE_URL + "/api/v1/verify/userVerify", body, { headers: { } })
        .then(async response => {
            let body: any = {
                "line_id": userAccount?.userId,
                "mode": "3",
            }
    
            let submit = await axios.post(SOURCE_URL + "/api/v1/verify/userVerify", body, { headers: { } })
            .then(async response => {
                let res: any = response?.data;

                setVerify(res);
            });
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    return(
        <Layout className="layout-verify">
            <div id="container-verify-bg">
                {/* <Spin spinning={isLoading}> */}
                    <div className="layout-container-verify">
                        <img style={{width: "15%"}} src={`${DOMAIN_URL}/images/svg/icon-no-text-dark.svg`} alt="NUEMEK"/>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img style={{width: "45%", marginRight: "1rem"}} src={`${DOMAIN_URL}/images/svg/logo-connect-line.svg`} alt="NUEMEK"/>
                        </div>

                        <div style={{marginTop: "0.5rem"}}>
                            <span className="txt_5vw">เชื่อมต่อ เหนือเมฆ เข้ากับ LINE เพื่อ</span>
                            <br />
                            <span className="txt_5vw">รับการแจ้งเตือน</span>
                        </div>

                        {
                            isLoading == true ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} style={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px"}}/>
                            :isLoading == false &&
                                isVerify == false ?
                                    <div>
                                        <div className="blog-warning">
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <div className="margin-right-icon"><WarningIcon style={{color: "#FFB231"}}/></div>
                                                <div style={{fontSize: "16px", color: "#696969"}}>คุณยังไม่ได้เชื่อมต่อบัญชีเข้ากับ LINE</div>
                                            </div>
                                        </div>
    
                                        <div style={{marginTop: "1.5rem"}}>
                                            <Form<IVerify>
                                                layout="vertical"
                                                form={form}
                                                onFinish={async (values: any) => {
                                                    onFinishKey(values);
                                                }}
                                                requiredMark={false}
                                                initialValues={{
                                                    remember: false,
                                                }}
                                            >
    
                                                <Form.Item
                                                    className="disable_req"
                                                    name="secret_key"
                                                    label=" "
                                                    rules={[{ required: true, message: "Please Enter Secret Key" }]}
                                                >
                                                    <Input size="large" placeholder="Secret Key"/>
                                                </Form.Item>
    
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    htmlType="submit"
                                                    block
                                                    style={{marginTop: "1rem"}}
                                                >
                                                    {t("Connect to Line")}
                                                </Button>
    
                                                <div style={{ marginTop: "2rem", textAlign: "center"}}>
                                                    <span>ใช้ Secret Key ที่คุณได้รับใน Email สำหรับการเชื่อมต่อ</span>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                : isVerify == true &&
                                    <div>
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            block
                                            style={{marginTop: "3rem"}}
                                            onClick={()=> onFinishDIS()}
                                        >
                                            {t("Disconnect Line")}
                                        </Button>
                                    </div>
                        }
                    </div>
                {/* </Spin> */}
            </div>
        </Layout>
    )
}