import { List } from "@refinedev/antd";
import { IResourceComponentsProps, useNavigation, useTranslate } from "@refinedev/core";
import { Card, Col, Form, Row, Tabs, notification } from "antd";
import { useEffect, useState } from "react";
import { BillinghisPage } from "./history";
import { TopupPage } from "./top_up";

let countNoti: number = 0;
export const BillingList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { TabPane } = Tabs;
    const { create, edit } = useNavigation();

    const [TabM, setTabM] = useState<any>("1");
    const TOPUP: any = localStorage.getItem("TOPUP");

    useEffect(() => {
        if(TOPUP == "T" && countNoti == 0){
            countNoti = countNoti + 1;
            successNoti();
            setTabM("2");
            localStorage.removeItem("TOPUP");
        }
    }, [TOPUP])

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("บันทึกข้อมูลเสร็จสิ้น โปรดรอการตรวจสอบจากระบบ"),
            duration: 3
        });
    };
    
    return(
        <div className="layout_page">
            {/* <div className="nav_breadcrumbs_custom" style={{display: "flex"}}>
                <div style={{marginRight: "5px"}}>{"Billing"}</div>
                <div style={{marginRight: "5px"}}>{"/"}</div>
                <div style={{color: "#EE642A", cursor: "pointer"}}>{"My Billing"}</div>
            </div> */}
            <List
                title={<div className="list_title_custom">{t("Billing")}</div>}
                headerProps={{extra:<></>}}
                breadcrumb={false}
            >
                <BillinghisPage />
            </List>
        </div>
    )
}