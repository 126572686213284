import { Button, Modal } from "antd"
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import "./styles.css";
import { useTranslate } from "@refinedev/core";

interface IMD {
    open?: any,
    setopen?: any
    type?: "success" | "error"
    title?: string
    description?: any
    prop?: any
    propAllow?: boolean
    footerButton?: "1btn" | "2btn"
    afterSubmit: (value: any) => void
    oktxt?: string
    canceltxt?: string
}

export const ModalConfirm: React.FC<IMD> = ({
    open,
    setopen,
    type,
    title,
    description,
    prop,
    propAllow = false,
    footerButton = "2btn",
    afterSubmit,
    oktxt = "Confirm",
    canceltxt = "Cancel"
}) => {

    const t = useTranslate();
    
    function iconContent(type: any){
        let icon: any
        if(type == "success"){icon = <CheckOutlined className="icon_success"/>}
        else if(type == "error"){icon = <WarningOutlined className="icon_error"/>}
        
        return <div className={type == "success" ? "cc_success": "cc_error"}>{icon}</div>
    }

    function onClickBTN(val: "submit" | "cancel"){
        afterSubmit(val);
        setopen(false);
    }

    return(
        <Modal
            open={open}
            closeIcon={false}
            zIndex={999999999}
            footer={<></>}
            width={"30%"}
        >
            <div className="layout_mdconfirm" style={{margin: "2.5rem 5px", padding: "0 2rem"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "2.5rem"}}>{iconContent(type)}</div>
                <div className="title_mdconfirm"><span className={`${type}`}>{title}</span></div>
                <div className="desc_mdconfirm"><span style={{color: "#637381"}}>{description}</span></div>
                {propAllow == true &&
                    <div className="md_txt" style={{marginTop: "1rem"}}>
                        {prop}
                    </div>
                }
                <div style={{marginTop: "3rem"}}>
                    {footerButton == "1btn" ?
                        <div style={{display: "flex", justifyContent: "center"}}><Button style={{width: "50%", height: "50px"}} type="primary" className="btn_normal_color" onClick={() => onClickBTN("submit")} size="large">{t(oktxt)}</Button></div>
                    :
                        <div style={{display: "flex", gap: "10px"}}>
                            <Button style={{width: "50%", height: "50px", letterSpacing: "1px"}} onClick={() => onClickBTN("cancel")} size="large">{t(canceltxt)}</Button>
                            <Button style={{width: "50%", height: "50px", letterSpacing: "1px"}} type="primary" className="btn_normal_color" onClick={() => onClickBTN("submit")} size="large">{t(oktxt)}</Button>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}