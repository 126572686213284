import { useNavigation } from "@refinedev/core";
import { DOMAIN_URL } from "../../../constants"

interface IDT {
    title: string,
    is_subtitle: boolean,
    subtitle: string
    pathURL?: string
}

export const PHcustom: React.FC<IDT> = ({
    title,
    is_subtitle,
    subtitle,
    pathURL
}) => {
    const { push } = useNavigation();
    
    return (
        <div className="pageHeader_custom">
            <div style={{fontSize: "26px", fontWeight: 500}}>
                {/* {`${header}` + dataPD?.order_number} */}
                <span className="title">{`${title}`}</span>
                {is_subtitle == true &&
                    <span className="sub-title">{`${subtitle}`}</span>
                }
            </div>
            <div style={{cursor: "pointer", color: "#EE642A"}} onClick={() => push(`/${pathURL}`)}>{"Back to Previous page"}</div>
        </div>
    )
}