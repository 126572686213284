import { Authenticated, GitHubBanner, Refine, WelcomePage } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import routerBindings, {
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import routerProvider, { CatchAllNavigate } from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import "./App.css";
import { LoginPage } from "pages/login";
import { ProductConfigPage, ProductCreate, ProductList, ProductdetailPage, ProductlistPage } from "pages/product";
import { authProvider } from "authProvider";
import { AuthPage, RefineThemes, ThemedLayoutV2, ThemedTitleV2, ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import { ConfigProvider } from "antd";
import { Footer, Header, Sider } from "./components/layout";
import BentoIcon from '@mui/icons-material/Bento';
import { AppIcon } from "components/app-icon";
import { BillingList } from "pages/billing";
import { Register } from "pages/register";
import { VerifyLINE } from "pages/verify";
import { VerifyPage } from "pages/verify/page";
import { HomePage } from "pages/home";
import { AccountList } from "pages/account";
import { LanddingPage } from "pages/landding";
import { ContactPage } from "pages/contact";
import { CartEditPage, CartPage, CartconfirmPage, CartdetailPage } from "pages/cart";
import { PayPage } from "pages/payment";
import { TermsoutAuthPage } from "pages/t&c/termoutauth";
import { AdminListPage, AdminVerifyPage } from "pages/admin";
import { ForgotpwdPage } from "pages/forgot-pwd";
import { AuthHelper } from "@pankod/refine-strapi-v4";
import { API_URL, TOKEN_KEY } from "./constants";
import axios from "axios";
import { InvoiceAdminPage } from "pages/admin/invoice";

function App() {
  
  const strapiAuthHelper = AuthHelper(API_URL + "/api");
  const titleHandler: any = ({ resource, action, params }: { resource: string; action: string; params: { id: string } }): string => {
    let title = "Cloud Management"; // Default title
  
    return title;
  };

  return (
    <BrowserRouter>
      <GitHubBanner />
      <RefineKbarProvider>
        {/* <DevtoolsProvider> */}
          <Refine
            authProvider={authProvider}
            routerProvider={routerBindings}
            dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: false,
              useNewQueryKeys: true,
              projectId: "wBB9wM-vTF22S-nxpHVn",
            }}
            resources={[
              {
                name: "products",
                list: "/products",
                // create: "/products/create",
                // meta: {
                //   icon: <BentoIcon />,
                //   label: "Product"
                // }
              },
              {
                name: "pn-admin",
                list: "/pn-admin",
                // create: "/products/create",
                // meta: {
                //   icon: <BentoIcon />,
                //   label: "Product"
                // }
              },
            ]}
          >
            <DocumentTitleHandler handler={titleHandler}/>
            <Routes>
              <Route
                  element={
                      <Authenticated
                          key="authenticated-routes"
                          fallback={
                              <CatchAllNavigate to="/login" />
                          }
                      >
                          <ThemedLayoutV2
                            Header={() => <Header sticky />}
                            Sider={Sider}
                            Title={AppIcon}
                          >
                              <Outlet />
                          </ThemedLayoutV2>
                      </Authenticated>
                  }
              >
                <Route path="/products">
                  <Route index element={<ProductList/>} />
                  <Route path="list" element={<ProductlistPage/>} />
                  <Route path="overviews/:id" element={<ProductdetailPage/>} />
                  <Route path="product-config/:id" element={<ProductConfigPage/>} />
                </Route>
                <Route path="/billing" element={<BillingList/>} />
                <Route path="/account" element={<AccountList/>} />
                <Route path="/verify" element={<VerifyLINE />} />
                <Route path="/cart/:id" element={<CartPage />} />
                <Route path="/cart/cart-confirm/:id" element={<CartconfirmPage />} />
                <Route path="/cart/cart-detail/:id" element={<CartdetailPage />} />
                <Route path="/cart/payment/:id" element={<PayPage />} />
                <Route path="/cart/edit-config/:id" element={<CartEditPage />} />
                <Route path="/pn-admin" element={<AdminListPage />} />
                <Route path="/pn-admin/verify" element={<AdminVerifyPage />} />
                <Route path="/pn-admin/invoice" element={<InvoiceAdminPage />} />
              </Route>

              <Route
                  element={
                      <Authenticated
                          key="auth-pages"
                          fallback={<Outlet />}
                      >
                          <NavigateToResource resource="posts" />
                      </Authenticated>
                  }
              >
                  <Route index element={<LanddingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-pwd" element={<ForgotpwdPage />} />
                  <Route path="/verify" element={<VerifyLINE />} />
                  <Route path="/verify/page" element={<VerifyPage />} />
                  <Route path="/contact" element={<ContactPage />} />
              </Route>

              <Route
                  element={
                      <Authenticated key="catch-all">
                          <ThemedLayoutV2>
                              <Outlet />
                          </ThemedLayoutV2>
                      </Authenticated>
                  }
              >
                  <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
          {/* <DevtoolsPanel /> */}
        {/* </DevtoolsProvider> */}
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;