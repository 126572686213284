import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core"
import { DOMAIN_URL, SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Spin, notification } from "antd";
import { axiosInstance } from "@refinedev/strapi-v4";
import { SearchOutlined, CheckOutlined, WarningOutlined } from '@ant-design/icons';

let nf = new Intl.NumberFormat('en-US');
export const ProductConfigPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const [form] = Form.useForm<any>();

    const USER: any = localStorage.getItem("USER");
    const USERID: any = localStorage.getItem("USERID");
    const { data: user } = useGetIdentity<any>();

    const { push } = useNavigation();

    const [modal, contextHolder] = Modal.useModal();
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [tabm, settabm] = useState<any>("tab1");
    const [dataPD, setdataPD] = useState<any>();
    const [totalSum, settotalSum] = useState<any>();
    const [selBilling, setselBilling] = useState<any>();
    const [selOs, setselOs] = useState<any>();
    const [selCtrl, setselCtrl] = useState<any>();
    const [isAddon, setAddon] = useState<boolean>(false);

    const [optionBilling, setoptionBilling] = useState<any>();
    const [optionOs, setoptionOs] = useState<any>();
    const [optionCtrl, setoptionCtrl] = useState<any>();
    const [checkHN, setcheckHN] = useState<any>("SEARCH");

    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[5];

    const { data: dataProduct, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/product/findOne`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: pageTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataPD((pre: any) => dataLoad);
                settotalSum((pre: any) => Number(dataLoad?.price));
                setoptionBilling((pre: any) => [
                    {
                        value: '65cc79ddbc033da8854bc9a8',
                        label: `${nf?.format(dataLoad?.price)} THB Monthly`
                    },
                    {
                        value: '65cc79e2bc033da8854bc9aa',
                        label: `${nf?.format(calculateTotalPrice(dataLoad?.price, 6, 0.1))} THB Semi-Annually`
                    },
                    {
                        value: '65cc79e7bc033da8854bc9ac',
                        label: `${nf?.format(calculateTotalPrice(dataLoad?.price, 12, 0.2))} THB Annually`
                    }
                ]);
                setselBilling((pre: any) => ({
                    value: '65cc79ddbc033da8854bc9a8',
                    label: `฿${nf?.format(dataLoad?.price)} THB Monthly`
                }));
                setLoading(false)
            },
        },
    });

    const { data: dataOption} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/productdetail/findAll`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            onSuccess(data) {
                let dataOs: any = data?.data?.filter((f: any) => f?.type == "8" && f?.is_active == true)?.map((item: any) => {return {
                    value: item?._id,
                    label: item?.name_en
                }});
                let dataCtrl: any = data?.data?.filter((f: any) => f?.type == "9" && f?.is_active == true)?.map((item: any) => {return {
                    value: item?._id,
                    label: item?.name_en
                }});

                setoptionOs((pre: any) => dataOs);
                setoptionCtrl((pre: any) => dataCtrl);
            },
        },
    });

    const calculateTotalPrice = (price:any, multiply:any, discount:any) => {
        const totalPrice = price * multiply;
        const discountedPrice = totalPrice - (totalPrice * discount);
        return discountedPrice;
    };

    const failNoti = (type: any) => {
        notification['error']({
            message: "Faild",
            description: type == "HN" ? "ชื่อ Host name ถูกใช้แล้วภายในระบบ"
                :type == "IP" ? "กรุณากรอกชื่อ Host name ที่ต้องการ"
                :type == "CF" && "กรุณาตรวจสอบ Host name",
            duration: 3,
        });
    };

    function changeBilling(val: any){
        if(val?.value == "65cc79ddbc033da8854bc9a8"){
            settotalSum((pre: any) => Number(dataPD?.price));
            setselBilling((pre: any) => ({
                value: val?.value,
                label: val?.label
            }));
        }else if(val?.value == "65cc79e2bc033da8854bc9aa"){
            settotalSum((pre: any) => calculateTotalPrice(dataPD?.price, 6, 0.1));
            setselBilling((pre: any) => ({
                value: val?.value,
                label: val?.label
            }));
        }else if(val?.value == "65cc79e7bc033da8854bc9ac"){
            settotalSum((pre: any) => calculateTotalPrice(dataPD?.price, 12, 0.2));
            setselBilling((pre: any) => ({
                value: val?.value,
                label: val?.label
            }));
        }
    }

    function changeOs(val: any){
        setselOs({
            id: val?.value,
            label: val?.label
        })
    }

    function checkHost(){
        // host_name
        let hostn: any = form?.getFieldValue(["host_name"]);

        if(hostn?.length > 0 && hostn !== undefined && hostn !== ""){
            axiosInstance.get(`${SOURCE_URL}/api/v1/transaction/checkDupHostname?host_name=${String(hostn)}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
            )
            .then((response) => {
                let res = response?.data;
                if(res?.can_create == true){
                    setcheckHN("SUCCESS");
                }else if(res?.can_create == false){
                    failNoti("HN");
                    setcheckHN("WARN");
                }
            })
            .catch(error => {

            });
        }else{
            failNoti("IP")
        }
    }

    function nfformat (num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    return (
        <Spin spinning={isLoading}>
            <Form
                layout="vertical"
                form={form}
                onFinish={async (values: any) => {
                    if(checkHN == "SUCCESS"){
                        setLoading(true);
                        axiosInstance.post(`${SOURCE_URL}/api/v1/transaction/create`,
                            {
                                select_product: pageTOKEN,
                                product_obj: {
                                    id: dataPD?._id,
                                    name: dataPD?.name_th,
                                    name_en: dataPD?.name_en,
                                    os: selOs,
                                    ctrl_panel: selCtrl
                                },
                                billing_cycle: selBilling,
                                host_name: values?.host_name,
                                paid_status: "3",
                                is_addon: isAddon,
                                user_profile: {
                                    id: user?.user_profile?.id,
                                    first_name: user?.user_profile?.first_name,
                                    last_name: user?.user_profile?.last_name
                                },
                                user: {
                                    id: user?.id,
                                    username: user?.name,
                                    email: user?.email
                                },
                                create_by: {
                                    user_id: user?.id,
                                    user_name: user?.name,
                                    email: user?.email
                                }
                            },
                            { headers: { Authorization: `Bearer ${TOKEN}` } }
                            )
                        .then((response) => {
                            let res = response?.data;
                            window.location.href = `${DOMAIN_URL}/cart/${res?.trans_id}`;
                        })
                        .catch(error => {
    
                        });
                    }else if(checkHN !== "SUCCESS"){
                        failNoti("CF");
                    }
                }}
            >
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "1480px"}}>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={15}>
                                <Card style={{marginBottom: "1.5rem"}}>
                                    <div style={{color: "#FF9300", fontSize: "20px", fontWeight: "500"}}>
                                        {dataPD?.name_en}
                                    </div>

                                    <Divider />

                                    <Row gutter={48}>
                                        <Col className="gutter-row" span={12}>
                                        <img style={{width: "100%"}} src={`${DOMAIN_URL}/images/svg/server_img.svg`} alt="IMAGE"/>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Row gutter={[12, 12]}>
                                                {dataPD?.product_detail?.length > 0 && dataPD?.product_detail?.map((item: any, index: any) => {
                                                    return (
                                                    <>
                                                        <Col className="gutter-row" span={7}>
                                                            <div style={{fontWeight: "bold", color: "#606060"}}>{item?.desc}</div>
                                                        </Col>
                                                        <Col className="gutter-row" span={1}>
                                                            <div style={{color: "#606060"}}>:</div>
                                                        </Col> 
                                                        <Col className="gutter-row" span={16}>
                                                            <div style={{color: "#606060"}}>{item?.name_en}</div>
                                                        </Col>
                                                    </>
                                                    )
                                                })}
                                            </Row>

                                            <div style={{paddingLeft: "0px", marginTop: "1rem", color: "#606060"}}>
                                                <div>
                                                    <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                                                    <span>Plesk</span>
                                                </div>
                                                <div>
                                                    <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                                                    <span>Additional IP</span>
                                                </div>
                                                <div>
                                                    <span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>
                                                    <span>Free Trial Contact info@nueamek.com</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Divider />

                                    <Row style={{marginBottom: "1rem"}}>
                                        <Col className="gutter-row" span={16}>
                                            <Row gutter={[24, 24]}>
                                                <Col className="gutter-row" span={8}>
                                                    <div style={{marginTop: "5px"}}>Choose billing cycle:</div>
                                                </Col>
                                                <Col className="gutter-row" span={16}>
                                                    <Form.Item 
                                                        label=""
                                                        name="billing_cycle"
                                                        style={{marginBottom: 0}}
                                                        className="disable_txt"
                                                    >
                                                        <Select defaultValue={"65cc79ddbc033da8854bc9a8"} options={optionBilling} onChange={(_, e) => changeBilling(e)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" span={8}>
                                                    <div style={{marginTop: "5px"}}>Hostname <span style={{color: "#FF5353"}}>*</span></div>
                                                </Col>
                                                <Col className="gutter-row" span={16}>
                                                    <Row gutter={[6, 12]}>
                                                        <Col className="gutter-row" span={21}>
                                                            <Form.Item 
                                                                label=" "
                                                                name="host_name"
                                                                style={{marginBottom: 0}}
                                                                className="disable_txt"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please enter Hostname"
                                                                    },
                                                                ]}
                                                            >
                                                                <Input onChange={() => {if(checkHN !== "SEARCH"){setcheckHN("SEARCH")}}}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className="gutter-row" span={3} style={{display: "flex", justifyContent: "end"}}>
                                                            <Button
                                                                className={`${
                                                                    checkHN == "SEARCH" ? "btn_check_host_search"
                                                                    :checkHN == "WARN" ? "btn_check_host_warn"
                                                                    :checkHN == "SUCCESS" ? "btn_check_host_success"
                                                                    :""
                                                                }`}
                                                                icon={
                                                                    checkHN == "SEARCH" ? <SearchOutlined style={{fontSize: 12}}/>
                                                                    :checkHN == "WARN" ? <WarningOutlined  style={{fontSize: 12}}/>
                                                                    :checkHN == "SUCCESS" && <CheckOutlined style={{fontSize: 12}}/>
                                                                } 
                                                                onClick={() => checkHost()}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="gutter-row" span={8}>
                                                    <div style={{marginTop: "5px"}}>OS Template <span style={{color: "#FF5353"}}>*</span></div>
                                                </Col>
                                                <Col className="gutter-row" span={16}>
                                                    <Form.Item 
                                                        label=" "
                                                        name="os_server"
                                                        style={{marginBottom: 0}}
                                                        className="disable_txt"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select Os-server"
                                                            },
                                                        ]}
                                                    >
                                                        <Select options={optionOs} onChange={(_ , e) => changeOs(e)}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div style={{color: "#F48515"}}>(Windows ยังไม่รวมค่า License)</div>
                                    <div style={{color: "#F48515"}}>(Pleskยังไม่รวมค่า License)</div>
                                </Card>
                            </Col>
                            <Col className="gutter-row" span={9}>
                                <Card>
                                    <div style={{color: "#FF9300", fontSize: "20px", fontWeight: "500"}}>
                                        Cart Summary
                                    </div>

                                    <Divider />

                                    <Row gutter={[24,6]}>
                                        <Col className="gutter-row" span={24}>
                                            <div>{dataPD?.name_en}</div>
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            <div>Setup Fee</div>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            <div style={{textAlign: "right"}}>0.00 THB</div>
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            <div>Billing Cycle Price</div>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            <div style={{textAlign: "right"}}>{nfformat(totalSum)}</div>
                                        </Col>
                                    </Row>
                                    <Divider />
                                        <div style={{textAlign: "right", color: "#AFAFAF"}}>CART SUBTOTAL TODAY:</div>
                                        <div style={{textAlign: "right", fontSize: "32px", fontWeight: "bold"}}>{nfformat(totalSum)}</div>
                                    <Divider />
                                    <div>
                                        <Button 
                                            size="large" 
                                            type="primary" 
                                            style={{
                                                width: "100%",
                                                height: "50px"
                                            }}
                                            onClick={() => form?.submit()}
                                            className="btn_normal_color fx_fadeout"
                                        >
                                            {t("Continue")}
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </Spin>
    )
}