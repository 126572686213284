import "./styles.css";
import { Col, ConfigProvider, Row, Segmented, List as AntdList, Card, Divider, Button, CollapseProps, Collapse, Layout } from "antd";
import { DOMAIN_URL, SOURCE_URL } from "../../constants";
import { useState } from "react";
import { HeadernoAuth } from "components/unAuth/header"
import { FooternoAuth } from "components/unAuth/footer";
import { useCustom, useNavigation } from "@refinedev/core";

let dtProduct: any = [];
let nf = new Intl.NumberFormat('en-US');
export const LanddingPage: React.FC = () => {

    const { push } = useNavigation();

    const { data: dataProduct, refetch: refetchProduct } = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/product/findAll`,
        method: "get",
        // config:{
        //     headers: { Authorization: `Bearer ${TOKEN}` }
        // },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                let newDT: any = [];
                for (let i = 0; i < dataLoad?.length; i++) {  
                    newDT.push({
                        id: dataLoad[i]?._id,
                        name: dataLoad[i]?.name_th,
                        name_en: dataLoad[i]?.name_en,
                        price: Number(dataLoad[i]?.price),
                        is_active: dataLoad[i]?.is_active,
                        type: dataLoad[i]?.type,
                        service: []
                    });

                    if(dataLoad[i]?.product_detail?.length > 0){
                        let dtservice: any =[];

                        for (let ix = 0; ix < dataLoad[i]?.product_detail?.length; ix++) {
                            dtservice?.push({
                                id: dataLoad[i]?.product_detail[ix]?.id,
                                name: dataLoad[i]?.product_detail[ix]?.name_th,
                                name_en: dataLoad[i]?.product_detail[ix]?.name_en,
                                desc: dataLoad[i]?.product_detail[ix]?.description
                            })
                        }

                        newDT[i].service = dtservice;
                    }
                }

                if(newDT?.length > 0){ 
                    dtProduct = newDT;
                    // onLoading();
                }
            },
        },
    });

    let windowDT: any = [
        {
            id: 1,
            product_name: "VPS-WG1XS+",
            price: 325,
            spec: {
                cpu: "2 Core CPU",
                ram: "3 GB RAM",
                ssd: "30 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 2,
            product_name: "VPS-WG2XS+",
            price: 650,
            spec: {
                cpu: "3 Core CPU",
                ram: "6 GB RAM",
                ssd: "60 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 3,
            product_name: "VPS-WG1SS+",
            price: 910,
            spec: {
                cpu: "4 Core CPU",
                ram: "12 GB RAM",
                ssd: "100 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 4,
            product_name: "VPS-WG2SS+",
            price: 1560,
            spec: {
                cpu: "6 Core CPU",
                ram: "24 GB RAM",
                ssd: "200 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },{
            id: 5,
            product_name: "VPS-WG3SS+",
            price: 2210,
            spec: {
                cpu: "8 Core CPU",
                ram: "36 GB RAM",
                ssd: "300 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 6,
            product_name: "VPS-WG4SS+",
            price: 2860,
            spec: {
                cpu: "10 Core CPU",
                ram: "48 GB RAM",
                ssd: "400 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 7,
            product_name: "VPS-WG5SS+",
            price: 3510,
            spec: {
                cpu: "12 Core CPU",
                ram: "60 GB RAM",
                ssd: "500 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
    ]

    let linuxDT: any = [
        {
            id: 1,
            product_name: "VPS-LG1XS+",
            price: 195,
            spec: {
                cpu: "2 Core CPU",
                ram: "3 GB RAM",
                ssd: "30 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 2,
            product_name: "VPS-LG2XS+",
            price: 390,
            spec: {
                cpu: "3 Core CPU",
                ram: "6 GB RAM",
                ssd: "60 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 3,
            product_name: "VPS-LG1SS+",
            price: 650,
            spec: {
                cpu: "4 Core CPU",
                ram: "12 GB RAM",
                ssd: "100 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 4,
            product_name: "VPS-LG2SS+",
            price: 1040,
            spec: {
                cpu: "6 Core CPU",
                ram: "24 GB RAM",
                ssd: "200 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },{
            id: 5,
            product_name: "VPS-LG3SS+",
            price: 1430,
            spec: {
                cpu: "8 Core CPU",
                ram: "36 GB RAM",
                ssd: "300 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 6,
            product_name: "VPS-LG4SS+",
            price: 1820,
            spec: {
                cpu: "10 Core CPU",
                ram: "48 GB RAM",
                ssd: "400 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
        {
            id: 7,
            product_name: "VPS-LG5SS+",
            price: 2210,
            spec: {
                cpu: "12 Core CPU",
                ram: "60 GB RAM",
                ssd: "500 GB SSD",
                speed: "1 Gbps Shared",
                ip: "1 IP Address",
                transfer: "Unlimited Transfer",
            }
        },
    ]

    const faq1 = `
    If you or your customers are located in Asia, hosting your VPS in Singpore will ensure low-latency network access to your server
    and a local IP address. This will translate to a better experience for you and your customers. Our low cost Linux & Windows VPS
    in Singapore are well connected with all major Asian hubs of business and internet, including these in China, Indonesia, India,
    Japan, and South Korea.`;

    const faq2 = ``;

    const faq3 = ``;

    const items: CollapseProps['items'] = [
    {
        key: '1',
        label: 'Virtual Private Server (VPS) คืออะไร ?',
        children: <p className="txt">{"VPS คือเครื่อง server ส่วนตัวเสมือน เป็นเครื่อง Host สำหรับ  software และ data ทั้งหมดที่จำเป็นในการเรียกใช้ Application หรือ Website โดยใช้ทรัพยากรทางกายภาพพื้นฐานของ server เพียงบางส่วน ซึ่งคุณสามารถเข้าถึงทรัพยากรเฉพาะของคุณบน hardware นั้นได้"}</p>,
    },
    {
        key: '2',
        label: 'ทำไมจึงควรเลือก VPS ?',
        children: <p className="txt">{"VPS เป็นเครื่อง server ที่มีราคาประหยัด เหมาะอย่างยิ่งสำหรับธุรกิจขนาดเล็กและกลาง และบุคคลทั่วไป โดยไม่ต้องกังวลเรื่อง downtime สามารถใช้งานได้ตามสภาพแวดล้อมที่เหมาะสม มีประสิทธิภาพและปลอดภัย"}</p>,
    },
    {
        key: '3',
        label: 'ใช้ VPS ทำอะไรได้บ้าง ?',
        children: <div>
            <div>เราสามารถใช้ VPS ได้กับงานหลากหลายประเภท ได้แก่</div>
            <div><span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>notification service</div>
            <div><span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>test environment server</div>
            <div><span style={{marginRight: "1rem", fontWeight: "bold"}}>•</span>optional storage</div>
        </div>,
    },
    {
        key: '4',
        label: 'VPS Hosting ต่างกับ Hosting ชนิดอื่นอย่างไร ?',
        children: <p className="txt">{"VPS hosting เป็นการเช่า Hosting ที่ใช้ทรัพยากรเฉพาะของคุณเอง โดยสามารถอัพเกรดได้ตามความต้องการในการใช้งาน ในขณะที่ Shared Hosting และ Dedicated Hosting ที่มีการแชร์ทรัพยากรเครื่องในการใช้งาน และแชร์ IP Address"}</p>,
    },
    {
        key: '5',
        label: 'VPS Hosting ปลอดภัยหรือไม่ ?',
        children: <p className="txt">{"VPS hosting มีความปลอดภัย ซึ่งมีการจัดการแยกกันในแต่ละเครื่อง คุณสามารถเพิ่มความปลอดภัยอีกขั้นด้วยการเพิ่ม firewall, โปรแกรม Antivirus, และโปรแกรมอื่นๆ ได้บนเครื่องของคุณ เพื่อป้องกันการโจมตีจากระบบอื่น"}</p>,
    },
    {
        key: '6',
        label: 'มีบริการติดตั้ง software หรือไม่ ?',
        children: <p className="txt">{"ทางเราสามารถติดตั้ง software ที่เป็น freewares ให้ได้ โดยลูกค้าเป็นผู้เตรียม software ที่ต้องการมาให้ทางเรา อย่างไรก็ตาม หากต้องการข้อมูลส่วนใดเพิ่มเติม เราพร้อมตรวจสอบและให้คำแนะนำ Solution ที่ดีและเหมาะสมกับงานของลูกค้ามากที่สุด"}</p>,
    },
    ];

    const [tabm, settabm] = useState<any>("windows")

    const renderItem = (item: any) => {
        const {
            id,
            name,
            price,
            service,
        }: any = item;

        return (
            <AntdList.Item 
                key={id} 
            >
                <AntdList.Item>
                    <Card
                        bordered={true}
                        className="card_no_padding"
                        style={{borderRadius: "10px"}}
                    >
                        <div className="head_product_list_noauth">
                            <div>{name}</div>
                        </div>
                        <div className="sc_head_product_list_noauth">
                            {nf?.format(price) + "฿ / เดือน"}
                        </div>
                        {/* <div className="body_product_list_noauth">
                            <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{spec?.cpu}</div>
                            <Divider style={{margin: "10px 0"}}/>
                            <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{spec?.ram}</div>
                            <Divider style={{margin: "10px 0"}} />
                            <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{spec?.ssd}</div>
                            <Divider style={{margin: "10px 0"}} />
                            <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{spec?.speed}</div>
                            <Divider style={{margin: "10px 0"}} />
                            <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{spec?.ip}</div>
                            <Divider style={{margin: "10px 0"}} />
                            <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{spec?.transfer}</div>
                        </div> */}
                        <div className="body_product_list">
                            {service?.length > 0 && service?.map((item: any, index: any) => {
                                return (
                                    <div>
                                        <div style={{color: "#898989", fontSize: "16px", textAlign: "center"}}>{item?.name}</div>
                                        {service?.length -1 !== index && <Divider style={{margin: "10px 0"}}/>}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="footer_product_list_noauth">
                            <Button
                                className="btn_sl_noauth"
                                type="primary"
                                size="large"
                                style={{
                                    width: "100%",
                                    color: "#fff",
                                    boxShadow: "unset"
                                }}
                                onClick={() => push(`/login?${id}`)}
                            >
                                ซื้อสินค้า
                            </Button>
                        </div>
                    </Card>
                </AntdList.Item>
            </AntdList.Item>
        )
    }
    
    return (
        // <div className="layout_noauth">
        <Layout className="layout_noauth">
            <div className="body_noauth">
                <HeadernoAuth sticky/>
                <div className="banner_header_noauth">
                    <div className="container_landding_noauth">
                        <Row gutter={12} className="fadeup_animations">
                            <Col className="gutter-row" span={14}>
                                <div className="banner_header_noauth_h3_org">VPS and Dedicated</div>
                                <div className="banner_header_noauth_h3_gry" style={{lineHeight: "3rem", marginBottom: "2.8rem"}}>Servers in Asia</div>
                                <div className="banner_header_noauth_txt_gry" style={{fontWeight: "500"}}>
                                    Cloud VPS, Cloud VDS and Bare Metal in Asia. High quality <br/>served directly. More CPU & RAM for less money
                                </div>

                                <div style={{marginTop: "2rem"}}>
                                    <Row gutter={[12, 12]}>
                                        <Col className="gutter-row" span={2}>
                                            <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/shield-icon.svg`} alt="ICON"/>
                                        </Col>
                                        <Col className="gutter-row" span={22}>
                                            <div className="banner_header_noauth_txt_gry" style={{fontWeight: "700"}}>
                                                Incredible Prices
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={2}>
                                            <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/shield-icon.svg`} alt="ICON"/>
                                        </Col>
                                        <Col className="gutter-row" span={22}>
                                            <div className="banner_header_noauth_txt_gry" style={{fontWeight: "700"}}>
                                                Global Availability
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={2}>
                                            <img style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/shield-icon.svg`} alt="ICON"/>
                                        </Col>
                                        <Col className="gutter-row" span={22}>
                                            <div className="banner_header_noauth_txt_gry" style={{fontWeight: "700"}}>
                                                High Quality
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={10} style={{display: "flex", justifyContent: "center"}}>
                                <img style={{width: "500px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/svg_1.svg`} alt="IMG"/>
                            </Col>
                        </Row>

                        <div className="fadeup_animations" style={{padding: "0 20px", marginTop: "4rem"}}>
                            <Row gutter={[12, 12]}>
                                <Col className="gutter-row" span={7} style={{display: "flex", justifyContent: "flex-end"}}>
                                    <div>
                                        <div style={{color: "#F48515", textAlign: "center",fontSize: "42px", fontWeight: 700, lineHeight: 1}}>100+</div>
                                        <div className="banner_header_noauth_txt_gry" style={{fontWeight: 700, textAlign: "center"}}>Customers</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={10}>
                                    <div>
                                        <div style={{color: "#F48515", textAlign: "center",fontSize: "42px", fontWeight: 700, lineHeight: 1}}>200+</div>
                                        <div className="banner_header_noauth_txt_gry" style={{fontWeight: 700, textAlign: "center"}}>Server Running</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={7} style={{display: "flex", justifyContent: "flex-start"}}>
                                    <div>
                                        <div style={{color: "#F48515", textAlign: "center",fontSize: "42px", fontWeight: 700, lineHeight: 1}}>3 Years</div>
                                        <div className="banner_header_noauth_txt_gry" style={{fontWeight: 700, textAlign: "center"}}>In Business</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="section_1_noauth">
                    <div className="container_landding_noauth" style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "70%"}} className="fadeup_animations">
                            <div style={{textAlign: "center", color: "#484848", fontSize: "2rem",fontWeight: 700}}>ใช้งานง่ายพร้อมระบบติดตั้งอัตโนมัติ</div>
                            <div style={{textAlign: "center", color: "#484848", fontSize: "17px",fontWeight: 500, marginTop: "1rem"}}>เราพัฒนา Planform ให้ใช้งานง่ายสำหรับผู้การเริ่มต้นใช้งาน Cloud VPS เพื่อรับประสบการณ์ที่ดีที่สุด</div>

                            <Row gutter={12} style={{marginTop: "5rem"}}>
                                <Col className="gutter-row" span={8}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <img style={{width: "100px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/easy-icon.svg`} alt="IMG"/>
                                    </div>
                                    <div style={{marginTop: "1.2rem", textAlign: "center", color: "#F48515", fontSize: "17px",fontWeight: 700}}>ติดตั้งง่ายดาย</div>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <img style={{width: "100px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/system-icon.svg`} alt="IMG"/>
                                    </div>
                                    <div style={{marginTop: "1.2rem", textAlign: "center", color: "#F48515", fontSize: "17px",fontWeight: 700}}>ปรับให้เสร็จสมบูรณ์</div>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <img style={{width: "100px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/upload-icon.svg`} alt="IMG"/>
                                    </div>
                                    <div style={{ marginTop: "1.2rem", textAlign: "center", color: "#F48515", fontSize: "17px",fontWeight: 700}}>Upload ไฟล์ง่ายดาย</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="section_2_noauth">
                    <div className="container_landding_noauth" style={{display: "flex", justifyContent: "center"}}>
                        <Row gutter={[1, 100]}>
                            <Col className="gutter-row" span={12}>
                                <img style={{width: "560px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/svg_3.svg`} alt="IMG"/>
                            </Col>
                            <Col className="gutter-row" span={12} style={{display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "7%"}}>
                                <div>
                                    <div style={{color: "#F48515", fontSize: "2rem",fontWeight: 700, marginBottom: "1rem"}}>การซัพพอร์ตที่รวดเร็ว</div>
                                    <div style={{fontWeight: 600, color: "#484848", fontSize: "16px"}}>
                                        เราเข้าใจดีบางครั้งปัญหาต้องได้รับการแก้ไขโดยเร็วคุณสามารถติดต่อ <br/>
                                        ซัพพอร์ตของเราผ่าน LINE Official ด้วยการ Live Chat ไม่จำเป็นต้องรอ <br/>
                                        Ticket หรือ Email อีกต่อไป <br/><br/>

                                        เราคือผู้เชี่ยวชาญ คุณสามารถปรึกษาเรา ได้หากไม่มั่นใจโดยเราซัพพอร์ต <br/>
                                        ในช่วงเวลา 9:00 – 01:00 ตามเวลาไทย
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={12} style={{display: "flex", alignItems: "center", paddingLeft: "5rem"}}>
                                <div>
                                    <div style={{color: "#F48515", fontSize: "2rem",fontWeight: 700, marginBottom: "1rem"}}>Smart Firewall</div>
                                    <div style={{fontWeight: 600, color: "#484848", fontSize: "16px"}}>
                                        เราพัฒนาระบบ Smart Firewall ช่วยป้องกันการโจมตีจาก Hacker <br/>
                                        หรือผู้ไม่หวังดีเพื่อรักษาความเป็นส่วนตัวสูงสุด โดยผู้ใช้ VPS Cloud <br/>
                                        ไม่จำเป็นต้องมีความรู้และไม่ต้องเสียค่าใช้จ่ายใด ๆเพิ่มเติม
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "center"}}>
                                <img style={{width: "350px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/svg_4.svg`} alt="IMG"/>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="section_main_noauth">
                    <div className="container_landding_noauth">
                        <div style={{color: "#F48515", fontSize: "2rem",fontWeight: 700, textAlign: "center"}}>เริ่มใช้งานเลย !</div>
                        {/* <ConfigProvider
                            theme={{
                                components: {
                                    Segmented: {
                                        itemSelectedBg: "#F48515",
                                    },
                                },
                            }}
                        >
                            <Segmented 
                                options={[
                                    {
                                        label: (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px"}}>
                                            <img className="icon" style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/windows-icon.svg`} alt="ICON"/>
                                            <div className="txt" style={{marginLeft: "10px"}}>Windows</div>
                                        </div>
                                        ),
                                        value: 'windows',
                                    },
                                    {
                                        label: (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px"}}>
                                            <img className="icon" style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/linux-icon.svg`} alt="ICON"/>
                                            <div className="txt" style={{marginLeft: "10px"}}>Linux</div>
                                        </div>
                                        ),
                                        value: 'linux',
                                    },
                                ]} 
                                block
                                size="large"
                                className="segment_custom_noauth"
                                style={{width: "300px"}}
                                onChange={(e: any) => settabm(e)}
                            />
                        </ConfigProvider> */}

                        <div className="layout_product_list_noauth">
                            <AntdList
                                rowKey={"id"}
                                grid={{ gutter: [12,32], xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
                                dataSource={dtProduct}
                                renderItem={renderItem}
                                style={{ position: "relative" }}
                                pagination={false}
                            />
                            {/* {
                                tabm == "windows" ?
                                    <div style={{marginTop: "3rem"}}>
                                        <AntdList
                                            rowKey={"id"}
                                            grid={{ gutter: [12,32], xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
                                            dataSource={dtProduct?.filter((f: any) => f?.type == "window")}
                                            renderItem={renderItem}
                                            style={{ position: "relative" }}
                                            pagination={false}
                                        />
                                    </div>
                                :tabm == "linux" && 
                                    <div style={{marginTop: "3rem"}}>
                                        <AntdList
                                            rowKey={"id"}
                                            grid={{ gutter: [12,32], xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
                                            dataSource={linuxDT}
                                            renderItem={renderItem}
                                            style={{ position: "relative" }}
                                            pagination={false}
                                        />
                                    </div>
                            } */}
                        </div>
                    </div>
                </div>

                <div className="section_faq_noauth" id="faq_panel" style={{padding: "30px"}}/>

                <div className="section_faq_noauth">
                    <div className="container_landding_noauth">
                        <div style={{color: "#484848", fontSize: "2rem",fontWeight: 700, textAlign: "center", marginBottom: "2rem"}}>FAQ</div>
                        <Collapse defaultActiveKey={['1']} items={items} expandIconPosition={"end"} size="large" ghost className="collapse_custom_noauth" style={{marginBottom: "1rem"}}/> 
                    </div>
                </div>
                <FooternoAuth />
            </div>
        </Layout>
    )
}