import { List } from "@refinedev/antd";
import { IResourceComponentsProps, useCustom, useNavigation, useTranslate } from "@refinedev/core";
import { Card, Col, ConfigProvider, Divider, Form, Row, Segmented, Spin, Tabs, notification } from "antd";
import { useEffect, useState } from "react";
import { ProfilePage } from "./profile";
import { PasswordPage } from "./reset-pwd";
import { API_URL, DOMAIN_URL, TOKEN_KEY } from "../../constants";
import { UserOutlined, SafetyOutlined } from '@ant-design/icons';
// import { BillinghisPage } from "./history";
// import { TopupPage } from "./top_up";

let countNoti: number = 0;
let dataUser: any = undefined;
export const AccountList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { TabPane } = Tabs;
    const { create, edit } = useNavigation();

    const [TabM, setTabM] = useState<any>("profile");
    const [isLoading, setLoading] = useState<boolean>(true);

    const USER: any = localStorage.getItem("USER");
    const USERID: any = localStorage.getItem("USERID");
    const ACCOUNT: any = localStorage.getItem("ACCOUNT");
    const PASSWORD: any = localStorage.getItem("PASSWORD");
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const PATH: any = localStorage.getItem("PATH");

    useEffect(() => {
        if(ACCOUNT == "T" && countNoti == 0){
            countNoti = countNoti + 1;
            successNoti();
            localStorage.removeItem("ACCOUNT");
        }
    }, [ACCOUNT])

    useEffect(() => {
        if(PASSWORD == "T" && countNoti == 0){
            countNoti = countNoti + 1;
            successNoti();
            localStorage.removeItem("PASSWORD");
        }
    }, [PASSWORD])

    const { data: dataProduct, refetch } = useCustom<any>({
        url: `${API_URL}/api/user-profiles/UserPermission`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: USER
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data[0]?.user_detail;
                dataUser = dataLoad;
                setLoading(false);
            },
        },
    });

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("แก้ไขข้อมูลของ User เสร็จสิ้น"),
            duration: 3
        });
    };
    
    return(
        <Spin spinning={isLoading}>
            <Card>
                <div style={{marginTop: "1rem"}}>
                    <div style={{fontSize: "2rem", fontWeight: "600", color: "#EE642A"}}>
                        {dataUser?.first_name == "" && dataUser?.last_name == "" ?
                            <>
                                <span style={{marginRight: "1rem"}}>{"User"}</span>
                                <span>{dataUser?.id}</span>
                            </>
                            :
                            <>
                                <span style={{marginRight: "1rem"}}>{dataUser?.first_name}</span>
                                <span>{dataUser?.last_name}</span>
                            </>
                        }
                        {/* {t("ACCOUNT")} */}
                    </div>
                    <div style={{fontSize: "15px", color: "#8a9099"}}><span>{dataUser?.user?.email}</span></div>
                </div>

                <Divider />

                <Row gutter={[6,24]} style={{marginBottom: "2rem"}}>
                    <Col className="gutter-row" span={4}>
                        <Form.Item name="" style={{marginBottom: 0}}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Segmented: {
                                            itemSelectedBg: "#ff8d00",
                                            itemSelectedColor: "#fff",
                                            algorithm: true,
                                        },
                                    },
                                }}
                            >
                                <Segmented 
                                    options={[
                                        {
                                            label: (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px"}}>
                                                {/* <img className="icon" style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/windows-icon.svg`} alt="ICON"/> */}
                                                <div><UserOutlined /></div>
                                                <div className="txt" style={{marginLeft: "10px"}}>User Profile</div>
                                            </div>
                                            ),
                                            value: 'profile',
                                        },
                                        {
                                            label: (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px"}}>
                                                {/* <img className="icon" style={{width: "30px"}} src={`${DOMAIN_URL}/images/svg/landding_svg/linux-icon.svg`} alt="ICON"/> */}
                                                <div><SafetyOutlined /></div>
                                                <div className="txt" style={{marginLeft: "10px"}}>
                                                    {PATH == null ? t("Change Password"): t("Set Password")}
                                                </div>
                                            </div>
                                            ),
                                            value: 'password',
                                        },
                                    ]} 
                                    block
                                    size="large"
                                    className="segment_custom_profile"
                                    style={{width: "400px"}}
                                    onChange={(e: any) => setTabM(e)}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </Col>
                </Row>

                {
                    TabM == "profile" ?
                        <ProfilePage />
                    :TabM == "password" &&
                        <PasswordPage />
                }
            </Card>
        </Spin>
    )
}