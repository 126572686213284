// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing_id_table {
    cursor: pointer;
    transition: 0.3s;
    font-weight: 400;
}

.billing_id_table:hover {
    color: #F48515;
    transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/pages/billing/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".billing_id_table {\n    cursor: pointer;\n    transition: 0.3s;\n    font-weight: 400;\n}\n\n.billing_id_table:hover {\n    color: #F48515;\n    transition: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
