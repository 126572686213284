// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_account {
    width: 550px;
    margin-bottom: 1rem;
}

.layout_account_pwd {
    width: 400px;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
    
}`, "",{"version":3,"sources":["webpack://./src/pages/account/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;;AAEA","sourcesContent":[".layout_account {\r\n    width: 550px;\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n.layout_account_pwd {\r\n    width: 400px;\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
