import { useNavigation } from "@refinedev/core";
import { DOMAIN_URL } from "../../constants"
import { Input, Select, Space } from "antd";
import phoneList from './world.json.custom/list.json'
import { useState } from "react";

interface ITF {
    defaultLocal?: string,
    selectWidth? : string
    popupWidth? : number,
    userPhone: any,
    setuserPhone: any,
    allowborderAlert?: boolean
    borderAlert?: boolean
    setborderAlert?: any
}

// CUSTOM BY JUUXO.DEV

export const CustomPhoneInput: React.FC<ITF> = ({
    defaultLocal = "TH",
    selectWidth = "60%",
    popupWidth = 330,
    userPhone,
    setuserPhone,
    allowborderAlert = false,
    borderAlert = false,
    setborderAlert
}) => {

    const [tk, settk] = useState<boolean>(true);

    function changePhone(e: any){
        settk(!tk);
        setuserPhone({
            value: e?.value,
            label: `${e?.value}  +${e?.short}`,
            short: e?.value,
            tel_no: userPhone?.tel_no,
        })
    }

    function changeNOPhone(e: any){
        if(e?.target?.value?.length > 0){
            let oldPhone: any = userPhone;
            oldPhone.tel_no = e?.target?.value;
            settk(!tk);
            setuserPhone((pre: any) => oldPhone);
            if(allowborderAlert == true){
                if(borderAlert == true){
                    setborderAlert(false);
                }
            }
        }else if(e?.target?.value?.length == 0){
            let oldPhone: any = userPhone;
            oldPhone.tel_no = "";
            settk(!tk);
            setuserPhone((pre: any) => oldPhone);
            if(allowborderAlert == true){
                setborderAlert(true);
            }
        }
    }

    return (
        <Space.Compact style={{width: "100%", borderRadius: "6px", boxShadow: allowborderAlert == true && borderAlert == true ? "rgb(255, 83, 83) 0px 0px 0px 1px" : "none"}}>
            <Select 
                defaultValue={defaultLocal} 
                options={(
                    phoneList?.map((item: any, key: any)=> {
                        return {
                            value: item?.code, 
                            label: <div><span style={{marginRight: "0.3rem", fontSize: "12px"}}>{item?.code}</span><span style={{marginRight: "0.3rem"}}>{item?.name}</span><span>{item?.dial_code}</span></div>, 
                            name: item?.name,
                            short: item?.dial_code, 
                            key: key
                        }
                    })
                )}
                showSearch
                filterOption={(input, option) => (option?.name.toLowerCase() ?? '').includes(input.toLowerCase()) || (option?.short.toLowerCase() ?? '').includes(input.toLowerCase()) || (option?.value.toLowerCase() ?? '').includes(input.toLowerCase())}
                style={{width: selectWidth}}
                onSelect={(_, e) => changePhone(e)}
                popupMatchSelectWidth={popupWidth}
                value={userPhone}
            />
            <Input
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                    }
                }}
                onChange={(e: any) => changeNOPhone(e)}
                value={userPhone?.tel_no}
            />
        </Space.Compact>
    )
}