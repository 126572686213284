import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core"
import { SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useEffect, useState } from "react";
import { Spin, Table, Tabs, Tag, Badge, TabsProps, ConfigProvider, Input, Button, Drawer, Form, DatePicker, Select, notification } from "antd";
import { List } from "@refinedev/antd";
import dayjs from "dayjs";
import { FormOutlined, PrinterOutlined } from '@ant-design/icons';
import { axiosInstance } from "@refinedev/strapi-v4";
import axios from "axios";

export const AdminListPage: React.FC<IResourceComponentsProps> = () => {

    let countFX: number = 0;
    const t = useTranslate();
    const format = 'YYYY-MM-DD';
    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const ADMIN_TABS: any = localStorage.getItem("ADMIN_TABS");
    const { data: user } = useGetIdentity<any>();
    const { push } = useNavigation();
    const [formSearch] = Form.useForm<any>();
    const [formExport] = Form.useForm<any>();
    const { RangePicker } = DatePicker;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [pageSize, setPageSize] = useState<any>(10);

    const [dtLoad, setdtLoad] = useState<any>([]);
    const [dtWAIT, setdtWAIT] = useState<any>([]);
    const [dtUNPAID, setdtUNPAID] = useState<any>([]);
    const [dtPAID, setdtPAID] = useState<any>([]);
    const [dtREJECT, setdtREJECT] = useState<any>([]);
    const [dtOVERDUE, setdtOVERDUE] = useState<any>([]);
    const [dtCOMPLETE, setdtCOMPLETE] = useState<any>([]);
    const [dtALLS, setdtALLS] = useState<any>([]);

    const [Tab, setTab] = useState<any>(ADMIN_TABS ? ADMIN_TABS: 'wait-approve');
    const [drawer, setdrawer] = useState<any>(false);
    const [tk, settk] = useState<boolean>(true);

    useEffect(() => {
        if(countFX == 0){
            countFX = countFX + 1;
            setTimeout(() => {
                localStorage.removeItem("ADMIN_TABS");
            }, 500);
        }
    }, [ADMIN_TABS])

    const { data: dataAPI, refetch: rfAPI} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/topup/getPaymentAndConf`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data?.data?.reverse();
                if(countFX == 1){countFX = 0}
                let test = dataLoad?.filter((item: any) => item?.create_by?.paid_status == "2")
                mergeData(dataLoad, "load");
            },
        },
    });

    const columns:any = [
        {
            title: 'Invoice No.',
            dataIndex: 'trans_id',
            key: 'trans_id',
            width: 200,
            sorter: (a:any, b:any) => a?.invoice_id?.localeCompare(b?.invoice_id),
            render: (value:any, record:any) => {
                return (
                    <span 
                        className="billing_id_table" 
                        onClick={() => {
                            localStorage?.setItem("ADMIN_TABS",Tab)
                            push(`/pn-admin/invoice?payment_id=${record?.id}&trans_id=${record?.trans_id}`);
                        }}
                    >
                        {record?.invoice_id ? record?.invoice_id : '-'}
                    </span>
                )
            },
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Customer Name.',
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 300,
            sorter: (a:any, b:any) => Tab == 'un_paid' ? a?.user_profile?.first_name?.localeCompare(b?.user_profile?.first_name) : a?.trans_obj?.billing_address?.first_name?.localeCompare(b?.trans_obj?.billing_address?.first_name),
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            Tab == 'un_paid' ? record?.user_profile?.first_name + " " + record?.user_profile?.last_name :
                            Tab == 'alls' ? record?.trans_obj?.billing_address ? record?.trans_obj?.billing_address?.first_name + " " + record?.trans_obj?.billing_address?.last_name : record?.user_profile?.first_name + " " + record?.user_profile?.last_name :
                            record?.trans_obj?.billing_address?.first_name + " " + record?.trans_obj?.billing_address?.last_name
                        }
                    </span>
                )
            },
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            sorter: (a:any, b:any) => a?.product_obj?.name_en?.localeCompare(b?.product_obj?.name_en),
            render: (value:any, record:any) => {
                return (<span style={{fontWeight: "500"}}>{record?.product_obj?.name_en}</span>)
            },
        },
        {
            title: 'Hostname',
            dataIndex: 'hostname',
            key: 'hostname',
            width: 200,
            sorter: (a:any, b:any) => a?.hostname?.localeCompare(b?.hostname),
            render: (value:any, record:any) => {
                return (<span>{record?.hostname}</span>)
            },
        },
        {
            title: 'Invoice date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            width: 200,
            sorter: (a:any, b:any) => a?.trans_obj?.invoice_date?.localeCompare(b?.trans_obj?.invoice_date),
            render: (value:any, record:any) => {
                return (<span>{record?.trans_obj ? dayjs(record?.trans_obj?.invoice_date).format("DD/MM/YYYY"): "-"}</span>)
            },
        },
        {
            title: 'Total (7%)',
            dataIndex: 'total',
            key: 'total',
            width: 200,
            sorter: (a:any, b:any) => a?.amount - b?.amount,
            render: (value:any, record:any) => {
                return (<span>{record?.amount ? nfformat(record?.amount) : '-'}</span>)
            },
        },
        {
            title: 'Note',
            dataIndex: 'note_reject',
            key: 'note-reject',
            width: 200,
            render: (value:any, record:any) => {
                return (<span>{record?.note ? record?.note : "-"}</span>)
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (value:any, record:any) => {
                return (
                    <span style={{color: 
                        record?.status === "1" ? "#FF76FA" :
                        record?.status === "2" ? "#F8A626" :
                        record?.status === "4" ? "#FF5252" :
                        record?.status === "5" ? "#0978F2" :
                        record?.status === "6" ? "#0EAD69" :
                        record?.status === "7" ? "#6D6D6D" : ""
                        ,
                        fontWeight: "500"
                    }}>
                        {record?.status == "1" ?
                            "Paid"
                        :record?.status == "2" ?
                            "Rejected"
                        :record?.status == "4" ?
                            "Unpaid"
                        : record?.status == "5" ?
                            "Wait for Approve"
                        : record?.status == "6" ?
                            "Completed"
                        : record?.status == "7" &&
                            "Overdue"
                        }
                    </span>
                )
            },
        },
        {
            title: ' ',
            dataIndex: 'actions',
            key: 'actions',
            width: 50,
            align: "center",
            render: (value:any, record:any) => {
                // 2 = failed,  หลังจาก "admin" กด "ยกเลิก"
                // 4 = wait for payment, หลังจาก "user" กด "checkout" มาแล้ว (!!แต่ยังไม่จ่ายเงิน)
                return (
                    record?.status == "2" || record?.status == "4" ?
                        <FormOutlined 
                            style={{
                                cursor: "not-allowed",
                                color: "#D6D6D6",
                                fontSize: "20px"
                            }} 
                        />
                    :
                        <FormOutlined 
                            style={{
                                cursor: "pointer",
                                color: "#A0A0A0",
                                fontSize: "20px"
                            }} 
                            onClick={() => {
                                localStorage?.setItem("ADMIN_TABS",Tab)
                                push(`/pn-admin/verify?payment_id=${record?.id}&trans_id=${record?.trans_id}`);
                            }}
                        />
                )
            },
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: 'wait-approve', //5 wait for approve, หลังจ่าย "user" กดจ่ายเงิน (จากนั้นจะส่งให้ "admin confirm")
            label: 
                <div>
                    <Tag color={Tab == 'wait-approve' ? "#F48515": "#D9D9D9"}>
                        {dtWAIT?.length > 99 ? "99+": dtWAIT?.length == 0 ? "0": dtWAIT?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Wait for approve'}
                    </span>
                </div>,
        },
        {
            key: 'un_paid', //4 wait for payment, หลังจาก "user" กด "checkout" มาแล้ว (!!แต่ยังไม่จ่ายเงิน)
            label: 
                <div>
                    <Tag color={Tab == 'un_paid' ? "#F48515": "#D9D9D9"}>
                        {dtUNPAID?.length > 99 ? "99+": dtUNPAID?.length == 0 ? "0": dtUNPAID?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Unpaid'}
                    </span>
                </div>,
        },
        {
            key: 'paid', //1 successful, หลังจาก "admin" กด "ยืนยัน"
            label: 
                <div>
                    <Tag color={Tab == 'paid' ? "#F48515": "#D9D9D9"}>
                        {dtPAID?.length > 99 ? "99+": dtPAID?.length == 0 ? "0": dtPAID?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Paid'}
                    </span>
                </div>,
        },
        {
            key: 'reject', //2 failed,  หลังจาก "admin" กด "ยกเลิก"
            label: 
                <div>
                    <Tag color={Tab == 'reject' ? "#F48515": "#D9D9D9"}>
                        {dtREJECT?.length > 99 ? "99+": dtREJECT?.length == 0 ? "0": dtREJECT?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Rejected'}
                    </span>
                </div>,
        },
        {
            key: 'overdue', //7 หลังจากสร้าง "invoice" เสร็จระยะเวลาเกิน 7 วันนับตั้งแต่วันแรกที่กด (หมดอายุ)
            label: 
                <div>
                    <Tag color={Tab == 'overdue' ? "#F48515": "#D9D9D9"}>
                        {dtOVERDUE?.length > 99 ? "99+": dtOVERDUE?.length == 0 ? "0": dtOVERDUE?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Overdue'}
                    </span>
                </div>,
        },
        {
            key: 'complete', //6 completed, หลังจากเครื่องเตรียมพร้อมใช้งาน(เสร็จ) หลังบ้านเปลี่ยนให้เอง
            label: 
                <div>
                    <Tag color={Tab == 'complete' ? "#F48515": "#D9D9D9"}>
                        {dtCOMPLETE?.length > 99 ? "99+": dtCOMPLETE?.length == 0 ? "0": dtCOMPLETE?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Completed'}
                    </span>
                </div>,
        },
        {
            key: 'alls', // 1-2-4-5-6-7
            label: 
                <div>
                    <Tag color={Tab == 'alls' ? "#F48515": "#D9D9D9"}>
                        {dtALLS?.length > 99 ? "99+": dtALLS?.length == 0 ? "0": dtALLS?.length}
                    </Tag>
                    <span style={{marginLeft: "5px"}}>
                        {'Alls'}
                    </span>
                </div>,
        },
    ];
    
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const exportfail = () => {
        notification['error']({
            message: "Faild",
            description: t("Transaction not Found"),
            duration: 3,
        });
    };

    function getUNPAID(data: any){
        axiosInstance.get(`${SOURCE_URL}/api/v1/transaction/findAll`, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((response) => {
            let dataLoad: any = response?.data.reverse();
            let unpaid: any = dataLoad?.filter((item: any) => item?.paid_status == "4");

            let dataUNPAID: any = regroupDT(unpaid, "alltrans") //UNPAID
            let dataUNPAIDM: any = regroupDT(unpaid, "mergetrans") //UNPAID MERGE
            
            
            let dataALLS: any = data;
            dataALLS?.push(...dataUNPAIDM)

            setdtUNPAID((pre: any) => dataUNPAID);
            setdtALLS((pre: any) => dataALLS);
            setdtLoad((pre: any) => dataALLS);

            settk(!tk)
            setLoading(false);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }
    
    function nfformat(num: any) {
        return new Intl.NumberFormat('th-TH', { 
          style: 'currency', 
          currency: "THB", 
          currencyDisplay: "code" 
        })
        .format(num)
        .replace("THB", "")
        .trim()+" THB";
    }

    function onSearch(){
        setLoading(true);

        setTimeout(() => {
            let values: any = formSearch?.getFieldsValue();
            let data: any = dtLoad;
            let invoice_no: any = values?.invoice_no && values?.invoice_no !== "" ? values?.invoice_no : undefined;
            let company_name: any = values?.company_name && values?.company_name !== "" ? values?.company_name : undefined;
            let invoice_date: any = values?.invoice_date && values?.invoice_date !== "" ? dayjs(values?.invoice_date).format(format) : undefined;

            let newDT: any = [];
            newDT = data?.filter((e:any) => 
                (invoice_no ? e?.invoice_id?.toUpperCase()?.includes(invoice_no?.toUpperCase()) : e?.invoice_id !== undefined)  && 
                (company_name ? e?.organization?.toUpperCase()?.includes(company_name?.toUpperCase()) || e?.user_profile?.first_name?.toUpperCase()?.includes(company_name?.toUpperCase()) : (e?.organization !== undefined || e?.first_name !== undefined)) &&
                (invoice_date ? dayjs(e?.trans_obj?.invoice_date).format(format) == invoice_date : e?.trans_obj?.invoice_date !== undefined)
            );

            mergeData(newDT, "search");
        }, 300);
    }

    function mergeData(data: any, mode: "load" | "search"){
        // !!status ในระบบ
        // 1 = successful, หลังจาก "admin" กด "ยืนยัน"
        // 2 = failed,  หลังจาก "admin" กด "ยกเลิก"
        // 3 = pending, หลังจาก "user" กดเพิ่มสินค้าใส่ตะกร้า 
        // 4 = wait for payment, หลังจาก "user" กด "checkout" มาแล้ว (!!แต่ยังไม่จ่ายเงิน)
        // 5 = wait for approve, หลังจ่าย "user" กดจ่ายเงิน (จากนั้นจะส่งให้ "admin confirm")
        // 6 = completed, หลังจากเครื่องเตรียมพร้อมใช้งาน(เสร็จ) หลังบ้านเปลี่ยนให้เอง
        // 7 = overdue, หลังจากสร้าง "invoice" เสร็จระยะเวลาเกิน 7 วันนับตั้งแต่วันแรกที่กด (หมดอายุ)
        // 0 = order remove, "ยังไม่มีในระบบ"

        if(mode == "load"){
            let alls: any = data;
            let paid: any = data?.filter((item: any) => item?.create_by?.paid_status == "1");
            let rejected: any = data?.filter((item: any) => item?.create_by?.paid_status == "2");
            let wait: any = data?.filter((item: any) => item?.create_by?.paid_status == "5");
            let completed: any = data?.filter((item: any) => item?.create_by?.paid_status == "6");
    
            let dataPAID: any = regroupDT(paid, "payment&conf") //PAID
            let dataREJECTED: any = regroupDT(rejected, "payment&conf") //REJECTED
            let dataWAIT: any = regroupDT(wait, "payment&conf") //WAIT FOR APPROVE
            let dataCOMPLETED: any = regroupDT(completed, "payment&conf") //COMPLETED
            let dataALLS: any = regroupDT(alls, "payment&conf") //COMPLETED
    
            setdtPAID((pre: any) => dataPAID);
            setdtREJECT((pre: any) => dataREJECTED);
            setdtWAIT((pre: any) => dataWAIT);
            setdtCOMPLETE((pre: any) => dataCOMPLETED);
            getUNPAID(dataALLS);
        }else if(mode == "search"){
            let alls: any = data;
            let paid: any = data?.filter((item: any) => item?.create_by?.paid_status == "1");
            let rejected: any = data?.filter((item: any) => item?.create_by?.paid_status == "2");
            let unpaid: any = data?.filter((item: any) => item?.status == "4")
            let wait: any = data?.filter((item: any) => item?.create_by?.paid_status == "5");
            let completed: any = data?.filter((item: any) => item?.create_by?.paid_status == "6");
    
            let dataPAID: any = paid //PAID
            let dataREJECTED: any = rejected //REJECTED
            let dataWAIT: any = wait //WAIT FOR APPROVE
            let dataCOMPLETED: any = completed //COMPLETED
            let dataALLS: any = alls //COMPLETED
            let dataUNPAID: any = unpaid //UNPAID
    
            setdtPAID((pre: any) => dataPAID);
            setdtREJECT((pre: any) => dataREJECTED);
            setdtWAIT((pre: any) => dataWAIT);
            setdtCOMPLETE((pre: any) => dataCOMPLETED);
            setdtALLS((pre: any) => dataALLS);
            setdtUNPAID((pre: any) => dataUNPAID);
            settk(!tk);
            setLoading(false);
        }
    }

    function regroupDT(data: any, from: "payment&conf" | "alltrans" | "mergetrans"){
        let newDT: any = [];
        if(from == "payment&conf"){
            for (let i = 0; i < data?.length; i++) {  
                newDT?.push({
                    key: data[i]?._id,
                    id: data[i]?._id,
                    invoice_id: data[i]?.create_by?.invoice_no,
                    trans_id: data[i]?.transaction_id,
                    amount: data[i]?.amount?.replace("THB","")?.replace(",", "")?.replace(" ", ""),
                    status: data[i]?.create_by?.paid_status,
                    user_profile: data[i]?.user_profile,
                    bank_obj: data[i]?.bank_obj,
                    pay_date: data[i]?.topup_timestamp,
                    trans_obj: data[i]?.create_by,
                    product_obj: data[i]?.create_by?.product_obj,
                    description: "Company Name : " + (data[i]?.create_by?.organization ? data[i]?.create_by?.organization : "-") + " , " + "Tel. " + data[i]?.create_by?.billing_address?.tel,
                    note: data[i]?.create_by?.reject_reason,
                    hostname: data[i]?.create_by?.host_name,
                    invoice_date: data[i]?.create_by?.invoice_date,
                    create_by: data[i]?.create_by,
                    organization: data[i]?.create_by?.organization ? data[i]?.create_by?.organization : "",
                });
            }
        }else if(from == "alltrans"){
            for (let i = 0; i < data?.length; i++) {  
                newDT?.push({
                    key: data[i]?._id,
                    id: data[i]?._id,
                    invoice_id: data[i]?.invoice_no,
                    trans_id: data[i]?._id,
                    amount: data[i]?.billing_cycle?.total_amount?.replace("THB","")?.replace(",", "")?.replace(" ", ""),
                    status: data[i]?.paid_status,
                    user_profile: data[i]?.user_profile,
                    product_obj: data[i]?.product_obj,
                    trans_obj: {
                        invoice_date: data[i]?.invoice_date,
                        account_type: data[i]?.account_type
                    },
                    description: "Company Name : " + (data[i]?.organization ? data[i]?.organization : "-") + " , " + "Tel. " + data[i]?.billing_address?.tel,
                    note: data[i]?.create_by?.reject_reason,
                    hostname: data[i]?.host_name,
                    invoice_date: data[i]?.invoice_date,
                    create_by: data[i]?.create_by,
                    organization: data[i]?.organization ? data[i]?.organization : "",
                });
            }
        }else if(from == "mergetrans"){
            for (let i = 0; i < data?.length; i++) {  
                newDT?.push({
                    key: data[i]?._id,
                    id: data[i]?._id,
                    invoice_id: data[i]?.invoice_no,
                    trans_id: data[i]?._id,
                    amount: data[i]?.billing_cycle?.total_amount?.replace("THB","")?.replace(",", "")?.replace(" ", ""),
                    status: data[i]?.paid_status,
                    user_profile: data[i]?.user_profile,
                    product_obj: data[i]?.product_obj,
                    trans_obj: {
                        invoice_date: data[i]?.invoice_date,
                        account_type: data[i]?.account_type
                    },
                    description: "Company Name : " + (data[i]?.organization ? data[i]?.organization : "-") + " , " + "Tel. " + data[i]?.billing_address?.tel,
                    note: data[i]?.create_by?.reject_reason,
                    hostname: data[i]?.create_by?.host_name ? data[i]?.create_by?.host_name : data[i]?.host_name,
                    invoice_date: data[i]?.create_by?.invoice_date,
                    organization: data[i]?.create_by?.organization ? data[i]?.create_by?.organization : data[i]?.organization,
                });
            }
        }
        return newDT;
    }

    function exportFile(values: any){
        let data_date: any = values?.date_start_end;
        let data_status: any = values?.status;
        let data_type: any = "1";
        // let data_type: any = values?.type;

        axios({
            url: `${SOURCE_URL}/api/v1/topup/exportByDate?status=${data_status}&startDate=${dayjs(data_date[0]).toISOString()}&endDate=${dayjs(data_date[1]).toISOString()}&type=${data_type}&user_id=${user?.id}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const href = URL.createObjectURL(response?.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data_type == "1" ? 'file.csv': 'file.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch(error => {
            console.error('error ',error);
            exportfail();
        });
    }

    return (
        <List
            title={<div className="list_title_custom">{t("Admin")}</div>}
            headerProps={{extra:<></>}}
            breadcrumb={false}
        >
            <Spin spinning={isLoading}>
                <Form
                    layout="vertical"
                    form={formSearch}
                    style={{display: "flex", gap: "20px", margin: "1rem 0 2rem 0"}}
                >
                    <div style={{width: "350px"}}>
                        <Form.Item 
                            label=" "
                            name="invoice_no"
                            style={{marginBottom: 0}}
                            className="disable_txt"
                        >
                            <Input placeholder="Invoice No" size="large" onChange={onSearch} allowClear/>
                        </Form.Item>
                    </div>
                    <div style={{width: "500px"}}>
                        <Form.Item 
                            label=" "
                            name="company_name"
                            style={{marginBottom: 0}}
                            className="disable_txt"
                        >
                            <Input placeholder="Company Name | First Name" size="large" onChange={onSearch} allowClear/>
                        </Form.Item>
                    </div>
                    <div style={{width: "350px"}}>
                        <Form.Item 
                            label=" "
                            name="invoice_date"
                            style={{marginBottom: 0}}
                            className="disable_txt"
                        >
                            <DatePicker
                                size="large"
                                format={format}
                                onChange={onSearch}
                                style={{width: "100%"}}
                                placeholder="Invoice Date"
                                allowClear
                            />
                        </Form.Item>
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "end"}}>
                        <Button icon={<PrinterOutlined />} size="large" onClick={() => setdrawer(true)}>{t("Export Files")}</Button>
                    </div>
                </Form>
                <ConfigProvider
                    theme={{
                        components: {
                            Tabs: {
                                itemColor: "#49454FCC",
                                itemSelectedColor: "#EE642A",
                                inkBarColor: "#49454F",
                            },
                        },
                    }}
                >
                    <Tabs defaultActiveKey="wait-confirm" items={items} onChange={(e) => setTab(e)} activeKey={Tab}/>
                </ConfigProvider>
                <Table 
                    dataSource={
                        Tab == 'wait-approve' ? dtWAIT : 
                        Tab == 'un_paid' ? dtUNPAID : 
                        Tab == 'paid' ? dtPAID : 
                        Tab == 'reject' ? dtREJECT : 
                        Tab == 'overdue' ? dtOVERDUE : 
                        Tab == 'complete' ? dtCOMPLETE : 
                        Tab == 'alls' && dtALLS
                    } 
                    columns={
                        (Tab == 'wait-approve' ||
                         Tab == 'un_paid' || 
                         Tab == 'paid' || 
                         Tab == 'overdue' || 
                         Tab == 'complete' || 
                         Tab == 'alls'
                        ) ? 
                        columns?.filter((item: any) => item?.key !== "note-reject") 
                        : Tab == 'reject' && columns
                    }
                    loading={isLoading}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '30', '50', '100'], 
                        showSizeChanger: true, 
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    expandable={{
                        expandedRowRender: (record) => <p style={{ margin: 0 }}>{record?.description}</p>,
                        rowExpandable: (record) => record?.trans_obj && record?.trans_obj?.account_type !== 'Personal' && record?.trans_obj?.account_type !== "null",
                    }}
                    scroll={{ x: 900 }}
                />
            </Spin>
            <Drawer 
                title=""
                onClose={()=> {
                    setdrawer(false);
                    formExport?.resetFields();
                }}
                open={drawer}
                closeIcon={false}
            >
                <div style={{fontSize: "26px", color: "#EE642A", fontWeight: 700}}>{t("Filter Data")}</div>
                <div style={{marginTop: "2rem"}}>
                    <Form
                        layout="vertical"
                        form={formExport}
                        onFinish={async (values: any) => 
                            exportFile(values)
                        }
                    >
                        <Form.Item 
                            label=" "
                            name="date_start_end"
                            style={{marginBottom: 15}}
                            className="disable_txt"
                            rules={[
                                {
                                    required: true, message: 'Please select date.'
                                },
                            ]}
                        >
                            <RangePicker 
                                style={{width: "100%"}}
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item 
                            label=" "
                            name="status"
                            style={{marginBottom: 15}}
                            className="disable_txt"
                            rules={[
                                {
                                    required: true, message: 'Please select status.'
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Status"
                                options={[
                                    {
                                        label: "Paid",
                                        value: "1",
                                    },
                                    {
                                        label: "Rejected",
                                        value: "2",
                                    },
                                    {
                                        label: "Unpaid",
                                        value: "4",
                                    },
                                    {
                                        label: "Wait for approve",
                                        value: "5",
                                    },
                                    {
                                        label: "Completed",
                                        value: "6",
                                    },
                                    {
                                        label: "Overdue",
                                        value: "7",
                                    }
                                ]}
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item 
                            label=" "
                            name="type"
                            style={{marginBottom: 15}}
                            className="disable_txt"
                            // rules={[
                            //     {
                            //         required: true, message: 'Please select type.'
                            //     },
                            // ]}
                        >
                            <Select
                                placeholder="Type is Comming Soon.."
                                options={[
                                    {
                                        label: "csv",
                                        value: "1",
                                    },
                                    {
                                        label: "excel",
                                        value: "2",
                                    },
                                ]}
                                disabled
                                size="large"
                            />
                        </Form.Item>

                        <div style={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                            <Button type="primary" size="large" style={{width: "80%", backgroundColor: "#FF9300"}} onClick={() => formExport?.submit()}>{t("Export File")}</Button>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </List>
    )
}