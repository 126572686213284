import { Col, Form, Input, Row, Select } from "antd";
import { CustomPhoneInput } from "components/phoneInput";

interface IPROFILE {
    dataProfile: any
    personalPhone: any
    setpersonalPhone: any
    onChangeProfile: (e: any, field: any, mode: "personal" | "organization") => void
}

export const FormPersonal: React.FC<IPROFILE> = ({
    dataProfile = undefined,
    personalPhone,
    setpersonalPhone,
    onChangeProfile
})=> {
    return(
        <>
            <Col className="gutter-row" span={12} /> {/* FREE SPACE */}
            
            <Col className="gutter-row" span={12}> {/* FIRST NAME */}
                <Form.Item 
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: "Please Enter First Name" }]}
                    style={{marginBottom: 15}}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "first_name", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* LAST NAME */}
                <Form.Item 
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: "Please Enter Last Name" }]}
                    style={{marginBottom: 15}}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "last_name", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* ADDRESS */}
                <Form.Item 
                    label="Address"
                    name="address"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter address"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "address", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* SUB DISTICT */}
                <Form.Item 
                    label="Sub Distict"
                    name="tambon"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter sub distict"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "tambon", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* DISTICT */}
                <Form.Item 
                    label="Distict"
                    name="amphure"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter distict"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "amphure", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* CITY */}
                <Form.Item 
                    label="City"
                    name="province"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter city"
                        },
                    ]}
                >
                    <Input onChange={(e) => onChangeProfile(e?.target?.value, "province", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* POSTAL */}
                <Form.Item 
                    label="Postal"
                    name="postcode"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter postal"
                        },
                    ]}
                >
                    <Input
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                        onChange={(e) => onChangeProfile(e?.target?.value, "postcode", "personal")}
                    />
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* COUNTRY */}
                <Form.Item 
                    label="Country"
                    name="country"
                    style={{marginBottom: 15}}
                    rules={[
                        {
                            required: true,
                            message: "Please enter country"
                        },
                    ]}
                >
                    <Input  onChange={(e) => onChangeProfile(e?.target?.value, "country", "personal")}/>
                </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}> {/* TEL */}
                <Form.Item
                    label="Tel"
                    // name="tel"
                    style={{marginBottom: 15}}
                >
                    <CustomPhoneInput 
                        defaultLocal="TH" 
                        selectWidth="60%"
                        popupWidth={330}
                        userPhone={personalPhone}
                        setuserPhone={setpersonalPhone}
                    />
                </Form.Item>
            </Col>
        </>
    )
}