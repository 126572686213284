import { useThemedLayoutContext } from "@refinedev/antd";
import { DOMAIN_URL } from "../../constants";
import React from "react";

export const AppIcon: React.FC = () => {
  const { siderCollapsed } = useThemedLayoutContext();
  
  return (
  <>
    {siderCollapsed == false &&
      <img style={{width: "100%", marginTop: "2rem"}} src={`${DOMAIN_URL}/images/svg/logo_new_1.svg`} alt="NUEMEK"/>
    }
    {siderCollapsed == true &&
      <img style={{width: "60%"}} src={`${DOMAIN_URL}/images/svg/logo-light.svg`} alt="NUEMEK"/>
    }
  </>
  );
};
