import { Button, Modal } from "antd"
import { Termsdt } from "./data"

interface ITF {
    mdopen?: any,
    setmdopen?: any
}

export const ModalTerms: React.FC<ITF> = ({
    mdopen,
    setmdopen
}) => {
    return(
        <Modal
            open={mdopen}
            onOk={() => {
                setmdopen(false)
            }}
            onCancel={() => {
                setmdopen(false)
            }}
            okText="Confirm"
            cancelText="Cancel"
            zIndex={999999999}
            // footer={<Button type="primary" style={{backgroundColor: "#ff8d00"}} onClick={() => setmdopen(false)}>I have read and agree to the Terms & Conditions</Button>}
            footer={<></>}
            width={"50%"}
        >
            <Termsdt />
        </Modal>
    )
}