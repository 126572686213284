// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_forgot_pwd {
    width: 25%;
    background-color: #fff;
    padding: 10px 50px;
    border-radius: 20px;
    box-shadow: 0px 0px 26.899999618530273px 0px #FFB144;
    animation: fadeup 1s;
    transition: 0.3s;
}

.btn_reset_pwd {
    margin-top: 1rem;
    animation: fx_btn_reset_pwd 2s;
    animation-iteration-count: 999;
}


@keyframes fx_btn_reset_pwd {
    0% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(255, 152, 35) 0px 0px 10px 5px;}
    50% {box-shadow: unset;}
    100% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(255, 152, 35) 0px 0px 10px 5px;}
  }`, "",{"version":3,"sources":["webpack://./src/pages/forgot-pwd/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,oDAAoD;IACpD,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,8BAA8B;IAC9B,8BAA8B;AAClC;;;AAGA;IACI,IAAI,kFAAkF,CAAC;IACvF,KAAK,iBAAiB,CAAC;IACvB,MAAM,kFAAkF,CAAC;EAC3F","sourcesContent":[".layout_forgot_pwd {\r\n    width: 25%;\r\n    background-color: #fff;\r\n    padding: 10px 50px;\r\n    border-radius: 20px;\r\n    box-shadow: 0px 0px 26.899999618530273px 0px #FFB144;\r\n    animation: fadeup 1s;\r\n    transition: 0.3s;\r\n}\r\n\r\n.btn_reset_pwd {\r\n    margin-top: 1rem;\r\n    animation: fx_btn_reset_pwd 2s;\r\n    animation-iteration-count: 999;\r\n}\r\n\r\n\r\n@keyframes fx_btn_reset_pwd {\r\n    0% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(255, 152, 35) 0px 0px 10px 5px;}\r\n    50% {box-shadow: unset;}\r\n    100% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(255, 152, 35) 0px 0px 10px 5px;}\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
