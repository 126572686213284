import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Card, Col, Row } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler } from 'chart.js';
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { axiosInstance } from "@refinedev/strapi-v4";
import { API_URL, DOMAIN_URL, TOKEN_KEY, SOURCE_URL } from "../../../constants";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

interface IFC {
    dataLoad: any
}

export const OverviewDashboard: React.FC<IFC> = ({
    dataLoad
})=> {
    const t = useTranslate();

    const [cpu, setCpu] = useState<any>([]);

    const [cpunow, setCpuNow] = useState<any>([]);
    const [ramusagepercent, setRamUsagePercent] = useState<any>([]);
    const [diskread, setDiskRead] = useState<any>([]);
    const [diskwrite, setDiskWrite] = useState<any>([]);
    const [bwrx, setBwRx] = useState<any>([]);
    const [bwtx, setBwTx] = useState<any>([]);

    const diskWriteMax = Math.max(...diskwrite);
    const networkRxMax = Math.max(...bwrx);


    const dataLine = {
        datasets: [
          {
            fill: true,
            label: 'CPU USAGE',
            data: cpunow,
            borderColor: 'rgb(255, 190, 143)',
            backgroundColor: 'rgba(255, 140, 55, 0.5)',
            lineTension: 0.3,
          },
        ],
    };

    const datacpu = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1), // Days of the current month
        datasets: [
            {
                label: 'CPU usage',
                data: cpunow,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            // {
            //     label: 'Dataset 2',
            //     data: Array.from({ length: 31 }, () => Math.floor(Math.random() * 100)),
            //     fill: false,
            //     borderColor: 'rgb(255, 99, 132)',
            //     tension: 0.1,
            // },
        ],
    };

    const dataram = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1), // Days of the current month
        datasets: [
            {
                label: 'Ram usage',
                data: ramusagepercent,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    const datadisk = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1), // Days of the current month
        datasets: [
            {
                label: 'Disk RX',
                data: diskread,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            {
                label: 'Disk TX',
                data: diskwrite,
                fill: false,
                borderColor: 'rgb(245, 66, 221)',
                tension: 0.1,
            },
        ],
    };

    const datanetwork = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1), // Days of the current month
        datasets: [
            {
                label: 'RX',
                data: bwrx,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            {
                label: 'TX',
                data: bwtx,
                fill: false,
                borderColor: 'rgb(245, 66, 221)',
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: { display: true },
                max: 100
            }
        },
        maintainAspectRatio: false,
    };

    const optionsdisk = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: { display: true },
                max: diskWriteMax
            }
        },
        maintainAspectRatio: false,
    };

    const optionsnetwork = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: { display: true },
                max: networkRxMax
            }
        },
        maintainAspectRatio: false,
    };

    // console.log('cpu_now', cpunow)
    // console.log('ram_usage_percent', ramusagepercent)
    // console.log('disk_read', diskread)
    // console.log('diskReadMax', diskReadMax)
    // console.log('disk_write', diskwrite)
    // console.log('bw_rx', bwrx)
    // console.log('bw_tx', bwtx)


    useEffect(() => {
        // axiosInstance.get(`${API_URL}/api/v1/user_profile/findUserUnderCompAndProd?comp_id=${COMPID}&prod_id=${pageTOKEN}`,
        // axiosInstance.get(`${SOURCE_URL}/api/v1/servdata/findByIP?ip=202.44.229.126`,
        axiosInstance.get(`http://192.168.100.178:10061/api/v1/servdata/findByIP?ip=202.44.229.126`,
            // { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }
            { headers: { } }
        )
        .then((response) => {
            let dataLoad: any = response?.data[0];
            // console.log('dataLoad', dataLoad)
            setCpu(
                {
                    "pc_ip": dataLoad?.pc_ip,

                    "cpu_name": dataLoad?.cpu_name,
                    "cpu_avg": dataLoad?.cpu_avg,
                    "cpu_core": dataLoad?.cpu_core,
                    "cpu_handle": dataLoad?.cpu_handle,
                    "cpu_lowest": dataLoad?.cpu_lowest,
                    "cpu_now": dataLoad?.cpu_now,
                    "cpu_peakest": dataLoad?.cpu_peakest,
                    "cpu_process": dataLoad?.cpu_process,
                    "cpu_thread": dataLoad?.cpu_thread,

                    "disk_read": dataLoad?.disk_read,
                    "disk_write": dataLoad?.disk_write,

                    "ram_available": dataLoad?.ram_available,
                    "ram_total": dataLoad?.ram_total,
                    "ram_usage_percent": dataLoad?.ram_usage_percent,
                    "ram_used": dataLoad?.ram_used,

                    "bw_rx": dataLoad?.bw_rx,
                    "bw_tx": dataLoad?.bw_tx,
                }
            )
        })
        .catch(error => {

        });

        // DATA GRAPH
        // {{URL}}/api/v1/servdatalog/findByIP?ip=202.44.229.126&time=1000
        axiosInstance.get(`http://192.168.100.178:10061/api/v1/servdatalog/findByIP?ip=202.44.229.126&time=1000`,
            // { headers: { Authorization: `Bearer ${TOKEN_KEY}` } },
            { headers: { } },
        )
        .then((response) => {
            let dataLoad: any = response?.data;
            // console.log('dataLoad xx', dataLoad)

            // CPU cpu_now
            // const cpu_now = dataLoad.map((item:any) => item.cpu_now);
            setCpuNow(dataLoad.map((item:any) => item.cpu_now));

            // RAM ram_usage_percent
            // const ram_usage_percent = dataLoad.map((item:any) => item.ram_usage_percent);
            setRamUsagePercent(dataLoad.map((item:any) => item.ram_usage_percent));

            // DISK disk_write, disk_read
            // const disk_read = dataLoad.map((item:any) => item.disk_read);
            setDiskRead(dataLoad.map((item:any) => item.disk_read));
            // const disk_write = dataLoad.map((item:any) => item.disk_write);
            setDiskWrite(dataLoad.map((item:any) => item.disk_write));

            // Network bw_rx, bw_tx
            // const bw_rx = dataLoad.map((item:any) => item.bw_rx);
            setBwRx(dataLoad.map((item:any) => item.bw_rx));
            // const bw_tx = dataLoad.map((item:any) => item.bw_tx);
            setBwTx(dataLoad.map((item:any) => item.bw_tx));
            

        })
        .catch(error => {
            console.log('error', error)
        });
    
      return () => {}
    }, [])
    
    // console.log('cpu', cpu)
    return (
        <Row gutter={[12, 12]}>
            <Col className="gutter-row" span={12}>
                <Card>
                    <Row gutter={[12, 12]} style={{marginBottom: "5px"}}>
                        <Col className="gutter-row" span={6}>
                            <div style={{color: "#64A0E8", fontSize: "22px", fontWeight: "bold"}}>{"CPU"}</div>
                            <div style={{marginTop: "5px"}}>{t("% Utilization")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right", fontSize: "18px"}}>{t(cpu?.cpu_name)} {t(cpu?.cpu_core)} Cores</div>
                            <div style={{textAlign: "right", marginTop: "5px"}}>{t("0 %")}</div>
                        </Col>
                    </Row>
                    <div className="chart-pie-container" style={{height: "200px"}}>
                        <Line data={datacpu} options={options} />
                    </div>
                    <Row gutter={[12, 12]} style={{marginBottom: "15px"}}>
                        <Col className="gutter-row" span={6}>
                            <div>{t("60 Seconds")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right"}}>{t("0")}</div>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        {/* <Col className="gutter-row" span={4}>
                            <div>{t("Utilization")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0 %"}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Speed")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0 GHz"}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Processes")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0"}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Threads")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0"}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Handles")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0"}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Up time")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"00:00:00:00"}</div>
                        </Col> */}

                        <Col className="gutter-row" span={4}>
                            <div>{t("Peak")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.cpu_peakest} %`}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Low")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.cpu_lowest} %`}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Average")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.cpu_avg} %`}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Processes")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.cpu_process}`}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Threads")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.cpu_thread}`}</div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <div>{t("Handles")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.cpu_handle}`}</div>
                        </Col>

                    </Row>
                </Card>
            </Col>
            <Col className="gutter-row" span={12}>
                <Card>
                    <Row gutter={[12, 12]} style={{marginBottom: "5px"}}>
                        <Col className="gutter-row" span={6}>
                            <div style={{color: "#E06382", fontSize: "22px", fontWeight: "bold"}}>{"Memory"}</div>
                            <div style={{marginTop: "5px"}}>{t("Memory usage")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right", fontSize: "18px"}}>{t(cpu?.ram_total)} GB</div>
                            {/* <div style={{textAlign: "right", marginTop: "5px"}}>{t("0")}</div> */}
                        </Col>
                    </Row>
                    <div className="chart-pie-container" style={{height: "200px"}}>
                        <Line data={dataram} options={options} />
                    </div>
                    <Row gutter={[12, 12]} style={{marginBottom: "15px"}}>
                        <Col className="gutter-row" span={6}>
                            <div>{t("60 Seconds")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right"}}>{t("0")}</div>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]} >
                        <Col className="gutter-row" span={8}>
                            <div>{t("In use")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.ram_used} GB`} </div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                            <div>{t("Available")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.ram_available} GB`} </div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                            <div>{t("Usage in %")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.ram_usage_percent} %`} </div>
                        </Col>
                        {/* <Col className="gutter-row" span={6}>
                            <div>{t("Committed")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0/0 GB"}</div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div>{t("Cached")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0 GB"}</div>
                        </Col> */}
                    </Row>
                </Card>
            </Col>
            <Col className="gutter-row" span={12}>
                <Card>
                    <Row gutter={[12, 12]} style={{marginBottom: "5px"}}>
                        <Col className="gutter-row" span={6}>
                            <div style={{color: "#49BBBB", fontSize: "22px", fontWeight: "bold"}}>{"Disk"}</div>
                            <div style={{marginTop: "5px"}}>{t("Active time")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right", fontSize: "18px"}}>{t("-")}</div>
                            <div style={{textAlign: "right", marginTop: "5px"}}>{t("0 %")}</div>
                        </Col>
                    </Row>
                    <div className="chart-pie-container" style={{height: "200px"}}>
                        {/* <Line
                            className="chart-pie"
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {display: false},
                                },
                                scales: {
                                    y: {
                                      ticks: { display: false },
                                    }
                                }
                            }}
                            data={dataLine}
                        /> */}
                        <Line className="chart-pie" data={datadisk} options={optionsdisk}  />

                    </div>
                    <Row gutter={[12, 12]} style={{marginBottom: "15px"}}>
                        <Col className="gutter-row" span={6}>
                            <div>{t("60 Seconds")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right"}}>{t("0")}</div>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        {/* <Col className="gutter-row" span={6}>
                            <div>{t("Active time")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0 %"}</div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                            <div>{t("Average response time")}</div>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{"0 ms"}</div>
                        </Col> */}
                        <Col className="gutter-row" span={8}>
                            <div style={{borderLeft: "2px solid #49BBBB", paddingLeft: "5px"}}>
                                <div>{t("Read speed")}</div>
                                <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.disk_read} kb/s`}</div>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                            <div style={{borderLeft: "2px dotted #49BBBB",paddingLeft: "5px"}}>
                                <div>{t("Write speed")}</div>
                                <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.disk_write} kb/s`}</div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col className="gutter-row" span={12}>
                <Card>
                    <Row gutter={[12, 12]} style={{marginBottom: "5px"}}>
                        <Col className="gutter-row" span={6}>
                            <div style={{color: "#EBC65B", fontSize: "22px", fontWeight: "bold"}}>{"Network"}</div>
                            <div style={{marginTop: "5px"}}>{t("Thorughput")}</div>
                        </Col>
                        {/* <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right", fontSize: "18px"}}>{t("-")}</div>
                            <div style={{textAlign: "right", marginTop: "5px"}}>{t("0 Mbps")}</div>
                        </Col> */}
                    </Row>
                    <div className="chart-pie-container" style={{height: "200px"}}>
                        {/* <Line
                            className="chart-pie"
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {display: false},
                                },
                                scales: {
                                    y: {
                                      ticks: { display: false },
                                    }
                                }
                            }}
                            data={dataLine}
                        /> */}
                        <Line className="chart-pie" data={datanetwork} options={optionsnetwork}  />
                    </div>
                    <Row gutter={[12, 12]} style={{marginBottom: "15px"}}>
                        <Col className="gutter-row" span={6}>
                            <div>{t("60 Seconds")}</div>
                        </Col>
                        <Col className="gutter-row" span={18} style={{textAlign: "right"}}>
                            <div style={{textAlign: "right"}}>{t("0")}</div>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col className="gutter-row" span={8}>
                            <div style={{borderLeft: "2px solid #EBC65B", paddingLeft: "5px"}}>
                                <div>{t("Read speed")}</div>
                                <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.bw_rx} kb/s`}</div>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                            <div style={{borderLeft: "2px dotted #EBC65B",paddingLeft: "5px"}}>
                                <div>{t("Write speed")}</div>
                                <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "5px"}}>{`${cpu?.bw_tx} kb/s`}</div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}