import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Checkbox, Form, Input, Layout, Modal, Spin, Tag, message, notification } from "antd";
import "./styles.css";
import { API_URL, DOMAIN_URL, TOKEN_KEY } from "../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { useState } from "react";
import { HeadernoAuth } from "components/unAuth/header";
import { CheckCircleFilled} from '@ant-design/icons';
import { ModalTerms } from "components/term_md";
import { ModalPolicy } from "components/policy_md";

export interface IRegisForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Register: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<IRegisForm>();
    const { push } = useNavigation();

    localStorage.clear();

    const [alertTerm, setalertTerm] = useState<boolean>(false);
    const [alertPolicy, setalertPolicy] = useState<boolean>(false);
    const [checkedTerm, setcheckedTerm] = useState<boolean>(false);
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, MsgHolder] = message.useMessage();
    const [mdopen, setmdopen] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[3].split("?");
    let pageTOKEN: any = pageSPLIT[1];

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("สมัครสมาชิกเสร็จสิ้น โปรดเช็ค E-mail เพื่อรับรหัสยืนยันตัวตน"),
            duration: 3
        });
    };

    const faildNoti = () => {
        notification['error']({
          message: t("login-msg.error"),
          description: t("login-msg.error-description"),
        });
    };

    const checkTerm = () => {
        messageApi.open({
          type: 'error',
          content: 'Please checked Term & conditions',
        });
    };

    function onclickSN(mode: any){
        if(mode == "un-token"){
            push(`/login`);
        }else if(mode == "token"){
            push(`/login?${pageTOKEN}`)
        }
    }

    return(
        <Layout className="layout-regis">
            <HeadernoAuth sticky/>
            {contextHolder}
            {MsgHolder}
            <div id="container-page-bg" style={{display: "flex", alignItems: "center", height: "92.4vh", justifyContent: "center"}}>
                <div className="layout_regis">
                    <Spin spinning={isLoading}>
                        <Form<IRegisForm>
                            layout="vertical"
                            form={form}
                            onFinish={async (values: any) => {
                                if(checkedTerm == true){
                                    setLoading(true);
                                    setTimeout(() => {
                                        let body: any = {
                                            email: values.email?.toLowerCase(),
                                            username: values.email?.toLowerCase(),
                                            first_name: values.first_name,
                                            last_name: values.last_name,
                                            password: values.password1,
                                            tel: ""
                                        }
            
                                        axiosInstance.post(`${API_URL}/api/user-profiles/CreateUser`,
                                            body,
                                            { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }
                                            )
                                        .then((response) => {
                                            setLoading(false);
                                            setTimeout(() => {
                                                setmdopen(true);
                                            },100)
                                        })
                                        .catch(error => {
                                            console.error('error ',error);
                                        })
                                    },500);
                                }else if(checkedTerm == false){
                                    checkTerm();
                                }
                            }}
                            requiredMark={false}
                            // style={{width: "40%"}}
                        >
                            <div style={{marginBottom: "1rem", marginTop: "2rem"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <img className="logoLogin" src={`${DOMAIN_URL}/images/svg/logo_new_dark.svg`} alt="NUEMEK LOGO" style={{width: "250px"}}/>
                                </div>
                            </div>

                            <Form.Item
                                name="email"
                                label=" "
                                rules={[{ required: true, message: "Please Enter Email" }]}
                                style={{marginBottom: 0}}
                            >
                                {/* <Input id="input_logi" size="large" placeholder="Email"/> */}
                                <Input size="large" placeholder="Email"/>
                            </Form.Item>

                            <Form.Item
                                name="first_name"
                                label=" "
                                rules={[{ required: true, message: "Please Enter First name" }]}
                                style={{marginBottom: 0}}
                            >
                                {/* <Input id="input_logi" size="large" placeholder="First Name"/> */}
                                <Input size="large" placeholder="First Name"/>
                            </Form.Item>

                            <Form.Item
                                name="last_name"
                                label=" "
                                rules={[{ required: true, message: "Please Enter Last name" }]}
                                style={{marginBottom: 0}}
                            >
                                {/* <Input id="input_logi" size="large" placeholder="Last Name"/> */}
                                <Input size="large" placeholder="Last Name"/>
                            </Form.Item>

                            <Form.Item
                                name="password1"
                                label=" "
                                // className="cus_input_pwd"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value.length >= 8 && value.length <= 16) {
                                                return Promise.resolve();
                                            }

                                            if (value.length <= 8 && value.length > 0  || value.length >= 16) {
                                                return Promise.reject(new Error('Password must be 8-16 characters only '));
                                            }

                                            if (value.length == 0) {
                                                return Promise.reject(new Error('please input your password'));
                                            }

                                            if (value == "") {
                                                return Promise.reject(new Error('please input your password'));
                                            }

                                        },
                                    }),
                                ]}
                                hasFeedback
                                style={{marginBottom: 0}}
                            >
                                {/* <Input.Password
                                    id="input_logi_pwd"
                                    type="password"
                                    placeholder="Password"
                                    size="large"
                                /> */}
                                <Input.Password
                                    // id="input_logi_pwd"
                                    type="password"
                                    placeholder="Password"
                                    size="large"
                                />
                            </Form.Item>

                            <div style={{ marginTop: "1.5rem" }}>
                                <div style={{display: "flex"}}>
                                    <Checkbox 
                                        className="checkbox_custom txt_onres"
                                        style={{fontSize: "0.9rem", color: "#838383"}} 
                                        onChange={(e: any) => {
                                            setcheckedTerm(e?.target?.checked);
                                        }}
                                    />
                                    <div style={{marginTop: "3px"}}>
                                        <span style={{marginLeft: "1rem"}}>I agree with the </span>
                                        <span 
                                            onClick={() =>  setalertTerm(true)}
                                            className="txt_hover"
                                        >
                                            term and conditions
                                        </span>
                                        <span style={{margin: "0 0.5rem"}}>&</span>
                                        <span 
                                            onClick={() =>  setalertPolicy(true)}
                                            className="txt_hover"
                                        >
                                            privacy policy
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <Button
                                className="btn_sign_in"
                                type="primary"
                                size="large"
                                htmlType="submit"
                                block
                                style={{boxShadow: "unset"}}
                                // icon={<Icons.LoginOutlined />}
                            >
                                {t("Sign up")}
                            </Button>

                            <div style={{color: "#838383", marginTop: "2rem", textAlign: "center", marginBottom: "1.5rem"}}>
                                <span>Already have an account? </span>
                                <span 
                                    style={{
                                        color: "#FF9823",
                                        cursor: "pointer"
                                    }} 
                                    onClick={() => {
                                        pageTOKEN == undefined ? 
                                            onclickSN("un-token")
                                        : pageTOKEN !== undefined && 
                                            onclickSN("token")
                                    }}
                                >
                                    Login
                                </span>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </div>

            <ModalTerms mdopen={alertTerm} setmdopen={setalertTerm}/>
            <ModalPolicy mdopen={alertPolicy} setmdopen={setalertPolicy}/>

            <Modal
                title={
                <div>
                    <span style={{marginRight: "1rem"}}>
                        <CheckCircleFilled style={{color: "#52c41a"}}/>
                    </span>
                    <span>Successful</span>
                </div>
                }
                open={mdopen}
                zIndex={999999999}
                onOk={() => pageTOKEN == undefined ? onclickSN("un-token") : pageTOKEN !== undefined && onclickSN("token")}
                onCancel={() => pageTOKEN == undefined ? onclickSN("un-token") : pageTOKEN !== undefined && onclickSN("token")}
                afterClose={() => pageTOKEN == undefined ? onclickSN("un-token") : pageTOKEN !== undefined && onclickSN("token")}
                footer={
                    <Button type="primary" 
                        onClick={()=> {
                            pageTOKEN == undefined ? onclickSN("un-token") : pageTOKEN !== undefined && onclickSN("token")
                        }}
                    >
                        OK
                    </Button>
                }
            >
                <div>
                    <span>
                        {"สมัครสมาชิกเสร็จสิ้น โปรดยืนยันตัวตนใน E-mail ที่ลงทะเบียน"}
                    </span>
                </div>
            </Modal>
        </Layout>
    )
}