// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cc_success {
    background-color: #DAF8E6;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.icon_success {
    color: #1A8245;
    font-size: 30px;
}

.cc_error {
    background-color: #FEEBEB;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.icon_error {
    color: #E10E0E;
    font-size: 30px;
}

.title_mdconfirm {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.title_mdconfirm > .success {
    color: #1A8245;
}

.title_mdconfirm > .error {
    color: #E10E0E;
}

.desc_mdconfirm {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.1em;
    margin-top: 1.5rem;
}

.md_txt {
    font-size: 16px;
    color: #637381;
}`, "",{"version":3,"sources":["webpack://./src/components/confirm_md/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".cc_success {\r\n    background-color: #DAF8E6;\r\n    width: 90px;\r\n    height: 90px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 50px;\r\n}\r\n\r\n.icon_success {\r\n    color: #1A8245;\r\n    font-size: 30px;\r\n}\r\n\r\n.cc_error {\r\n    background-color: #FEEBEB;\r\n    width: 90px;\r\n    height: 90px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 50px;\r\n}\r\n\r\n.icon_error {\r\n    color: #E10E0E;\r\n    font-size: 30px;\r\n}\r\n\r\n.title_mdconfirm {\r\n    text-align: center;\r\n    font-size: 30px;\r\n    font-weight: bold;\r\n}\r\n\r\n.title_mdconfirm > .success {\r\n    color: #1A8245;\r\n}\r\n\r\n.title_mdconfirm > .error {\r\n    color: #E10E0E;\r\n}\r\n\r\n.desc_mdconfirm {\r\n    text-align: center;\r\n    font-size: 16px;\r\n    letter-spacing: 0.1em;\r\n    margin-top: 1.5rem;\r\n}\r\n\r\n.md_txt {\r\n    font-size: 16px;\r\n    color: #637381;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
