import { useThemedLayoutContext } from "@refinedev/antd";
import { useGetIdentity, useNavigation } from "@refinedev/core";
import { Card, Spin } from "antd";
import "./styles.css";
import { useEffect, useState } from "react";
import { DOMAIN_URL } from "../../../constants";

let countLoad: number = 0;
const CustomMENU: React.FC = () => {
    const { siderCollapsed } = useThemedLayoutContext();
    const { replace, list, push} = useNavigation();
    const { data: user } = useGetIdentity<any>();
    const VERIFY: any = localStorage.getItem("VERIFY");

    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[3];
    let pagePATH: any = page[4];

    let userRole: any = user?.user_profile?.role;
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if(userRole && isLoading == true){
            setLoading(false);
        }
    }, [userRole])

    const menuUser: any = [
        // {
        //     id: 0,
        //     title: "Home",
        //     route: "home",
        //     url: "/home",
        //     active: true,
        //     visible: true,
        //     icon: "https://mt4.cloud/_images/icons/products.svg"
        // },
        {
            id: 1,
            title: "Products",
            route: "products",
            url: "/products",
            active: true,
            visible: true,
            in_domain: true,
            // icon: "https://mt4.cloud/_images/icons/products.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/product.svg`
        },
        {
            id: 2,
            title: "Billing",
            route: "billing",
            url: "/billing",
            active: true,
            visible: true,
            in_domain: true,
            // icon: "https://mt4.cloud/_images/icons/billing.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/billing.svg`
        },
        {
            id: 3,
            title: "Support",
            route: "support",
            url: "https://nueamek.com/",
            active: true,
            visible: true,
            in_domain: false,
            // icon: "https://mt4.cloud/_images/icons/support.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/support.svg`
        },
        {
            id: 4,
            title: "Affiliate",
            route: "affiliate",
            url: "/affiliate",
            active: false,
            visible: false,
            in_domain: false,
            // icon: "https://mt4.cloud/_images/icons/deploy.svg"
        },
        {
            id: 5,
            title: "Account",
            route: "account",
            url: "/account",
            active: true,
            visible: true,
            in_domain: true,
            // icon: "https://mt4.cloud/_images/icons/dashboard.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/account.svg`
        },
        {
            id: 6,
            title: "Docs",
            route: "docs",
            url: "https://blog.nueamek.cloud/",
            active: true,
            visible: true,
            in_domain: false,
            // icon: "https://mt4.cloud/_images/icons/docs.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/docs.svg`
        },
    ]

    const menuAdmin: any = [
        {
            id: 1,
            title: "Account",
            route: "account",
            url: "/account",
            active: true,
            visible: true,
            in_domain: true,
            // icon: "https://mt4.cloud/_images/icons/dashboard.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/account.svg`
        },
        {
            id: 2,
            title: "Docs",
            route: "docs",
            url: "https://blog.nueamek.cloud/",
            active: true,
            visible: true,
            in_domain: false,
            // icon: "https://mt4.cloud/_images/icons/docs.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/docs.svg`
        },
        {
            id: 3,
            title: "Admin",
            route: "pn-admin",
            url: "/pn-admin",
            active: true,
            visible: true,
            in_domain: true,
            // icon: "https://mt4.cloud/_images/icons/dashboard.svg"
            icon: `${DOMAIN_URL}/images/svg/menu/admin.svg`
        },
    ]

    const renderMenu = (type: any, item: any, key:any) => {
        return (
            <div 
                key={key} 
                className={`${type == "CLT" ? "item-sm": "item-lg"} ${item?.active == false ? "false-menu" : "true-menu"} ${(pageTOKEN == String(item?.route) || pageTOKEN == "cart" && pagePATH == "cart-detail" && item?.route == "billing") ? "custom-menu-item-checked": "custom-menu-item"}`} 
                onClick={() => {item?.active == true && item?.in_domain == true ? push(item?.url) : window.open(`${item?.url}`, '_blank');}}
            >
                <div>
                    <div className="menu-icon"><img src={item?.icon} style={{width: "40%"}}/></div>
                    <div className={`menu-txt ${type == "CLT" ? "txt-sm": "txt-lg"}`}>{item?.title}</div>
                </div>
            </div> 
        )
    }

    return (
        <Spin spinning={isLoading} style={{background: "transparent"}}>
            {siderCollapsed == false && //MODE FULL
                <div className="custom-menu">
                    {
                        userRole == "User" ? menuUser?.filter((f: any) => f?.visible == true)?.map((item: any, key: any) => {
                            return renderMenu("CLF",item, key)
                        })
                        :userRole == "Admin" && menuAdmin?.filter((f: any) => f?.visible == true)?.map((item: any, key: any) => {
                            return renderMenu("CLF",item, key)
                        })
                    }
                </div>
            }

            {siderCollapsed == true && //MODE MINI
                <div className="custom-menu menu-sm">
                    {/* {
                        menuList?.filter((f: any) => userRole == "User" ? f?.for == "user": userRole == "Admin" && (f?.for == "admin" || f?.for == "user"))?.filter((f: any) => f?.visible == true)?.map((item: any, key: any) => {
                            return (
                                <div 
                                    key={key} 
                                    className={`
                                        custom-menu-item
                                        item-sm
                                        ${item?.active == false && "false-menu"}
                                    `} 
                                    style={{
                                        backgroundColor: pageTOKEN == String(item?.route) ? "#2c2e47" : "transparent"
                                    }} 
                                    onClick={() => {item?.active == true && item?.in_domain == true ? push(item?.url) : window.open(`${item?.url}`, '_blank');}}
                                >
                                    <div>
                                        <div className="menu-icon"><img src={item?.icon} style={{width: "40%"}}/></div>
                                        <div className="menu-txt txt-sm">{item?.title}</div>
                                    </div>
                                </div> 
                            )
                        })
                    } */}
                    {
                        userRole == "User" ? menuUser?.filter((f: any) => f?.visible == true)?.map((item: any, key: any) => {
                            return renderMenu("CLT",item, key)
                        })
                        :userRole == "Admin" && menuAdmin?.filter((f: any) => f?.visible == true)?.map((item: any, key: any) => {
                            return renderMenu("CLT",item, key)
                        })
                    }
                </div>
            }
        </Spin>
    )
}

export default CustomMENU;