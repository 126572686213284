// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/svg/landding_svg/contact_header_2.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/svg/landding_svg/bg-line.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_contact_noauth {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    height: 30vh;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 30px 60px;
}

.bg_line {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
    height: 50vh;
    width: 100%;
    background-color: #fff;
    background-size: cover;
    position: absolute;
    bottom: 0;
    z-index: -1;
    /* background-position: 100px; */
    background-position-y: 15vh;
    /* position: absolute; */
}`, "",{"version":3,"sources":["webpack://./src/pages/contact/styles.css"],"names":[],"mappings":"AAAA;IACI,oEAAgG;IAChG,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,oEAAuF;IACvF,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gCAAgC;IAChC,2BAA2B;IAC3B,wBAAwB;AAC5B","sourcesContent":[".banner_contact_noauth {\r\n    background-image: url('../../../public/images/svg/landding_svg/contact_header_2.svg') !important;\r\n    height: 30vh;\r\n    background-size: cover;\r\n    border-radius: 10px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 30px 60px;\r\n}\r\n\r\n.bg_line {\r\n    background-image: url('../../../public/images/svg/landding_svg/bg-line.svg') !important;\r\n    height: 50vh;\r\n    width: 100%;\r\n    background-color: #fff;\r\n    background-size: cover;\r\n    position: absolute;\r\n    bottom: 0;\r\n    z-index: -1;\r\n    /* background-position: 100px; */\r\n    background-position-y: 15vh;\r\n    /* position: absolute; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
