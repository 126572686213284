import { IResourceComponentsProps, useCustom } from "@refinedev/core"
import { SOURCE_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { Spin } from "antd";

export const CartconfirmPage: React.FC<IResourceComponentsProps> = () => {

    let page = window?.location?.href.split("/");
    let pageTOKEN: any = page[5];

    const TOKEN: any = localStorage.getItem(TOKEN_KEY);
    const [isLoading, setLoading] = useState<boolean>(true);

    const [dataPD, setdataPD] = useState<any>();

    const { data: dataTarn, refetch} = useCustom<any>({
        url: `${SOURCE_URL}/api/v1/transaction/getTransactionDetail`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
            query: {
                id: pageTOKEN
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataPD((pre: any) => dataLoad);
                setLoading(false);
            },
        },
    });

    return (
        <Spin spinning={isLoading}>
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "1080px"}}>
                    <div style={{fontSize: "32px", fontWeight: 500}}>Order Confirmation</div>
                    <div>Thank you for your order. You will receive a confirmation email shortly.</div>
                    <div style={{fontSize: "26px", fontWeight: 500, marginTop: "1rem"}}>Your Order Number is: {dataPD?.order_number}</div>
                    <div>If you have any questions about your order, please open a support ticket from your client area and refer to your order number.</div>
                </div>
            </div>
        </Spin>
    )
}
